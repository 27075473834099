import styled, { css } from 'styled-components'

import Icon from '../Icon'
import Link from '../Link'

export enum ActionButtonVariant {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  quaternary = 'quaternary',
  quinary = 'quinary',
  senary = 'senary',
}

export const Label = styled.span`
  ${({ theme }) => theme.textStyles.firaSansTextBase}
  display: flex;
  align-items: center;
  min-height: 2.4rem;
  &:only-child {
    text-align: center;
  }
`

export const SpinnerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ActionButton = styled(Link)<{
  $active?: boolean
  disabled?: boolean
  $variant?: ActionButtonVariant
  $isLoading?: boolean
}>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  padding: 0.9rem 1.6rem;

  border-radius: 1rem;
  border-width: 1px;
  border-style: solid;

  transition: border-color 0.3s, background-color 0.3s, color 0.3s;

  border-color: ${({ theme, $active, $variant }) =>
    $active
      ? theme.colors.arapawa
      : !$variant || $variant === ActionButtonVariant.primary
      ? theme.colors.greenSecondaryBis
      : $variant === ActionButtonVariant.secondary
      ? theme.colors.bluePrimary
      : $variant === ActionButtonVariant.tertiary
      ? theme.colors.bluePrimary
      : $variant === ActionButtonVariant.quaternary
      ? theme.colors.pinkButton
      : $variant === ActionButtonVariant.quinary
      ? theme.colors.pureWhite
      : $variant === ActionButtonVariant.senary
      ? theme.colors.pureWhite
      : theme.colors.pureBlack};
  background-color: ${({ $active, theme, $variant }) =>
    $active
      ? theme.colors.arapawa
      : !$variant || $variant === ActionButtonVariant.primary
      ? theme.colors.greenSecondaryBis
      : $variant === ActionButtonVariant.secondary
      ? 'transparent'
      : $variant === ActionButtonVariant.tertiary
      ? theme.colors.bluePrimary
      : $variant === ActionButtonVariant.quaternary
      ? theme.colors.pinkButton
      : $variant === ActionButtonVariant.quinary
      ? 'transparent'
      : $variant === ActionButtonVariant.senary
      ? theme.colors.pureWhite
      : theme.colors.aliceBlue};

  color: ${({ $active, theme, $variant }) =>
    $active
      ? theme.colors.pureWhite
      : !$variant || $variant === ActionButtonVariant.primary
      ? theme.colors.pureWhite
      : $variant === ActionButtonVariant.secondary
      ? theme.colors.bluePrimary
      : $variant === ActionButtonVariant.tertiary
      ? theme.colors.pureWhite
      : $variant === ActionButtonVariant.quaternary
      ? theme.colors.pureWhite
      : $variant === ActionButtonVariant.quinary
      ? theme.colors.pureWhite
      : $variant === ActionButtonVariant.senary
      ? theme.colors.pinkButton
      : theme.colors.pureBlack};

  span {
    color: ${({ $active, theme, $variant }) =>
      $active
        ? theme.colors.pureWhite
        : !$variant || $variant === ActionButtonVariant.primary
        ? theme.colors.pureWhite
        : $variant === ActionButtonVariant.secondary
        ? theme.colors.bluePrimary
        : $variant === ActionButtonVariant.tertiary
        ? theme.colors.pureWhite
        : $variant === ActionButtonVariant.quaternary
        ? theme.colors.pureWhite
        : $variant === ActionButtonVariant.quinary
        ? theme.colors.pureWhite
        : $variant === ActionButtonVariant.senary
        ? theme.colors.pinkButton
        : theme.colors.pureBlack};
  }

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      ${Label} {
        opacity: 0;
      }
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `};

  ${({ disabled }) =>
    !disabled &&
    css<{
      $active?: boolean
      disabled?: boolean
      $variant?: ActionButtonVariant
    }>`
      cursor: pointer;
      &:hover:not(:disabled),
      a:hover &:not(:disabled),
      a:focus-visible &:not(:disabled),
      &:focus-visible:not(:disabled) {
        border-color: ${({ theme, $active, $variant }) =>
          $active
            ? theme.colors.aliceBlue
            : !$variant || $variant === ActionButtonVariant.primary
            ? theme.colors.greenAccent
            : $variant === ActionButtonVariant.secondary
            ? theme.colors.bluePrimary
            : $variant === ActionButtonVariant.tertiary
            ? theme.colors.bluePrimary
            : $variant === ActionButtonVariant.quaternary
            ? theme.colors.pinkSecondary
            : $variant === ActionButtonVariant.quinary
            ? theme.colors.pureWhite
            : $variant === ActionButtonVariant.senary
            ? theme.colors.bluePrimary
            : theme.colors.pureBlack};
        background-color: ${({ $active, theme, $variant }) =>
          $active
            ? theme.colors.aliceBlue
            : !$variant || $variant === ActionButtonVariant.primary
            ? theme.colors.greenAccent
            : $variant === ActionButtonVariant.secondary
            ? theme.colors.bluePrimary
            : $variant === ActionButtonVariant.tertiary
            ? 'transparent'
            : $variant === ActionButtonVariant.quaternary
            ? theme.colors.pinkSecondary
            : $variant === ActionButtonVariant.quinary
            ? theme.colors.pureWhite
            : $variant === ActionButtonVariant.senary
            ? theme.colors.bluePrimary
            : theme.colors.pureWhite};
        color: ${({ $active, theme, $variant }) =>
          $active
            ? theme.colors.pureWhite
            : !$variant || $variant === ActionButtonVariant.primary
            ? theme.colors.pureWhite
            : $variant === ActionButtonVariant.secondary
            ? theme.colors.pureWhite
            : $variant === ActionButtonVariant.tertiary
            ? theme.colors.bluePrimary
            : $variant === ActionButtonVariant.quaternary
            ? theme.colors.pureWhite
            : $variant === ActionButtonVariant.quinary
            ? theme.colors.pinkButton
            : $variant === ActionButtonVariant.senary
            ? theme.colors.pureWhite
            : theme.colors.pureBlack};

        span {
          color: ${({ $active, theme, $variant }) =>
            $active
              ? theme.colors.pureWhite
              : !$variant || $variant === ActionButtonVariant.primary
              ? theme.colors.pureWhite
              : $variant === ActionButtonVariant.secondary
              ? theme.colors.pureWhite
              : $variant === ActionButtonVariant.tertiary
              ? theme.colors.bluePrimary
              : $variant === ActionButtonVariant.quaternary
              ? theme.colors.pureWhite
              : $variant === ActionButtonVariant.quinary
              ? theme.colors.pinkButton
              : $variant === ActionButtonVariant.senary
              ? theme.colors.pureWhite
              : theme.colors.pureBlack};
        }
      }
    `}
`

export const ActionIcon = styled(Icon)`
  width: 2.4rem;
  height: 2.4rem;
`
