import { dataToFormData, postRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'
import { SUpload } from '../../types/api/Upload'

type UploadResponse = Array<SUpload>

export type UploadParams = {
  path?: string
  refId: string
  ref: string
  field: string
  files: Array<File>
}

const upload = (params: UploadParams) => {
  const data = dataToFormData(params)
  return postRestRequest({
    url: api.REST_API_URL + '/upload',
    data,
    transformer: (response: UploadResponse) => {
      return response
    },
  })
}

export default upload
