import { MediaType } from '../../form/fields/FormFieldUploadMedia'
import { BlockCategory } from '../../forms/AdsForm/types'

import {
  EmitterType,
  SClassifiedCabinetType,
  SClassifiedCategory,
  SClassifiedMedia,
  SClassifiedOfferType,
  SClassifiedSecretariat,
  SClassifiedSpecialty,
} from './api/Classified'
import { User } from './User'
import { SImageData } from './api/Image'
import type { ClassifiedView } from './ClassifiedView'
import { Image } from './Image'

export enum Status {
  Pending = 'pending',
  Archived = 'archived',
  Published = 'published',
}

export enum ContractType {
  Cdi = 'contract-cdi',
}

export type Classified = {
  id: string
  title?: string
  slug?: string
  description?: string
  keywords?: string
  summary?: string
  address?: string
  additionalAddress?: string
  block?: Array<any>
  emitter?: ClassifiedEmitter
  image?: SImageData
  videoUrl?: string
  mainMediaType?: MediaType
  updatedAt?: string
  publishedAt?: string
  createdAt?: string
  archivedAt?: string
  classifiedCategory?: ClassifiedCategory
  classified_answers?: Array<ClassifiedAnswer>
  classified_views?: Array<ClassifiedView>
  medias?: Array<ClassifiedMedia>
  user?: User
  status?: Status
  views?: string
  lat?: number
  long?: number
  city?: string
  postalCode?: string
  department?: string
}

export type ClassifiedCategory = {
  id: string
  name?: string
  slug?: string
  user_profiles?: Array<any>
  form?: string
  classifieds?: Array<Classified>
  totalClassifieds?: number
}

export type ClassifiedAnswer = {
  id: string
  birthdate?: string
  city?: string
  civility: string
  classified?: Classified
  country?: string
  firstDate?: string
  secondDate?: string
  thirdDate?: string
  fourthDate?: string
  document?: Image
  email: string
  jobDuration?: string
  jobDurationCarabin?: string
  lastname: string
  message: string
  name: string
  phone: string
  postcode?: string
  schoolingLevel?: string
  street?: string
  studentCard?: Image
  university?: string
  answer_user?: User
  status?: Status
}

export type ClassifiedOfferType = SClassifiedOfferType['attributes'] & {
  id: string
}
export type ClassifiedSecretariat = SClassifiedSecretariat['attributes'] & {
  id: string
}
export type ClassifiedSecretariats = Array<
  SClassifiedSecretariat['attributes']
> & {
  id: string
}
export type ClassifiedSpecialty = SClassifiedSpecialty['attributes'] & {
  id: string
}
export type ClassifiedSpecialties = Array<
  SClassifiedSpecialty['attributes'] & {
    id: string
  }
>
export type ClassifiedCategories = Array<
  SClassifiedCategory['attributes'] & {
    id: string
  }
>
export type ClassifiedFacetsDepartments = {
  [key: string]: Record<string, number>
}
export type ClassifiedCabinetType = SClassifiedCabinetType['attributes'] & {
  id: string
}

export type ClassifiedEmitter = {
  id: string
  type?: EmitterType
  presentation?: string
  website?: string
  recipients?: Array<{ value: string }>
  logo?: SImageData
  classifieds?: Array<Classified>
  name: string
  user?: User
}

export type ClassifiedMedia = SClassifiedMedia['attributes'] & { id: string }

export type ClassifiedAdoptBlock = {
  id: string
  speciality: ClassifiedSpecialty
  patronages: number
}

export type ClassifiedAssistantBlock = {
  id: string
  cabinetType: ClassifiedCabinetType
  speciality: ClassifiedSpecialty
  saturday: boolean
  gestures: string
  contract: Array<string>
  startDate: string
  endDate: string
}

export type ClassifiedBlockOperatoryBlock = {
  id: string
  establishmentName: string
  group: string
  speciality: ClassifiedSpecialty
  description: string
  operatoryHelpProvided: boolean
  toolsProvided: boolean
  treatmentType: Array<string>
  establishmentApproved: boolean
  availabilities: string
}

export type ClassifiedCarabinBlock = {
  id: string
  startDate: string
  endDate: string
  speciality: ClassifiedSpecialty
  cabinetType: ClassifiedCabinetType
}

export type ClassifiedCoordinatorBlock = {
  id: string
  contract: Array<string>
  startDate: string
  endDate: string
  cabinetType: ClassifiedCabinetType
  saturday: boolean
  experience: boolean
}

export type ClassifiedEmployeeBlock = {
  id: string
  job: Array<string>
  contract: Array<string>
  startDate: string
  endDate: string
}

export type ClassifiedGardeBlock = {
  id: string
  specialities: ClassifiedSpecialties
  doctors: Array<string>
  departments: Array<string>
  periods: Array<string>
  type: Array<string>
}

export type ClassifiedInstallationBlock = {
  id: string
  cabinetType: ClassifiedCabinetType
  specialities: ClassifiedSpecialties
  secretariats: ClassifiedSecretariats
  offerType: ClassifiedOfferType
  startDate: string
}

export type ClassifiedLiberalBlock = {
  id: string
  speciality: ClassifiedSpecialty
  cabinetType: ClassifiedCabinetType
  frequencies: Array<string>
  periodMonths: Array<string>
  periodDays: ClassifiedDays
  comments: string
}

export type ClassifiedOperatoryBlock = {
  id: string
  speciality: ClassifiedSpecialty
  days: ClassifiedDays
  frequency: string
  contract: Array<string>
  startDate: string
  endDate: string
}

export type ClassifiedReplacementBlock = {
  id: string
  startDate: string
  endDate: string
  period: ClassifiedDays
  negociableDays: boolean
  sector: string
  softwares: string
  rate: number
  collabReplacement: boolean
  cabinetType: ClassifiedCabinetType
  guard: boolean
  specialities: ClassifiedSpecialties
  secretariats: ClassifiedSecretariats
}

export type ClassifiedSecretaryBlock = {
  id: string
  saturday: boolean
  experience: boolean
  cabinetType: ClassifiedCabinetType
  speciality: ClassifiedSpecialty
  contract: Array<string>
  startDate: string
  endDate: string
}

export type ClassifiedDays = {
  mondayMorning: boolean
  mondayAfternoon: boolean
  tuesdayMorning: boolean
  tuesdayAfternoon: boolean
  wednesdayMorning: boolean
  wednesdayAfternoon: boolean
  thursdayMorning: boolean
  thursdayAfternoon: boolean
  fridayMorning: boolean
  fridayAfternoon: boolean
  saturdayMorning: boolean
  saturdayAfternoon: boolean
  sundayMorning: boolean
  sundayAfternoon: boolean
}

export const ClassifiedBlock = {
  [BlockCategory.Installation]: 'classified.installation',
  [BlockCategory.Secretary]: 'classified.secretary',
  [BlockCategory.Garde]: 'classified.garde',
  [BlockCategory.Coordinator]: 'classified.coordinator',
  [BlockCategory.MedicalAssistant]: 'classified.assistant',
  [BlockCategory.Adopt]: 'classified.adopt',
  [BlockCategory.Job]: 'classified.carabin',
  [BlockCategory.ShareOperatingTheatre]: 'classified.block-operatory',
  [BlockCategory.Liberal]: 'classified.liberal',
  [BlockCategory.Replacement]: 'classified.replacement',
  [BlockCategory.OperatingAid]: 'classified.operatory',
  [BlockCategory.PaidEmployment]: 'classified.employee',
}

export enum ClassifiedEmitterType {
  Doctor = 'Doctor',
  Organisation = 'Organisation',
}

export type ClassifiedAlert = {
  id: string
  terms?: string
  localisation?: string
  user?: User
  specialities?: Array<ClassifiedSpecialty>
  categories?: Array<ClassifiedCategory>
}
