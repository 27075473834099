import { getRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'

export type GetContentTypeSchemaParams = {
  uid: string
}

const GetContentTypeSchema = ({ uid }: GetContentTypeSchemaParams) =>
  getRestRequest({
    url: api.REST_API_URL + `/content-type-builder/content-types/${uid}`,
    transformer: (response: any) => {
      return { ...response.data.schema.attributes }
    },
  })

export default GetContentTypeSchema
