import styled from 'styled-components'
import { mq, sizes } from 'src/theme'

import FormSubmitButton from '../../form/FormSubmitButton'
import FormFieldEmail from '../../form/fields/FormFieldEmail'
import FormikForm from '../../form/FormikForm'

export const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Form = styled(FormikForm)`
  width: 100%;
  display: flex;
  gap: 2.6rem;
  align-items: center;
  flex-direction: column;
  @media ${mq(sizes.tablet)} {
    flex-direction: row;
  }
`

export const Fields = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 3rem;
  flex: 1;
  width: 100%;
`

export const EmailField = styled(FormFieldEmail)``

export const Actions = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 1rem;
  width: fit-content;

  @media ${mq(null, sizes.tablet)} {
    width: 100%;
    justify-content: flex-end;
  }
`

export const SubmitButton = styled(FormSubmitButton)``

export const Text = styled.p`
  width: 100%;
  margin-top: 2rem;
  text-align: left;
  ${({ theme }) => theme.textStyles.montserratSm};
  color: ${({ theme }) => theme.colors.bluePrimary};
`

export const Success = styled.p`
  width: 100%;
  margin-top: 2rem;
  text-align: center;
  ${({ theme }) => theme.textStyles.montserratSm};
  color: ${({ theme }) => theme.colors.greenAccent};
`
