import styled, { css, keyframes } from 'styled-components'

import ActionButton from '../../components/ActionButton'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

export const Button = styled(ActionButton)<{
  $isPending?: boolean
  $isSuccess?: boolean
}>`
  ${(props) =>
    props?.$isPending &&
    css`
      svg {
        animation: ${rotate} 2s linear infinite;
      }
    `}
`
