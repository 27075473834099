import { createSelector, createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../store'
import { getCustomService } from '../../helpers/ReduxHelpers'
import { ToggleReservationParams } from '../../rest/requests/reservations/toggleReservation'
import { Reservation } from '../../rest/types/Reservation'
import { getUserReservationsParams } from '../../rest/requests/reservations/getUserReservations'

import { actionTypes } from './types'

//
// requests
//

export const toggleReservationService = getCustomService<
  'toggleReservation',
  boolean,
  ToggleReservationParams
>('toggleReservation')

export const getUserReservationsService = getCustomService<
  'getUserReservations',
  Array<Reservation>,
  getUserReservationsParams
>('getUserReservations')

export const getAllUserReservationsService = getCustomService<
  'getAllUserReservations',
  Array<Reservation>
>('getAllUserReservations')

//
// Initial state
//

type ReservationsState = {
  reservations: Reservation[]
  toggleReservation: typeof toggleReservationService.state
  getUserReservations: typeof getUserReservationsService.state
  getAllUserReservations: typeof getAllUserReservationsService.state
}

const initialState: ReservationsState = {
  reservations: [],
  toggleReservation: toggleReservationService.state,
  getUserReservations: getUserReservationsService.state,
  getAllUserReservations: getAllUserReservationsService.state,
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: 'reservations',
  initialState,
  reducers: {
    setReservations: (state, { payload }: actionTypes.setReservations) => {
      state.reservations = payload
    },
    ...toggleReservationService.reducers,
    ...getUserReservationsService.reducers,
    ...getAllUserReservationsService.reducers,
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[slice.name]
const reservations = (state: RootState) => root(state).reservations
const reservedOffersIDs = createSelector([reservations], (rs) =>
  rs?.map((r) => r?.offer?.id)
)
const toggleReservation = (state: RootState) => root(state).toggleReservation
const getUserReservations = (state: RootState) =>
  root(state).getUserReservations
const getAllUserReservations = (state: RootState) =>
  root(state).getAllUserReservations

export const selectors = {
  toggleReservation,
  getUserReservations,
  getAllUserReservations,
  reservations,
  reservedOffersIDs,
}
