import { User, UserSpeciality } from '../types/User'
import { SUser, SUserSpecialty } from '../types/api/User'

import { userProfileTransformer } from './userProfileTransformer'
import { userSpecialityTransformer } from './userSpecialityTransformer'

export const userTransformer = (data: SUser): User => {
  return {
    id: data.id ?? '',
    ...data.attributes,
    ...(data.attributes?.profile?.data && {
      profile: userProfileTransformer(data.attributes.profile.data),
    }),
    ...(data.attributes?.speciality?.data && {
      speciality: userSpecialityTransformer(data.attributes.speciality.data),
    }),
  }
}

export const userSpecialtyTransformer = (
  data: SUserSpecialty
): UserSpeciality => {
  return {
    id: data.id,
    ...data.attributes,
  }
}
