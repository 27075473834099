import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import QueryString from 'qs'

import Router from '../../routes/Router'
import { actions, selectors } from '..'

export default class FiltersSagas {
  static *init() {
    yield put(actions.filters.resetState())
  }

  static *onRedirect({ payload }: ReturnType<any>) {
    const filters: ReturnType<typeof selectors.filters.filters> = yield select(
      selectors.filters.filters
    )
    const limit: ReturnType<typeof selectors.filters.limit> = yield select(
      selectors.filters.limit
    )

    const string: string = yield call(
      QueryString.stringify,
      {
        filters: {
          ...filters,
        },
      },
      {
        encodeValuesOnly: true,
        delimiter: ',',
      }
    )

    const fullUrl = `${payload.url}?filters=${string}&limit=${limit}`

    yield put(actions.filters.resetState())
    yield call(Router.push, fullUrl)
  }

  static *loop() {
    yield all([takeLatest(actions.filters.redirect, this.onRedirect)])
  }
}
