import { dataToFormData, postRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'
import { User } from '../../types/User'

export type RegisterResponse = {
  user: User
}

export type RegisterParams = {
  civility: string
  firstName: string
  lastName: string
  email: string
  profile: string
  speciality: string
  password: string
}

const register = (data: RegisterParams) =>
  postRestRequest({
    url: api.REST_API_URL + `/auth/local/register`,
    data: dataToFormData({
      ...data,
      username: data.email,
    }),
    transformer: (data: RegisterResponse) => {
      return data.user
    },
  })

export default register
