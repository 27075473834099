import { useEffect, useState } from 'react'

export const useIsFront = () => {
  const [ready, setReady] = useState<boolean>(false)

  useEffect(() => {
    setReady(true)
  }, [])

  return ready
}
