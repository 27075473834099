import { postRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'
import { SimpleResult } from '../../types/PaginatedResult'
import { SClassified } from '../../types/api/Classified'
import { classifiedTransformer } from '../../transformers/classifiedTransformer'

type CreateResponse = SimpleResult<SClassified>

export type CreateParams = {
  user: string
}

const create = (data: CreateParams) =>
  postRestRequest({
    url: api.REST_API_URL + `/classifieds`,
    data: {
      data: {
        user: [data.user],
      },
    },
    transformer: (response: CreateResponse) => {
      return classifiedTransformer(response.data)
    },
  })

export default create
