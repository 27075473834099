import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { REHYDRATE } from 'redux-persist'

import type { RootState } from '../store'

import { actionTypes } from './types'

const name = 'app'

type AppState = {
  isInit: boolean
  loginModalOpen: boolean
}

//
// Initial state
//

const initialState: AppState = {
  isInit: false,
  loginModalOpen: false,
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name,
  initialState,
  reducers: {
    init: () => undefined,
    setLoginModalOpen: (state, action: actionTypes.setLoginModalOpen) => {
      state.loginModalOpen = action.payload
    },
    setIsInit: (state, action: actionTypes.setIsInit) => {
      const { isInit } = action.payload
      state.isInit = isInit
    },
  },
  extraReducers: (builder) =>
    builder.addCase<any, PayloadAction<any>>(
      REHYDRATE,
      (_state, action: PayloadAction<any>) => {
        return {
          ...(action.payload?.[name] ?? initialState),
          isInit: false,
          loginModalOpen: false,
        }
      }
    ),
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[name]
const isInit = (state: RootState) => root(state).isInit
const loginModalOpen = (state: RootState) => root(state).loginModalOpen

export const selectors = {
  isInit,
  loginModalOpen,
}
