import { postRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'

type FeedbacksResponse = {
  status?: boolean
}

export type AddFeedbackParams = {
  rating: string
  text: string
  offer: string | number
}

const addFeedback = ({ offer, ...data }: AddFeedbackParams) =>
  postRestRequest({
    url: api.REST_API_URL + `/feedbacks/offer/${offer}`,
    data,
    transformer: (response: FeedbacksResponse): boolean => {
      return !!response?.status
    },
  })

export default addFeedback
