import { useCallback, useEffect, useRef, useState } from 'react'

import useTimeout from './useTimeout'

export const useInactivity = (
  timeoutDuration: number,
  countdownDuration: number,
  callbacks?: {
    onInactivityConfirmed?: () => void
    onInactivityDetected?: () => void
    onInactivityCancelled?: () => void
  }
): {
  inactivityOpen: boolean
  inactivityCountdown: number
  handleActivity: () => void
} => {
  const [inactivityOpen, setInactivityOpen] = useState(false)
  const [inactivityCountdown, setInactivityCountdown] = useState(
    countdownDuration / 1000
  )
  const inactivityCountdownInterval = useRef<NodeJS.Timeout>()

  const { onInactivityConfirmed, onInactivityDetected, onInactivityCancelled } =
    callbacks ?? {}

  const handleInactivityConfirmed = useCallback(() => {
    onInactivityConfirmed?.()
    setInactivityOpen(false)
  }, [onInactivityConfirmed])

  const [launchInactivityCountdown, clearInactivityCountdown] = useTimeout(
    handleInactivityConfirmed,
    countdownDuration
  )

  const handleInactivity = useCallback(() => {
    setInactivityOpen(true)
    onInactivityDetected?.()
  }, [onInactivityDetected])

  const [launchInactivityTimeout] = useTimeout(
    handleInactivity,
    timeoutDuration
  )

  const handleActivity = useCallback(() => {
    setInactivityOpen(false)
    setInactivityCountdown(countdownDuration / 1000)
    launchInactivityTimeout()
  }, [countdownDuration, launchInactivityTimeout])

  useEffect(() => {
    if (!inactivityOpen) {
      onInactivityCancelled?.()
    }
  }, [inactivityOpen, onInactivityCancelled])

  useEffect(() => {
    if (inactivityOpen) {
      launchInactivityCountdown()
    } else {
      clearInactivityCountdown()
    }
  }, [inactivityOpen, launchInactivityCountdown, clearInactivityCountdown])

  useEffect(() => {
    if (inactivityOpen) {
      inactivityCountdownInterval.current = setInterval(
        () => setInactivityCountdown((before) => before - 1),
        1000
      )
    }

    return () => {
      if (inactivityCountdownInterval.current) {
        clearInterval(inactivityCountdownInterval.current)
      }
    }
  }, [inactivityOpen])

  // Reset countdown value
  useEffect(() => {
    if (!inactivityOpen) {
      setInactivityCountdown(countdownDuration / 1000)
    }
  }, [countdownDuration, inactivityOpen])

  useEffect(() => {
    launchInactivityTimeout()
  }, [launchInactivityTimeout])

  return {
    inactivityOpen,
    inactivityCountdown,
    handleActivity,
  }
}
