import { deleteRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'

export type DeleteMediaParams = {
  id: string
}

const deleteMedia = ({ id }: DeleteMediaParams) =>
  deleteRestRequest({
    url: api.REST_API_URL + `/classified-medias/${id}`,
    transformer: () => {
      return true
    },
  })

export default deleteMedia
