export const isEmptyFile = (file: File) => {
  return file.size === 0
}

export const toBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = reject
  })

export const getSize = (
  file: File
): Promise<{ width: number; height: number }> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      const image = new Image()
      image.onload = () =>
        resolve({
          width: image.width,
          height: image.height,
        })
      image.src = reader.result as string
    }
    reader.onerror = reject
  })

export function isBase64ImageURL(url: string) {
  return /^data:image\/[a-zA-Z+]+;base64,([^\s]+)$/.test(url)
}
