import qs from 'qs'

import { deleteRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'
import { SimpleResult } from '../../types/PaginatedResult'
import { SAlert } from '../../types/api/Alert'
import { classifiedAlertTransformer } from '../../transformers/classifiedTransformer'

export type DeleteAlertResponse = SimpleResult<SAlert>

export type DeleteAlertParams = {
  id: string
}

const deleteAlert = ({ id }: DeleteAlertParams) => {
  return deleteRestRequest({
    url: `${api.REST_API_URL}/classified-alerts/${id}?${qs.stringify(
      {
        populate: {
          category: {
            fields: ['id', 'name'],
          },
          speciality: {
            fields: ['id', 'name'],
          },
        },
      },
      { encodeValuesOnly: true }
    )}`,
    transformer: (response: DeleteAlertResponse) => {
      return classifiedAlertTransformer(response.data)
    },
  })
}
export default deleteAlert
