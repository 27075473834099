import i18next from 'i18next'
import { getOperationName } from '@apollo/client/utilities'
import { GetServerSidePropsContext } from 'next'

import { MetasProps } from '../components/Metas'
import { app } from '../configuration'
import ssrClient from '../graphql/ssrClient'

import { QueryService, transformErrors } from './GraphqlHelpers'

export const ssrQuery = async function (
  service: QueryService,
  variables?: any
) {
  let result
  const start = new Date().getTime()
  try {
    result = await ssrClient.query({
      query: service?.query,
      variables,
    })
  } catch (e: any) {
    result = {
      errors: e,
      data: null,
    }
  }
  console.log(
    '[ssrQuery]',
    service?.name ?? getOperationName(service.query),
    'duration',
    new Date().getTime() - start,
    'ms'
  )
  const data = result?.data
  if (result?.errors) {
    console.error(`ssrQuery Errors: `, result?.errors)
  }
  const errors = transformErrors(result?.errors)
  const transformer = service?.transformer
  return {
    ...result,
    errors,
    data: data && transformer ? transformer(data) : data,
  }
}

export const noUndefined = function (source: any) {
  const result = source ? Object.assign(source) : null
  if (result) {
    Object.keys(source).forEach((k) => {
      if (result[k] === undefined) result[k] = null
      else if (Array.isArray(result[k])) result[k] = result[k].map(noUndefined)
      else if (typeof result[k] === 'object') result[k] = noUndefined(result[k])
    })
  }
  return source
}

export const getAllPaginatedResults = async (
  query: QueryService,
  transform: (v: any) => any = (data) => data
) => {
  const firstResult = await ssrQuery(query, { page: 1 })
  const results = await Promise.all(
    Array.from({ length: firstResult?.data?.paginatorInfo?.lastPage - 1 }).map(
      (_a, index) => ssrQuery(query, { page: index + 2 })
    )
  )
  results.unshift(firstResult)
  return results.reduce(
    (arr, result) => arr.concat(result?.data?.map(transform)),
    []
  )
}

export const transformSeoToMetas = (asPath: string, seo?: any): MetasProps => {
  return {
    applicationName: i18next.t('applicationName'),
    locale: 'fr_FR',
    title: seo?.title ?? '',
    description: seo?.description ?? '',
    image: seo?.picture ? app.APP_URL + seo?.picture : '', // todo transform picture ?
    robots: seo?.robots ?? '',
    url: app.APP_URL + asPath,
  }
}

export function setDefaultCacheHeader(context: GetServerSidePropsContext) {
  return context.res.setHeader(
    'Cache-Control',
    'public, s-maxage=10, stale-while-revalidate=59'
  )
}

export function isMaintenance(token?: any) {
  if (token === app.MAINTENANCE_TOKEN) {
    return false
  }
  return app.MAINTENANCE_MODE === 'true'
}
