/*
 * This project use mobile first integration
 * Default media is mobile
 */

const sizes = {
  mobile: 576,
  tablet: 768,
  desktop: 992,
  video: 1070,
  scoping: 1200,
  scopingMedium: 1280,
  scopingLarge: 1340,
  scopingMax: 1440,
}

const up = (val: keyof typeof sizes) => `@media (min-width: ${sizes?.[val]}px)`
const down = (val: keyof typeof sizes) =>
  `@media (max-width: ${sizes?.[val]}px)`

export const breakpoints = {
  up,
  down,
}

export default sizes
