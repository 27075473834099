import { putRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'
import { SimpleResult } from '../../types/PaginatedResult'
import { SClassified } from '../../types/api/Classified'
import { classifiedTransformer } from '../../transformers/classifiedTransformer'
import { Status } from '../../types/Classified'

type ArchiveResponse = SimpleResult<SClassified>

export type ArchiveParams = {
  id: number
  suggestions: string
  recommended: boolean
  filled: boolean
  service: string
}

const archive = ({ id, ...data }: ArchiveParams) => {
  const formData = new FormData()

  formData.append(
    'data',
    JSON.stringify({
      ...data,
      status: Status.Archived,
      archivedAt: new Date(),
    })
  )

  return putRestRequest({
    url: api.REST_API_URL + `/classifieds/${id}`,
    data: formData,
    transformer: (response: ArchiveResponse) => {
      return classifiedTransformer(response.data)
    },
  })
}

export default archive
