import { postRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'
import { SimpleResult } from '../../types/PaginatedResult'
import { SClassifiedMedia } from '../../types/api/Classified'
import { classifiedMediaTransformer } from '../../transformers/classifiedTransformer'

type addMediaResponse = SimpleResult<SClassifiedMedia>

export type UpdateParams = {
  data: any
  files?: Array<{ name: string; value: File }>
}

const addMedia = ({ data, files }: UpdateParams) => {
  const formData = new FormData()

  if (files?.length) {
    for (const file of files) {
      formData.append(file.name, file.value)
    }
  }

  formData.append('data', JSON.stringify(data))

  return postRestRequest({
    url: api.REST_API_URL + `/classified-medias`,
    data: formData,
    transformer: (response: addMediaResponse) => {
      return classifiedMediaTransformer(response.data)
    },
  })
}

export default addMedia
