import QueryString from 'qs'

import { PaginatedResult } from '../../types/PaginatedResult'
import { getRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'
import { OffersWithTotal } from '../../types/Offer'
import { SOffer } from '../../types/api/Offer'

type OffersResponse = PaginatedResult<SOffer>

export type GetOffersWithTotal = {
  filters?: { [key: string]: any }
  limit?: string | number
  offset?: string | number
  sort?: any
}

const getOffersWithTotal = (params: GetOffersWithTotal) =>
  getRestRequest({
    url:
      api.REST_API_URL +
      `/offers?${QueryString.stringify(
        {
          ...(params.filters && {
            filters: {
              ...params.filters,
            },
          }),
          populate: {
            card: {
              populate: '*',
            },
            image: {
              populate: '*',
            },
            feedbacks: {
              populate: '*',
            },
          },
          pagination: {
            ...(params.limit !== undefined && {
              limit: params.limit,
            }),
            ...(params.offset !== undefined && {
              start: params.offset,
            }),
          },
          ...(params.sort && {
            sort: params.sort,
          }),
        },
        {
          encodeValuesOnly: true,
        }
      )}`,
    transformer: (response: OffersResponse): OffersWithTotal => {
      return {
        total: response.meta?.pagination?.total,
        offers: response?.data?.map((offer) => ({
          id: offer?.id,
          ...offer?.attributes,
        })),
      }
    },
  })

export default getOffersWithTotal
