import { OperationVariables } from '@apollo/client/core/types'
import {
  MutationOptions,
  QueryOptions,
  TypedDocumentNode,
} from '@apollo/client'
import { ResultOf } from '@graphql-typed-document-node/core'
import { getOperationName } from '@apollo/client/utilities'

// TYPES

export type QueryService<
  TVariables = OperationVariables,
  TData = any,
  Transformer = (
    response: ResultOf<TypedDocumentNode<TData, TVariables>>
  ) => any
> = QueryOptions<TVariables, TData> & {
  name?: string
  transformer: Transformer
}

export type MutationService<
  TVariables = OperationVariables,
  TData = any,
  Transformer = (
    response: ResultOf<TypedDocumentNode<TData, TVariables>>
  ) => any
> = MutationOptions<TData, TVariables> & {
  name?: string
  transformer: Transformer
}

export type ApiResponse<
  Service extends QueryService<any> | MutationService<any> = any
> = {
  // TODO: conditionnal type to make transformer optionnal ?
  // data: Service extends { transformer: NonNullable<any> }
  //   ? ReturnType<Service['transformer']>
  //   : Service extends MutationService<TVariables, TData, Transformer>
  //   ? ResultOf<Service['mutation']>
  //   : Service extends QueryService<TVariables, TData, Transformer>
  //   ? ResultOf<Service['query']>
  //   : any
  data: ReturnType<Service['transformer']>
  errors: any
}

export type GraphqlError = {
  label?: string
  field?: string
}
export type GraphqlErrors = GraphqlError[]

// FUNCTIONS

export const getQueryService = <
  TVariables = OperationVariables,
  TData = any,
  Transformer = (
    response: ResultOf<TypedDocumentNode<TData, TVariables>>
  ) => any
>(
  service: QueryService<TVariables, TData, Transformer>
) => ({
  ...service,
  name: service.name || (getOperationName(service.query) ?? ''),
})

export const getMutationService = <
  TVariables = OperationVariables,
  TData = any,
  Transformer = (
    response: ResultOf<TypedDocumentNode<TData, TVariables>>
  ) => any
>(
  service: MutationService<TVariables, TData, Transformer>
) => ({
  ...service,
  name: service.name || (getOperationName(service.mutation) ?? ''),
})

const transformError = (error: any): GraphqlError => {
  if (error?.extensions?.category === 'validation') {
    const validation = error?.extensions[error?.extensions?.category]

    return Object.keys(validation).reduce(
      (_r, v) => ({
        field: v.replace('input.', ''),
        label: validation[v][0],
      }),
      {}
    )
  }

  if (error?.message) {
    return {
      label: error.message,
    }
  }

  return {}
}

export const transformErrors = (errors: any): GraphqlErrors =>
  (errors &&
    Array.isArray(errors) &&
    errors.map(transformError)?.filter((e) => !!e.label)) ||
  []

export const enumOption = (
  t: any,
  name: string,
  value: string
): { label: string; value: string } => ({
  label: t(`enum_${name}_${value}`),
  value,
})
