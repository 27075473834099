import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../store'
import { getCustomService } from '../../helpers/ReduxHelpers'
import { OffersWithTotal } from '../../rest/types/Offer'
import { GetOffersWithTotal } from '../../rest/requests/offers/getOffersWithTotal'

//
// requests
//

export const getOffersService = getCustomService<
  'getOffers',
  OffersWithTotal,
  GetOffersWithTotal
>('getOffers')

//
// Initial state
//

type ReservationsState = {
  getOffers: typeof getOffersService.state
}

const initialState: ReservationsState = {
  getOffers: getOffersService.state,
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    redirectWithFilters: () => undefined,
    // Services
    ...getOffersService.reducers,
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[slice.name]
const getOffers = (state: RootState) => root(state).getOffers

export const selectors = {
  getOffers,
}
