const PORT = String(process.env.PORT) !== '80' && String(process.env.PORT)

const APP = {
  APP_ENV: String(process.env.APP_ENV) || 'production',
  APP_VERSION: String(process.env.APP_VERSION) || '',
  GTM_ID: String(process.env.NEXT_PUBLIC_GTM_ID) || '',
  BANNER: String(process.env.BANNER) || false,
  PORT,
  APP_URL: String(process.env.NEXT_PUBLIC_APP_URL),
  APP_PREVIEW_URL: String(process.env.NEXT_PUBLIC_APP_PREVIEW_URL),
  SECURE_COOKIE: String(process.env.NEXT_PUBLIC_SECURE_COOKIE) === 'true',
  COOKIE_DOMAIN: String(process.env.NEXT_PUBLIC_COOKIE_DOMAIN),
  COOKIE_NAME: String(process.env.NEXT_PUBLIC_COOKIE_NAME) || 'auth_token',
  IS_PREVIEW: ['true', '1'].includes(
    String(process.env.NEXT_PUBLIC_IS_PREVIEW)
  ),
  NO_INDEX: ['true', '1'].includes(String(process.env.NO_INDEX)),
  USE_PWA: ['true', '1'].includes(String(process.env.USE_PWA)),
  PERSIST_STORE_ENABLED: ['true', '1'].includes(
    String(process.env.NEXT_PUBLIC_PERSIST_STORE_ENABLED)
  ),
  IGNORE_API_SSL_ERRORS: ['true', '1'].includes(
    String(process.env.NEXT_PUBLIC_IGNORE_API_SSL_ERRORS)
  ),
  ALGOLIA_APP_ID: process.env.NEXT_PUBLIC_ALGOLIA_PROVIDER_APPLICATION_ID
    ? String(process.env.NEXT_PUBLIC_ALGOLIA_PROVIDER_APPLICATION_ID)
    : '',
  ALGOLIA_API_KEY: process.env.NEXT_PUBLIC_ALGOLIA_PROVIDER_ADMIN_API_KEY
    ? String(process.env.NEXT_PUBLIC_ALGOLIA_PROVIDER_ADMIN_API_KEY)
    : '',
  ALGOLIA_INDEX_PREFIX: process.env.NEXT_PUBLIC_ALGOLIA_INDEX_PREFIX
    ? String(process.env.NEXT_PUBLIC_ALGOLIA_INDEX_PREFIX)
    : '',
  MAINTENANCE_MODE: String(process.env.NEXT_PUBLIC_MAINTENANCE_MODE),
  MAINTENANCE_TOKEN: String(process.env.NEXT_PUBLIC_MAINTENANCE_TOKEN),
  ADDRESS_API_ALLOWED_ZONES: process.env.NEXT_PUBLIC_ADDRESS_API_ALLOWED_ZONES
    ? String(process.env.NEXT_PUBLIC_ADDRESS_API_ALLOWED_ZONES).split(',')
    : [],
}

export default APP
