import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../store'
import { getCustomService } from '../../helpers/ReduxHelpers'
import { GeoAddress } from '../../rest/types/GeoAddress'
import { GetAddressesParams } from '../../rest/requests/geo/getAddress'

//
// Services
//

export const getAddressesService = getCustomService<
  'getAddresses',
  Array<GeoAddress>,
  GetAddressesParams
>('getAddresses')

//
// Initial state
//

export type FeedbacksState = {
  // Services
  getAddresses: typeof getAddressesService.state
}

const initialState: FeedbacksState = {
  // Services
  getAddresses: getAddressesService.state,

  // Customs
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: 'geo',
  initialState,
  reducers: {
    // Services
    ...getAddressesService.reducers,
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[slice.name]
const getAddresses = (state: RootState) => root(state).getAddresses

export const selectors = {
  // Services
  getAddresses,
}
