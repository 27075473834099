import { gql } from '../../../generated'
import { getQueryService } from '../../../../helpers/GraphqlHelpers'

export const userById = getQueryService({
  query: gql(/* GraphQL */ `
    query ExampleUserById($id: ID!) {
      User(id: $id) {
        ...UserFragment
      }
    }
  `),
  transformer: (response) => {
    return response.User
  },
})
