import styled, { css } from 'styled-components'

import FormFieldInput from '../FormFieldInput'
import Icon from '../../../components/Icon'

export const Container = styled.div``

export const Wrapper = styled.div`
  display: flex;
  gap: 1.6rem;
  align-items: flex-end;
`

export const Input = styled(FormFieldInput)`
  width: 100%;

  & input {
    opacity: 0;
    position: absolute;
  }
`

export const Value = styled.p`
  ${({ theme }) => theme.textStyles.montserratSm};
  color: ${({ theme }) => theme.colors.bluePrimary};
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const Placeholder = styled.p`
  color: ${({ theme }) => theme.colors.dustyGray};
  ${({ theme }) => theme.textStyles.montserratSm};
`

export const Delete = styled(Icon)``

export const Upload = styled(Icon)``

export const ModalButton = styled.button<{ $hasError: boolean }>`
  flex-shrink: 0;
  width: 3.9rem;
  height: 3.9rem;
  border-radius: 0.5rem;
  background: ${({ theme }) => theme.colors.pureWhite};
  border: 1px solid ${({ theme }) => theme.colors.greyContour};
  margin: 0;
  padding: 0;

  ${({ $hasError }) =>
    $hasError &&
    css`
      align-self: center;
    `};
`
