import Axios, {
  AxiosHeaders,
  AxiosRequestHeaders,
  AxiosResponse,
  isAxiosError,
} from 'axios'

import {
  RestApiResponse,
  RestApiTransformer,
  RestRequest,
} from '../helpers/RestHelpers'

class RestApiClient {
  static async call<Request extends RestRequest>(
    request: Request,
    headers?: AxiosHeaders
  ): Promise<RestApiResponse<Request>> {
    let result: RestApiResponse
    const { transformer, ...config } = request

    try {
      result = await Axios({
        ...config,
        headers: {
          ...config?.headers,
          ...headers,
        },
      })
    } catch (e) {
      console.error(`RestApi Error:`, e)
      if (isAxiosError(e) && e.response) {
        return e?.response as AxiosResponse
      }

      return {
        data: undefined,
        errors: (e as Error)?.message ?? 'error',
        status: 500,
        statusText: (e as Error)?.message ?? 'error',
        headers: {
          ...config?.headers,
          ...headers,
        },
        config: {
          ...config,
          headers: config.headers as AxiosRequestHeaders,
        },
      }
    }

    const data = await RestApiClient.transform(result.data, transformer)

    return { ...result, data }
  }

  static async transform(
    data: RestApiResponse['data'],
    transformer: RestApiTransformer<Transformer>
  ) {
    if (!data || !transformer) {
      return data
    }

    return await transformer(data)
  }
}

export default RestApiClient
