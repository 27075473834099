import { all, put, takeLatest } from 'redux-saga/effects'

import { actions } from '..'
import ApiSagas from '../api/sagas'
import getPaymentIntent from '../../rest/requests/stripe/getPaymentIntent'
import getPrice from '../../rest/requests/stripe/getPrice'
import getInvoices from '../../rest/requests/stripe/getInvoices'

export default class AuthSagas {
  static *onGetPaymentIntentRequest({
    payload,
  }: ReturnType<typeof actions.stripe.getPaymentIntentRequest>) {
    const response = yield* ApiSagas.rest(getPaymentIntent(payload))

    if (response.data) {
      yield put(actions.auth.setUser(response.data.user))
      yield put(actions.stripe.getPaymentIntentSuccess(response.data))
    }
  }

  static *onGetPriceRequest() {
    const response = yield* ApiSagas.rest(getPrice())

    if (response.data) {
      yield put(actions.stripe.getPriceSuccess(response.data))
    }
  }

  static *onGetInvoicesRequest() {
    const response = yield* ApiSagas.rest(getInvoices())

    if (response.data) {
      yield put(actions.stripe.getInvoicesSuccess(response.data))
    }
  }

  static *loop() {
    yield all([
      takeLatest(
        actions.stripe.getPaymentIntentRequest,
        this.onGetPaymentIntentRequest
      ),
      takeLatest(actions.stripe.getPriceRequest, this.onGetPriceRequest),
      takeLatest(actions.stripe.getInvoicesRequest, this.onGetInvoicesRequest),
    ])
  }
}
