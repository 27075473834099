import { dataToFormData, postRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'
import { User } from '../../types/User'

export type EmailLoginResponse = {
  jwt: string
  user: User
}

export type EmailLoginParams = {
  identifier: string
  password: string
}

const emailLogin = (payload: EmailLoginParams) =>
  postRestRequest({
    url: api.REST_API_URL + `/auth/local`,
    data: dataToFormData(payload),
    transformer: (data: EmailLoginResponse): EmailLoginResponse => {
      return data
    },
  })

export default emailLogin
