import { all, put, takeEvery } from 'redux-saga/effects'

import ApiSagas from '../api/sagas'
import { actions } from '../index'
import upload from '../../rest/requests/media/upload'
import GetContentTypeSchema from '../../rest/requests/content-types-builder/getContentTypeSchema'

export default class UploadSagas {
  static *onAlertRequest({
    payload,
  }: ReturnType<typeof actions.alert.alertRequest>) {
    const response = yield* ApiSagas.rest(upload(payload))

    if (response.data) {
      yield put(actions.upload.uploadSuccess(response.data))
    } else {
      yield put(actions.upload.uploadError(response.errors))
    }
  }

  static *onGetAlertSchemaRequest() {
    const response = yield* ApiSagas.rest(
      GetContentTypeSchema({ uid: 'api::classified-alert.classified-alert' })
    )

    if (response.data) {
      yield put(actions.alert.getAlertSchemaSuccess(response.data))
    } else {
      yield put(actions.alert.getAlertSchemaError(response.errors))
    }
  }

  static *loop() {
    yield all([
      takeEvery(actions.alert.alertRequest, this.onAlertRequest),
      takeEvery(
        actions.alert.getAlertSchemaRequest,
        this.onGetAlertSchemaRequest
      ),
    ])
  }
}
