import React, { FC, ReactNode } from 'react'
import cx from 'classnames'

import * as SC from './styled'

export type FormLabelProps = React.LabelHTMLAttributes<HTMLLabelElement> & {
  className?: string
  required?: boolean
  error?: boolean
  children?: ReactNode
  underlined?: boolean
}

const FormLabel: FC<FormLabelProps> = ({
  error,
  children,
  underlined,
  className,
  ...props
}) => {
  return (
    <SC.Label
      {...props}
      $error={error}
      $underlined={underlined}
      className={cx('FormLabel', className)}
    >
      {children}
      {underlined && <SC.Line />}
    </SC.Label>
  )
}

export default FormLabel
