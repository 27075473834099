import { postRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'

export type IncreaseViewParams = {
  user: string
  classified: string
}

const increaseView = (data: IncreaseViewParams) =>
  postRestRequest({
    url: api.REST_API_URL + `/classified-views`,
    data: {
      data: data,
    },
    transformer: () => {
      return true
    },
  })

export default increaseView
