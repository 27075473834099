import { deleteRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'
import { SimpleResult } from '../../types/PaginatedResult'
import { SFeedback } from '../../types/api/Feedback'

type FeedbacksResponse = SimpleResult<SFeedback>

export type DeleteFeedbackParams = {
  feedback: string | number
}

const deleteFeedback = ({ feedback, ...data }: DeleteFeedbackParams) =>
  deleteRestRequest({
    url: api.REST_API_URL + `/feedbacks/${feedback}`,
    data: {
      data,
    },
    transformer: (response: FeedbacksResponse): boolean => {
      return !!response?.data.id
    },
  })

export default deleteFeedback
