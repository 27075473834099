import QueryString from 'qs'

import { PaginatedResult } from '../../types/PaginatedResult'
import { getRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'
import { SAlert } from '../../types/api/Alert'
import { classifiedAlertTransformer } from '../../transformers/classifiedTransformer'

type GetAlertsResponse = PaginatedResult<SAlert>

export type GetAlertsParams = {
  filters?: { [key: string]: any }
  populate?: { [key: string]: any }
  pagination?: {
    start?: number
    limit?: number
    page?: number
    pageSize?: number
    withCount?: boolean
  }
  sort?: string | Array<string>
}

const getAlerts = (data: GetAlertsParams) => {
  return getRestRequest({
    url: `${api.REST_API_URL}/classified-alerts?${QueryString.stringify(
      {
        ...data,
      },
      {
        encodeValuesOnly: true,
      }
    )}`,
    transformer: (response: GetAlertsResponse) => {
      return response.data
        ? response.data?.map((c) => classifiedAlertTransformer(c))
        : []
    },
  })
}
export default getAlerts
