type Tag = { label: string; value: string }

export const stringToTags = (string: string): Array<Tag> => {
  const parts = string?.split(',')

  if (!parts?.length) {
    return []
  }

  return parts.map((word) => ({ value: word, label: word }))
}

export const tagsToString = (tags: Array<Tag>): string => {
  return tags.map((tag) => tag.value).join(',')
}

export const stringToOption = (string: string) => {
  const parts = string.split(':')
  return { label: parts?.[0], value: parts?.[1] }
}
