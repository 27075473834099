import { dataToFormData, postRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'

export type ForgottenPasswordResponse = boolean

export type ForgottenPasswordParams = {
  email: string
}

const forgottenPassword = (data: ForgottenPasswordParams) =>
  postRestRequest({
    url: api.REST_API_URL + `/auth/forgot-password`,
    data: dataToFormData(data),
    transformer: (data: ForgottenPasswordResponse) => {
      return data
    },
  })

export default forgottenPassword
