import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import Pubnub, { ChannelMembershipObject, ObjectCustom } from 'pubnub'
import { REHYDRATE } from 'redux-persist'

import type { RootState } from '../store'
import { Classified } from '../../rest/types/Classified'

const name = 'chat'
export type UnreadMessagesCount = { [key: string]: number }

type ChatState = {
  memberships: ChannelMembershipObject<ObjectCustom, ObjectCustom>[] | null
  currentChannel: string | null
  usersMetadata: Pubnub.UUIDMetadataObject<Pubnub.ObjectCustom>[] | null
  unreadMessagesCount: UnreadMessagesCount
}

//
// Initial state
//

const initialState: ChatState = {
  memberships: null,
  currentChannel: null,
  usersMetadata: null,
  unreadMessagesCount: {},
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name,
  initialState,
  reducers: {
    init: () => undefined,
    setMemberships: (
      state,
      action: PayloadAction<
        ChannelMembershipObject<ObjectCustom, ObjectCustom>[] | null
      >
    ) => {
      state.memberships = action.payload
    },
    setCurrentChannel: (state, action: PayloadAction<string | null>) => {
      state.currentChannel = action.payload
    },
    setUnreadMessagesCount: (
      state,
      action: PayloadAction<UnreadMessagesCount>
    ) => {
      state.unreadMessagesCount = action.payload
    },
    setUsersMetadata: (
      state,
      action: PayloadAction<Pubnub.UUIDMetadataObject<Pubnub.ObjectCustom>[]>
    ) => {
      state.usersMetadata = action.payload
    },
    createNewChannel: (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<{
        message: string
        classifiedAuthor: Classified | undefined
        response: string
      }>
    ) => undefined,
    sendFirstMessage: (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<{
        message: string
        classifiedAuthor: Classified | undefined
      }>
    ) => undefined,
    refreshUnreadCount: () => undefined,
  },
  extraReducers: (builder) =>
    builder.addCase<any, PayloadAction<any>>(REHYDRATE, () => {
      return { ...initialState }
    }),
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[name]
const memberships = (state: RootState) => root(state).memberships
const currentChannel = (state: RootState) => root(state).currentChannel
const usersMetadata = (state: RootState) => root(state).usersMetadata
const unreadMessagesCount = (state: RootState) =>
  root(state).unreadMessagesCount
const hasUnreadMessages = createSelector(
  [unreadMessagesCount],
  (count) =>
    Object.values(count).reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    ) > 0
)

export const selectors = {
  memberships,
  currentChannel,
  usersMetadata,
  unreadMessagesCount,
  hasUnreadMessages,
}
