import QueryString from 'qs'

import { getRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'
import { PaginatedResult } from '../../types/PaginatedResult'
import { SClassifiedSpecialty } from '../../types/api/Classified'
import { classifiedSpecialtyTransformer } from '../../transformers/classifiedTransformer'

type GetClassifiedSpecialtiesResponse = PaginatedResult<SClassifiedSpecialty>
export type getSpecialtiesParams = {
  filters?: { [key: string]: any }
  populate?: { [key: string]: any }
  pagination?: {
    start?: number
    limit?: number
    page?: number
    pageSize?: number
    withCount?: boolean
  }
  sort?: string | Array<string>
}

const getSpecialties = (data: getSpecialtiesParams) =>
  getRestRequest({
    url: `${api.REST_API_URL}/classified-specialities?${QueryString.stringify(
      {
        pagination: {
          limit: -1,
          ...(data.pagination ?? {}),
        },
        ...data,
      },
      {
        encodeValuesOnly: true,
      }
    )}
    `,
    transformer: (response: GetClassifiedSpecialtiesResponse) => {
      return response.data.map((specialty) =>
        classifiedSpecialtyTransformer(specialty)
      )
    },
  })

export default getSpecialties
