import React, { FC, useRef } from 'react'
import { useTheme } from 'styled-components'

import { FormFieldInputProps } from '../FormFieldInput'
import FormFieldPreview, { FormFieldPreviewProps } from '../FormFieldPreview'

import * as SC from './styled'

export type FormFieldFileProps = Omit<FormFieldInputProps, 'type' | 'value'> & {
  withButton: boolean
  value?: File | null
  preview?: FormFieldPreviewProps
  onDeletePhoto?: () => void
}

const FormFieldFile: FC<FormFieldFileProps> = ({
  className,
  onChange,
  name,
  value,
  preview,
  withButton = true,
  onDeletePhoto,
  hasError,
  ...props
}) => {
  const theme = useTheme()
  const hasValue = value?.name ? true : false
  const inputRef = useRef<any>()

  return (
    <SC.Container>
      <SC.Wrapper>
        {preview ? (
          <FormFieldPreview {...props} value={value} name={name} {...preview} />
        ) : (
          <>
            <SC.Input
              leftAdornment={
                <SC.Upload
                  icon="upload"
                  width={20}
                  height={20}
                  color={theme.colors.greyContour}
                />
              }
              {...props}
              name={name}
              className={className}
              type={'file'}
              value={!value?.name ? value?.name : ''}
              onRef={(ref: any) => (inputRef.current = ref)}
              onChange={(e: any) =>
                onChange?.({
                  target: {
                    name,
                    value: e?.target?.files?.[0],
                  },
                })
              }
              rightAdornment={
                hasValue ? (
                  <SC.Value>{value?.name}</SC.Value>
                ) : (
                  <SC.Placeholder>{props.placeholder}</SC.Placeholder>
                )
              }
            />
            {withButton && (
              <SC.ModalButton type="button" $hasError={hasError}>
                <SC.Delete
                  icon="delete"
                  width={20}
                  height={20}
                  color={theme.colors.bluePrimary}
                  onClick={onDeletePhoto}
                />
              </SC.ModalButton>
            )}
          </>
        )}
      </SC.Wrapper>
    </SC.Container>
  )
}

export default FormFieldFile
