import { putRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'

type FeedbacksResponse = {
  status?: boolean
}

export type UpdatedFeedbackParams = {
  rating: string
  text: string
  feedback: string | number
}

const updatedFeedback = ({ feedback, ...data }: UpdatedFeedbackParams) =>
  putRestRequest({
    url: api.REST_API_URL + `/feedbacks/${feedback}`,
    data: {
      data,
    },
    transformer: (response: FeedbacksResponse): boolean => {
      return !!response?.status
    },
  })

export default updatedFeedback
