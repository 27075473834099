import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  overflow: hidden;

  .react-player__shadow {
    background: initial !important;
  }

  .react-player__play-icon {
    display: none;
  }
`
