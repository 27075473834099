import { AxiosRequestConfig, AxiosResponse } from 'axios'

export type RestRequest<
  TData = any,
  Transformer = (response: TData) => any
> = AxiosRequestConfig<TData> & {
  name?: string
  transformer: Transformer
}

export type RestApiTransformer<Transformer> =
  RestRequest<Transformer>['transformer']

export type RestApiResponse<Service extends RestRequest = any> =
  AxiosResponse & {
    data?: ReturnType<Service['transformer']>
    errors?: any
  }

export const getRestRequest = <
  TData = any,
  Transformer = (response: TData) => any
>(
  request: RestRequest<TData, Transformer>
) => ({
  ...request,
  name: request.name ?? '',
})

export const postRestRequest = <
  TData = any,
  Transformer = (response: TData) => any
>(
  request: RestRequest<TData, Transformer>
) => ({
  ...request,
  method: 'post',
  name: request.name ?? '',
})

export const putRestRequest = <
  TData = any,
  Transformer = (response: TData) => any
>(
  request: RestRequest<TData, Transformer>
) => ({
  ...request,
  method: 'put',
  name: request.name ?? '',
})

export const deleteRestRequest = <
  TData = any,
  Transformer = (response: TData) => any
>(
  request: RestRequest<TData, Transformer>
) => ({
  ...request,
  method: 'delete',
  name: request.name ?? '',
})

export const dataToFormData = <TData = any>(data: TData) => {
  const formData = new FormData()

  for (const key in data) {
    const item = data[key as keyof TData]

    if (Array.isArray(item)) {
      for (const element of item) {
        formData.append(key, element)
      }
    } else {
      formData.append(key, item as any)
    }
  }

  return formData
}
