import { api } from '../../../configuration'
import { dataToFormData, postRestRequest } from '../../../helpers/RestHelpers'
import { SimpleResult } from '../../types/PaginatedResult'

type SendProductFormEmailResponse = SimpleResult<boolean>

export type SendProductFormParams = {
  title: string
  type: string
  description: string
}

const SendProductFormEmail = (params: SendProductFormParams) =>
  postRestRequest({
    url: `${api.REST_API_URL}/mail/sendFormProduct`,
    data: dataToFormData(params),
    transformer: (response: SendProductFormEmailResponse) => {
      return !!response.data
    },
  })

export default SendProductFormEmail
