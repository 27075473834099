import { all, put, takeLeading } from 'redux-saga/effects'

import ApiSagas from '../api/sagas'
import { actions } from '../index'
import getOffersWithTotal from '../../rest/requests/offers/getOffersWithTotal'

export default class OffersSagas {
  static *onGetOffersRequest({
    payload,
  }: ReturnType<typeof actions.offers.getOffersRequest>) {
    const response = yield* ApiSagas.rest(getOffersWithTotal(payload))

    if (response.data) {
      yield put(actions.offers.getOffersSuccess(response.data))
    } else {
      yield put(actions.offers.getOffersError(response.errors))
    }
  }

  static *loop() {
    yield all([
      takeLeading(actions.offers.getOffersRequest, this.onGetOffersRequest),
    ])
  }
}
