import styled from 'styled-components'

import Icon from '../../components/Icon'

export const Messages = styled.div<{ $isError: boolean }>`
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
  align-items: flex-start;
  margin-top: 2rem;
`
export const Message = styled.p`
  ${({ theme }) => theme.textStyles.firaSansTextBase}
  color: ${({ theme }) => theme.colors.red};
`
export const StyledIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 1rem;
  color: var(--color-icon);
  transform: translateY(-50%);
`
