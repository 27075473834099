import { UserProfile } from '../types/UserProfile'
import { SUserProfile } from '../types/api/UserProfile'

export const userProfileTransformer = (data: SUserProfile): UserProfile => {
  return {
    default: false,
    id: data.id,
    ...data.attributes,
  }
}
