import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import { actions, selectors } from '..'
import { adsFormValuesTransformer } from '../classifieds/transformers'
import { Classified } from '../../rest/types/Classified'
import { FileToImage } from '../../relay/Transformers/ImageTransformer'
import { MediaType } from '../../form/fields/FormFieldUploadMedia'
import { Image } from '../../rest/types/Image'
import { isEmptyFile } from '../../helpers/FileHelpers'
import summarizeDescription from '../../rest/requests/classifieds/summarizeDescription'
import ApiSagas from '../api/sagas'

export default class PreviewSaga {
  static *onSetPreviewRequest({
    payload,
  }: ReturnType<typeof actions.preview.setPreviewRequest>) {
    const user: ReturnType<typeof selectors.auth.user> = yield select(
      selectors.auth.user
    )

    const selectedAddress: ReturnType<
      typeof selectors.classifieds.selectedAddress
    > = yield select(selectors.classifieds.selectedAddress)

    const categories: ReturnType<typeof selectors.classifieds.categories> =
      yield select(selectors.classifieds.categories)

    const medias: ReturnType<typeof selectors.classifieds.medias> =
      yield select(selectors.classifieds.medias)

    if (user && Array.isArray(categories)) {
      const values: ReturnType<typeof adsFormValuesTransformer> = yield call(
        adsFormValuesTransformer,
        payload.formValues,
        categories,
        user,
        selectedAddress
      )

      const category = categories.find(
        (c) => c.id === values.data.classifiedCategory
      )

      let image: Image | null = null

      if (
        payload.formValues.mediaBlock?.media.type === MediaType.Photo &&
        payload.formValues.mediaBlock?.media.photo &&
        !isEmptyFile(payload.formValues.mediaBlock?.media.photo)
      ) {
        image = yield FileToImage(payload.formValues.mediaBlock?.media.photo)
      }

      let summarizeDescriptionResponse

      if (!values.data.summary) {
        yield put(actions.preview.setIsSummaryByIa(true))
        summarizeDescriptionResponse = yield* ApiSagas.rest(
          summarizeDescription({ description: values?.data?.description })
        )
      }

      const classified: Classified = {
        ...payload.classified,
        ...(values.data as any),
        id: payload.classified.id,
        classifiedCategory: category,
        ...(summarizeDescriptionResponse && {
          summary: summarizeDescriptionResponse.data.data,
        }),
        user: user,
        medias,
        ...(image && {
          image: { data: { attributes: image } },
        }),
      }

      yield put(actions.preview.setId({ id: payload.classified.id }))
      yield put(actions.preview.setClassified({ classified }))
      yield put(
        actions.preview.setCurrentValuesClassifiedForm(payload.formValues)
      )
      yield put(actions.preview.setPreviewSuccess(true))
    } else {
      yield put(actions.preview.setPreviewError(true))
    }
  }

  static *loop() {
    yield all([
      takeEvery(actions.preview.setPreviewRequest, this.onSetPreviewRequest),
    ])
  }
}
