import qs from 'qs'

import { deleteRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'
import { SimpleResult } from '../../types/PaginatedResult'
import { ClassifiedAnswerTransformer } from '../../transformers/classifiedTransformer'
import { SClassifiedAnswer } from '../../types/api/Classified'

export type DeleteAnswerResponse = SimpleResult<SClassifiedAnswer>

export type DeleteAnswerParams = {
  id: string
}

const deleteAnswer = ({ id }: DeleteAnswerParams) => {
  return deleteRestRequest({
    url: `${api.REST_API_URL}/classified-answers/${id}?${qs.stringify({
      encodeValuesOnly: true,
    })}`,
    transformer: (response: DeleteAnswerResponse) => {
      return ClassifiedAnswerTransformer(response.data)
    },
  })
}
export default deleteAnswer
