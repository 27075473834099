import React, { FC } from 'react'

import * as SC from './styled'

export type FormMessage = {
  label?: string
}

export type FormMessagesProps = {
  className?: string
  isError?: boolean
  messages?: FormMessage[] | null
}

const FormMessages: FC<FormMessagesProps> = ({
  className,
  isError = true,
  messages,
}) => {
  return messages && messages.length > 0 ? (
    <SC.Messages className={className} $isError={isError}>
      {messages?.map((message, i) => (
        <SC.Message key={i}>{message.label}</SC.Message>
      ))}
    </SC.Messages>
  ) : null
}

export default FormMessages
