import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../store'
import { getCustomService } from '../../helpers/ReduxHelpers'
import { Upload } from '../../rest/types/Upload'
import { UploadParams } from '../../rest/requests/media/upload'

//
// requests
//

export const alertService = getCustomService<'alert', Upload, UploadParams>(
  'alert'
)

export const getAlertSchemaService = getCustomService<'getAlertSchema', any>(
  'getAlertSchema'
)

//
// Initial state
//

type AlertState = {
  alert: typeof alertService.state
  getAlertSchema: typeof getAlertSchemaService.state
}

const initialState: AlertState = {
  alert: alertService.state,
  getAlertSchema: getAlertSchemaService.state,
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    ...alertService.reducers,
    ...getAlertSchemaService.reducers,
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[slice.name]
const alert = (state: RootState) => root(state).alert
const getAlertSchema = (state: RootState) => root(state).getAlertSchema

export const selectors = {
  alert,
  getAlertSchema,
}
