import { useState } from 'react'

export const useLoadMore = (start: number, jump: number) => {
  const [limit, setLimit] = useState<number>(start)

  const increase = () => {
    setLimit((l) => l + jump)
  }

  return { limit, increase }
}
