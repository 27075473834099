/**
 * @credits Alexis Bouhet - https://zouloux.com
 * First letter capital on given string.
 *
 * For ex: "courgette? Oui!" become "Courgette, Oui!"
 */
export function upperCaseFirstChar(pSource: string): string {
  return pSource.substr(0, 1).toUpperCase() + pSource.substr(1, pSource.length)
}

/**
 * @credits Alexis Bouhet - https://zouloux.com
 * Convert a dash case formated string to a camel case format.
 *
 * Ex: "my-string" will be converted to "myString"
 */
export function dashToCamelCase(pSource: string, pSeparator = '-'): string {
  // Seperate dashs
  const splitted = pSource.toLowerCase().split(pSeparator)
  const total = splitted.length

  // Return raw if it's not a dash
  if (total < 2) return pSource.toLowerCase()

  // The first is not uppercase
  let out = splitted[0]

  // Others are upper cased first
  for (let i = 1; i < total; i++) {
    out += i == 0 ? splitted[i] : upperCaseFirstChar(splitted[i])
  }

  return out
}

export function stringBeforeCharacter(pSource: string, pCharacter: string) {
  return pSource.substring(0, pSource.indexOf(`${pCharacter}`))
}

export function stringAfterCharacter(pSource: string, pCharacter: string) {
  return pSource.slice(pSource.indexOf(`${pCharacter}`) + 1)
}

export function getCharacters(pSource: string, start: number, end: number) {
  return pSource.slice(start, end)
}
