import styled, { css } from 'styled-components'

import FormLabel from '../../FormLabel'
import {
  FormFieldOrientation,
  FormFieldOrientationValues,
} from '../../../types/form'

export enum InputSize {
  Small = 'Small',
  Big = 'Big',
}

type InputStyleProps = {
  $isError?: boolean
  $isActive?: boolean
  $hasLabel?: boolean
  $isDisabled?: boolean
  $hasErrorMessage?: boolean
  $size?: InputSize
  $orientation?: FormFieldOrientationValues
  $type?: any
}

const HintMessage = styled.p`
  ${({ theme }) => theme.textStyles.firaSansTextBase}
  margin-top: 0.5rem;
  padding-inline: 0.8rem;
  text-align: left;
`

export const InnerHelpMessage = styled(HintMessage)`
  ${({ theme }) => theme.textStyles.montserratSm};
  color: ${({ theme }) => theme.colors.blueSecondaryBis};
`

export const HelpMessage = styled(HintMessage)`
  color: ${({ theme }) => theme.colors.bismark};
`
export const ErrorMessage = styled(HintMessage)`
  color: ${({ theme }) => theme.colors.error};
`

export const Container = styled.div<InputStyleProps>`
  display: flex;
  flex-flow: column;

  // disabled
  ${({ $isDisabled }) =>
    $isDisabled === true &&
    css`
      opacity: 0.8;
      &,
      & * {
        cursor: not-allowed;
      }
    `}

  ${({ $orientation }) =>
    $orientation &&
    $orientation === FormFieldOrientation.Horizontal &&
    css`
      flex-direction: row;
      gap: 2rem;
      align-items: center;

      ${HelpMessage} {
        flex-basis: 100%;
      }
    `};
`

export const InputContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  position: relative;
  padding: 0 1.7rem;

  > svg {
    margin-left: -0.5rem;
  }
`

export const MainWrapper = styled.div`
  width: 100%;
`

export const Wrapper = styled.div<InputStyleProps>`
  border: 1px solid ${({ theme }) => theme.colors.greyContour};
  background-color: ${({ theme }) => theme.colors.pureWhite};
  border-radius: 0.6rem;
  position: relative;
  width: 100%;
  transition: all 0.3s ease;

  ${({ $size }) =>
    $size &&
    $size === InputSize.Small &&
    css`
      height: 4.1rem;
    `};

  ${({ $size }) =>
    $size === InputSize.Big &&
    css`
      height: 5.1rem;
    `};

  ${({ $isError, theme }) =>
    $isError === true &&
    css`
      border-color: ${theme.colors.error};
    `}

  ${({ $isActive }) =>
    $isActive === true &&
    css`
      border-color: ${({ theme }) => theme.colors.bluePrimary};
      background-color: ${({ theme }) => theme.colors.blueLight};
    `}

  ${({ $isDisabled }) =>
    $isDisabled === true &&
    css`
      border-color: ${({ theme }) => theme.colors.greyContour};
      background-color: ${({ theme }) => theme.colors.pureWhite};
    `}

  ${({ theme, $type, $isActive, placeholder }) =>
    $type === 'date' &&
    css`
      ${InputContainer} {
        &::after {
          content: ${`'${placeholder}'`};
          ${({ theme }) => theme.textStyles.montserratSm};
          color: ${({ theme }) => theme.colors.greyFields};
          position: absolute;
          left: 6rem;
          pointer-events: none;
          display: ${$isActive ? 'none' : 'block'};
        }

        & input {
          color: ${$isActive ? theme.colors.greyFields : 'transparent'};
        }
      }
    `};
`

export const Label = styled(FormLabel)<{
  $required?: boolean
  $active?: boolean
}>`
  transition: all 0.3s ease;

  ${({ $active }) =>
    $active === true &&
    css`
      color: ${({ theme }) => theme.colors.bluePrimary};
    `}
`

export const inputStyle = css<InputStyleProps>`
  ${({ theme }) => theme.textStyles.montserratSm};
  line-height: 1;
  width: 100%;
  height: 100%;
  border: 0;
  color: ${({ theme }) => theme.colors.dustyGray};
  background: transparent;
  transition: all 0.3s ease;

  ${({ $isActive }) =>
    $isActive === true &&
    css`
      color: ${({ theme }) => theme.colors.bluePrimary};
    `}

  // disabled
  ${({ $isDisabled }) =>
    $isDisabled === true &&
    css`
      pointer-events: none;
      color: ${({ theme }) => theme.colors.greyContour};
    `}

  &::placeholder {
    color: ${({ theme }) => theme.colors.dustyGray};
    ${({ theme }) => theme.textStyles.montserratSm};
  }

  // readonly
  &[readonly] {
    cursor: default;
  }

  &:focus {
    outline: none;
  }

  &[type='password']&:not(:placeholder) {
    letter-spacing: 3px;
  }
`

export const Input = styled.input<InputStyleProps>`
  ${inputStyle};
`
