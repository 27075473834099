import qs from 'qs'

import { getRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'
import { Feedbacks } from '../../types/Feedback'
import { User } from '../../types/User'

type FeedbacksResponse = {
  data: {
    id: string
    rating: string
    text: string
    date: string
    user: {
      id: string
      firstName: string
      lastName: string
    }
  }[]
  total: number
}

export type FeedbacksParams = {
  id: string
  limit: number
  offset: number
}

const feedbacks = ({ id, limit, offset }: FeedbacksParams) =>
  getRestRequest({
    url:
      api.REST_API_URL +
      `/feedbacks/offer/${id}?${qs.stringify({
        limit,
        offset,
      })}`,
    transformer: (data: FeedbacksResponse): Feedbacks => {
      return {
        data: data?.data?.map((d) => ({
          ...d,
          user: d?.user as User,
        })),
        total: data?.total,
      }
    },
  })

export default feedbacks
