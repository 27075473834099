import * as api from './api/redux'
import * as app from './app/redux'
import * as auth from './auth/redux'
import * as example from './example/redux'
import * as feedbacks from './feedbacks/redux'
import * as upload from './upload/redux'
import * as reservations from './reservations/redux'
import * as alert from './alert/redux'
import * as offers from './offers/redux'
import * as filters from './filters/redux'
import * as mail from './mail/redux'
import * as stripe from './stripe/redux'
import * as classifieds from './classifieds/redux'
import * as modals from './modals/redux'
import * as user from './user/redux'
import * as geo from './geo/redux'
import * as answer from './answer/redux'
import * as preview from './preview/redux'
import * as chat from './chat/redux'
import * as redirections from './redirections/redux'

export const actions = {
  app: app.actions,
  api: api.actions,
  auth: auth.actions,
  example: example.actions,
  feedbacks: feedbacks.actions,
  upload: upload.actions,
  reservations: reservations.actions,
  alert: alert.actions,
  offers: offers.actions,
  filters: filters.actions,
  mail: mail.actions,
  stripe: stripe.actions,
  classifieds: classifieds.actions,
  modals: modals.actions,
  user: user.actions,
  geo: geo.actions,
  answer: answer.actions,
  preview: preview.actions,
  chat: chat.actions,
  redirections: redirections.actions,
}

export const selectors = {
  app: app.selectors,
  api: api.selectors,
  auth: auth.selectors,
  example: example.selectors,
  feedbacks: feedbacks.selectors,
  upload: upload.selectors,
  reservations: reservations.selectors,
  alert: alert.selectors,
  offers: offers.selectors,
  filters: filters.selectors,
  mail: mail.selectors,
  stripe: stripe.selectors,
  classifieds: classifieds.selectors,
  modals: modals.selectors,
  user: user.selectors,
  geo: geo.selectors,
  answer: answer.selectors,
  preview: preview.selectors,
  chat: chat.selectors,
  redirections: redirections.selectors,
}

export const reducers = {
  app: app.reducer,
  api: api.reducer,
  auth: auth.reducer,
  example: example.reducer,
  feedbacks: feedbacks.reducer,
  upload: upload.reducer,
  reservations: reservations.reducer,
  alert: alert.reducer,
  offers: offers.reducer,
  filters: filters.reducer,
  mail: mail.reducer,
  stripe: stripe.reducer,
  classifieds: classifieds.reducer,
  modals: modals.reducer,
  user: user.reducer,
  geo: geo.reducer,
  answer: answer.reducer,
  preview: preview.reducer,
  chat: chat.reducer,
  redirections: redirections.reducer,
}
