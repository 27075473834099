import qs from 'qs'

import { getRestRequest } from '../../../helpers/RestHelpers'
import { GouvGeoAddress } from '../../types/api/GeoAddress'
import { geoAddressTransformer } from '../../transformers/geoAddressTransformer'

type GetAddressesResponse = {
  features: Array<GouvGeoAddress>
}

export type GetAddressesParams = {
  q: string
  type?: string
}

const getAddresses = (params: GetAddressesParams) =>
  getRestRequest({
    url: `https://api-adresse.data.gouv.fr/search?${qs.stringify(params, {
      encodeValuesOnly: true,
    })}`,
    transformer: (response: GetAddressesResponse) => {
      return response.features.map((address) => geoAddressTransformer(address))
    },
  })

export default getAddresses
