import styled, { css } from 'styled-components'
import chroma from 'chroma-js'

import { mq, sizes } from '../../theme'
import Link from '../Link'

export const Container = styled.div<{ isOpen?: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  z-index: ${({ theme }) => theme.zIndexes.modal};

  ${({ isOpen }) =>
    isOpen
      ? css`
          display: flex;
          opacity: 1;
          pointer-events: all;

          ${Overlay} {
            opacity: 1;
            pointer-events: all;
          }
        `
      : css`
          display: none;
          opacity: 0;
          pointer-events: none;

          ${Overlay} {
            opacity: 0;
            pointer-events: none;
          }
        `}
`

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${({ theme }) => chroma(theme.colors.pureBlack).alpha(0.2).css()};
`

export const Wrapper = styled.div`
  position: relative;
  z-index: 2;

  @media ${mq(sizes.tablet)} {
    margin: auto;
    padding: 2rem;
  }
`

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  z-index: 2;
  width: 100%;
  padding: 1.6rem 3.2rem;
  margin: auto;
  position: relative;
  min-height: 100%;
  border: 1px solid ${({ theme }) => theme.colors.greyContour};

  @media ${mq(sizes.tablet)} {
    border-radius: 1.5rem;
    box-shadow: 5px 12px 38px 0px #a7b1c033;
    min-height: auto;
    border-radius: 2rem;
    padding: 2.7rem 4.6rem;
    max-width: 110rem;
    height: auto;
  }
`

export const Heading = styled.div<{ $dashedBorder?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 2.6rem;

  ${({ $dashedBorder }) =>
    $dashedBorder &&
    css`
      border-bottom: 3px dashed ${({ theme }) => theme.colors.greyContour};
    `};
`
export const Title = styled.h2`
  ${({ theme }) => theme.textStyles.firaSans3XlBold}
  padding: 0;
  margin: 0 auto;
  width: 100%;
  text-align: left;
  flex-grow: 1;
  color: ${({ theme }) => theme.colors.bluePrimary};

  @media ${mq(sizes.tablet)} {
  }
`

export const CloseButton = styled(Link)`
  margin-left: auto;
  width: 2.4rem;
  max-height: 3.5rem;
  color: ${({ theme }) => theme.colors.greyContour};
  @media ${mq(sizes.desktop)} {
    max-height: 4rem;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`
