import { getRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'
import { User } from '../../types/User'

export type LoginResponse = {
  jwt: string
  user: User
}

export type LoginParams = {
  id_token: string
  access_token: string
  refresh_token: string
}

const login = ({ id_token, access_token, refresh_token }: LoginParams) =>
  getRestRequest({
    url:
      api.REST_API_URL +
      `/auth/${api.AUTH_PROVIDER}/callback` +
      `?id_token=${id_token}` +
      `&access_token=${access_token}` +
      `&refresh_token=${refresh_token}`,
    transformer: (data: LoginResponse): LoginResponse => {
      return data
    },
  })

export default login
