import { FC, useEffect, useState } from 'react'
import { FormikContextType, useFormikContext } from 'formik'

export type FormikContextObserverProps<Values = any> = {
  onUpdate?: (context: FormikContextType<Values>) => void
  onUpdateWhenFirstTouched?: (context: FormikContextType<Values>) => void
}

const FormikContextObserver: FC<FormikContextObserverProps> = ({
  onUpdate,
  onUpdateWhenFirstTouched,
}) => {
  const context = useFormikContext()
  const [isReady, setIsReady] = useState<boolean>(false)

  useEffect(() => {
    if ((context.dirty, context.values)) {
      setIsReady(true)
    }
  }, [context.dirty, context.values])

  useEffect(() => {
    onUpdate?.(context)
  }, [context, onUpdate])

  useEffect(() => {
    if (isReady) {
      onUpdateWhenFirstTouched?.(context)
      setIsReady(false)
    }
  }, [context, onUpdateWhenFirstTouched, isReady])

  return null
}

export default FormikContextObserver
