import { AdsReplyFormValues } from '../../forms/AdsReplyForm'

export const adsReplyFormTransformer = ({
  values,
}: {
  values: AdsReplyFormValues
}) => {
  return {
    data: {
      ...values,
      civility: values.civility,
      name: values.name,
      lastname: values.surname,
      email: values.email,
      phone: values.phone,
      message: values.message,
      ...(values.university && {
        university: values.university,
      }),
      ...(values.studyLevel && {
        schoolingLevel: values.studyLevel,
      }),
      ...(values.dateOfBirth && {
        birthdate: values.dateOfBirth,
      }),
      ...(values?.address?.street && {
        street: values.address.street,
      }),
      ...(values?.address?.city && {
        city: values.address.city,
      }),
      ...(values?.address?.postalCode && {
        postcode: values.address.postalCode,
      }),
      ...(values?.address?.country && {
        country: values.address.country,
      }),
      ...(values?.sessionDuration && {
        jobDuration: values.sessionDuration,
      }),
      ...(values?.sessionDurationCarabin && {
        jobDurationCarabin: values.sessionDurationCarabin,
      }),
      ...(values.availability?.firstDate && {
        firstDate: values.availability?.firstDate,
      }),
      ...(values.availability?.firstDate && {
        secondDate: values.availability?.secondDate,
      }),
      ...(values.availability?.thirdDate && {
        thirdDate: values.availability?.thirdDate,
      }),
      ...(values.availability?.fourthDate && {
        fourthDate: values.availability?.fourthDate,
      }),
    },
    files: [
      ...(values.file ? [{ name: 'files.document', value: values.file }] : []),
      ...(values.uploadStudentCard
        ? [{ name: 'files.studentCard', value: values.uploadStudentCard }]
        : []),
    ],
  }
}
