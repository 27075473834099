import { FC } from 'react'
import { ImageProps } from 'next/image'
import { useTheme } from 'styled-components'

import { FormLabelProps } from '../../FormLabel'
import { Icons } from '../../../components/Icon'

import * as SC from './styled'

export type FormFieldPreviewProps = {
  className?: string
  label?: string
  name?: string
  labelProps: FormLabelProps
  value?: File | null
  preview?: ImageProps
  onDelete: () => void
}

const FormFieldPreview: FC<FormFieldPreviewProps> = ({
  className,
  label,
  labelProps,
  preview,
  onDelete,
}) => {
  const theme = useTheme()
  return (
    <SC.Container className={className}>
      <SC.Wrapper>
        {label && <SC.Label {...labelProps}>{label}</SC.Label>}
        <SC.Block>
          {preview && (
            <SC.ImageWrapper>
              <SC.Preview {...preview} />
            </SC.ImageWrapper>
          )}
          {onDelete && (
            <SC.IconWrapper>
              <SC.TrashIcon
                icon={Icons.delete}
                width={18}
                height={18}
                color={theme.colors.bluePrimary}
                onClick={onDelete}
              />
            </SC.IconWrapper>
          )}
        </SC.Block>
      </SC.Wrapper>
    </SC.Container>
  )
}

export default FormFieldPreview
