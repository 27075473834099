import { useSelector, useDispatch } from 'react-redux'
import { useCallback } from 'react'

import { actions, selectors } from '../../redux'

const useUser = () => {
  const dispatch = useDispatch()
  const user = useSelector(selectors.auth.user)
  const isConnected = useSelector(selectors.auth.isConnected)
  const isLoading = useSelector(selectors.auth.meIsLoading)

  const refresh = useCallback(() => {
    if (!isLoading) {
      dispatch(actions.auth.me())
    }
  }, [dispatch, isLoading])

  return { user, isConnected, isLoading, refresh }
}

export default useUser
