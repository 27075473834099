import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import AccountValidateEmailModal, {
  AccountValidateEmailModalProps,
} from 'src/components/AccountValidateEmailModal'
import { useSagaTakeEvery } from 'src/hooks/useSaga'
import { useServiceRequest } from 'src/hooks/useServiceRequest'
import { actions, selectors } from 'src/redux'
import useUser from 'src/relay/User/useUser'
import * as Yup from 'yup'

const ValidateEmailModal: FC = () => {
  // Libaries
  const dispatch = useDispatch()
  const { t } = useTranslation()

  // Selectors
  const { user } = useUser()
  const isOpen = useSelector(selectors.modals.validateUserEmailModalIsOpen)

  // Request
  const [response, update] = useServiceRequest(
    selectors.auth.updateProfile,
    actions.auth.updateProfileRequest,
    actions.auth.updateProfileReset
  )

  // Methods
  const closeModal = useCallback(() => {
    window.setTimeout(() => {
      dispatch(actions.modals.setValidateUserModalIsOpen(false))
    }, 3000)
  }, [dispatch])

  const handleProfileUpdated = useCallback(() => {
    closeModal()
  }, [closeModal])

  useSagaTakeEvery(
    actions.auth.updateProfileSuccess,
    useCallback(handleProfileUpdated, [handleProfileUpdated])
  )

  const props: AccountValidateEmailModalProps = {
    isOpen,
    onClose: () => {
      dispatch(actions.modals.setValidateUserModalIsOpen(false))
    },
    title: t('validate_email_modal_title'),
    form: {
      text: t('validate_email_modal_text'),
      validationSchema: Yup.object().shape({
        email: Yup.string().required(t('yup_required')).email(t('yup_email')),
      }),
      initialValues: {
        email: '',
      },
      fields: {
        email: {
          placeholder: t('validate_email_modal_email_placeholder'),
          label: t('validate_email_modal_email_label'),
          innerError: true,
          required: true,
        },
      },

      submitButton: {
        text: t('validate_email_modal_form_submit'),
        isLoading: response.pending,
      },
      onSubmit: (values) => {
        update({
          id: user?.id,
          email: values.email,
        })
      },
      ...(response.success && {
        success: t('validate_email_modal_form_success'),
      }),
      ...(response.errors && {
        errors: [{ label: t('validate_email_modal_form_error') }],
      }),
    },
  }

  return <AccountValidateEmailModal {...props} />
}

export default ValidateEmailModal
