import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../store'
import {
  getCustomLoadMoreService,
  getCustomService,
} from '../../helpers/ReduxHelpers'
import { Feedback, FeedbacksResult } from '../../rest/types/Feedback'
import { FeedbacksParams } from '../../rest/requests/feedbacks/feedbacks'
import { AddFeedbackParams } from '../../rest/requests/feedbacks/addFeedback'
import { UpdatedFeedbackParams } from '../../rest/requests/feedbacks/updateFeedback'
import { DeleteFeedbackParams } from '../../rest/requests/feedbacks/deleteFeedback'

//
// requests
//

export const loadService = getCustomLoadMoreService<
  'load',
  FeedbacksResult,
  FeedbacksParams
>('load')

export const addFeedbackService = getCustomService<
  'addFeedback',
  boolean,
  AddFeedbackParams
>('addFeedback')

export const updatedFeedbackService = getCustomService<
  'updatedFeedback',
  boolean,
  UpdatedFeedbackParams
>('updatedFeedback')

export const deleteFeedbackService = getCustomService<
  'deleteFeedback',
  boolean,
  DeleteFeedbackParams
>('deleteFeedback')

//
// Initial state
//

export type FeedbacksState = {
  currentUpdatedFeedback: Feedback | null
  // requests
  load: typeof loadService.state
  addFeedback: typeof addFeedbackService.state
  updatedFeedback: typeof updatedFeedbackService.state
  deleteFeedback: typeof deleteFeedbackService.state
}

const initialState: FeedbacksState = {
  currentUpdatedFeedback: null,
  // requests
  load: loadService.state,
  addFeedback: addFeedbackService.state,
  updatedFeedback: updatedFeedbackService.state,
  deleteFeedback: deleteFeedbackService.state,
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: 'feedbacks',
  initialState,
  reducers: {
    setCurrentUpdatedFeedback: (state, action) => {
      state.currentUpdatedFeedback = action.payload
    },
    // requests
    ...loadService.reducers,
    ...addFeedbackService.reducers,
    ...updatedFeedbackService.reducers,
    ...deleteFeedbackService.reducers,
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[slice.name]
const load = (state: RootState) => root(state).load
const addFeedback = (state: RootState) => root(state).addFeedback
const updatedFeedback = (state: RootState) => root(state).updatedFeedback
const deleteFeedback = (state: RootState) => root(state).deleteFeedback
const currentUpdatedFeedback = (state: RootState) =>
  root(state).currentUpdatedFeedback

export const selectors = {
  load,
  addFeedback,
  updatedFeedback,
  currentUpdatedFeedback,
  deleteFeedback,
}
