import { FC, ReactNode } from 'react'

import { useNoOverflow } from '../../hooks'
import Icon, { Icons } from '../Icon'

import * as SC from './styled'

export type ModalProps = {
  className?: string
  title?: string
  isOpen?: boolean
  disableDashedBorder?: boolean
  children?: ReactNode
  onClose?: () => void
}

const Modal: FC<ModalProps> = ({
  className,
  title,
  isOpen,
  children,
  disableDashedBorder = false,
  onClose,
}) => {
  useNoOverflow(isOpen ?? false)

  return (
    <>
      <SC.Container className={className} isOpen={isOpen}>
        <SC.Wrapper>
          <SC.Inner>
            <SC.Heading $dashedBorder={!disableDashedBorder}>
              <SC.Title>{title}</SC.Title>
              <SC.CloseButton onClick={onClose}>
                <Icon icon={Icons.close} width={24} height={24} />
              </SC.CloseButton>
            </SC.Heading>
            <SC.Content>{children}</SC.Content>
          </SC.Inner>
        </SC.Wrapper>
        <SC.Overlay onClick={onClose} />
      </SC.Container>
    </>
  )
}

export default Modal
