import styled from 'styled-components'
import AccountValidateEmailForm from 'src/forms/AccountValidateEmailForm'

import Modal from '../Modal'
import { Wrapper } from '../Modal/styled'

export const ModalStyled = styled(Modal)`
  ${Wrapper} {
    max-width: 70rem;
    width: 100%;
  }
`
export const Form = styled(AccountValidateEmailForm)`
  margin-top: 2.4rem;
`
