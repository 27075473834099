import { css } from 'styled-components'

import fonts from './fonts'

const textStyles = {
  vujahdaySmall: css`
    ${fonts.VujahdayScript}
    font-size: 1.4rem;
    line-height: 1.5;
  `,
  vujahdayTextBase: css`
    ${fonts.VujahdayScript}
    font-size: 1.6rem;
    line-height: 1.5;
  `,
  vujahdayMedium: css`
    ${fonts.VujahdayScript}
    font-size: 2.2rem;
    line-height: 1.5;
  `,
  vujahdayLarge: css`
    ${fonts.VujahdayScript}
    font-size: 2.5rem;
    line-height: 1.5;
  `,
  vujahdayXLarge: css`
    ${fonts.VujahdayScript}
    font-size: 4.5rem;
    line-height: 1.5;
  `,
  vujahdayXXLarge: css`
    ${fonts.VujahdayScript}
    font-size: 6rem;
    line-height: 1.5;
  `,

  // Fira sans
  firaSansXs: css`
    ${fonts.FiraSansRegular}
    font-size: 1.2rem;
    line-height: 1.5;
  `,
  firaSansSmMedium: css`
    ${fonts.FiraSansSemiBold}
    font-size: 1.4rem;
    line-height: 1.5;
  `,
  firaSansTextBase: css`
    ${fonts.FiraSansRegular}
    font-size: 1.6rem;
    line-height: 1.5;
  `,
  firaSansTextBaseSemiBold: css`
    ${fonts.FiraSansSemiBold}
    font-size: 1.6rem;
    line-height: 1.5;
  `,
  firaSansTextBaseBold: css`
    ${fonts.FiraSansBold}
    font-size: 1.6rem;
    line-height: 1.5;
  `,
  firaSansLg: css`
    ${fonts.FiraSansRegular}
    font-size: 1.8rem;
    line-height: 1.5;
  `,
  firaSansLgBold: css`
    ${fonts.FiraSansBold}
    font-size: 1.8rem;
    line-height: 1.5;
  `,
  firaSansXl: css`
    ${fonts.FiraSansRegular}
    font-size: 2rem;
    line-height: 1.5;
  `,
  firaSansXlBold: css`
    ${fonts.FiraSansBold}
    font-size: 2rem;
    line-height: 1.5;
  `,
  firaSans2Xl: css`
    ${fonts.FiraSansRegular}
    font-size: 2.4rem;
    line-height: 1.5;
  `,
  firaSans2XlBold: css`
    ${fonts.FiraSansBold}
    font-size: 2.4rem;
    line-height: 1.5;
  `,
  firaSans3Xl: css`
    ${fonts.FiraSansRegular}
    font-size: 3rem;
    line-height: 1.5;
  `,
  firaSans3XlBold: css`
    ${fonts.FiraSansBold}
    font-size: 3rem;
    line-height: 1.5;
  `,
  firaSans4XlBold: css`
    ${fonts.FiraSansBold}
    font-size: 3.6rem;
    line-height: 1.5;
  `,
  firaSans4Xl: css`
    ${fonts.FiraSansRegular}
    font-size: 3.6rem;
    line-height: 1.5;
  `,
  firaSans5Xl: css`
    ${fonts.FiraSansRegular}
    font-size: 3.6rem;
    line-height: 1.5;
  `,
  firaSans5XlBold: css`
    ${fonts.FiraSansBold}
    font-size: 4.8rem;
    line-height: 1.5;
  `,
  firaSans6Xl: css`
    ${fonts.FiraSansRegular}
    font-size: 6.4rem;
    line-height: 1.5;
  `,

  // Montserrat
  montserratXs: css`
    ${fonts.MontserratRegular}
    font-size: 1.2rem;
    line-height: 1.5;
  `,
  montserratTextBase: css`
    ${fonts.MontserratRegular}
    font-size: 1.6rem;
    line-height: 1.5;
  `,
  montserratSm: css`
    ${fonts.MontserratRegular}
    font-size: 1.4rem;
    line-height: 1.5;
  `,
  montserratLg: css`
    ${fonts.MontserratRegular}
    font-size: 1.7rem;
    line-height: 1.5;
  `,
  montserratXl: css`
    ${fonts.MontserratRegular}
    font-size: 1.8rem;
    line-height: 1.5;
  `,
  montserrat2Xl: css`
    ${fonts.MontserratBold}
    font-size: 2.4rem;
    line-height: 1.5;
  `,
  montserrat3Xl: css`
    ${fonts.MontserratRegular}
    font-size: 3rem;
    line-height: 1.5;
  `,
  montserrat4Xl: css`
    ${fonts.MontserratRegular}
    font-size: 3.6rem;
    line-height: 1.5;
  `,
  montserrat5Xl: css`
    ${fonts.MontserratRegular}
    font-size: 4.8rem;
    line-height: 1.5;
  `,
  montserrat6Xl: css`
    ${fonts.MontserratRegular}
    font-size: 6.4rem;
    line-height: 1.5;
  `,
  montserratTextBaseMedium: css`
    ${fonts.MontserratSemiBold}
    font-size: 1.6rem;
    line-height: 1.5;
  `,
  montserratSmMedium: css`
    ${fonts.MontserratSemiBold}
    font-size: 1.4rem;
    line-height: 1.5;
  `,
  montserratXlMedium: css`
    ${fonts.MontserratSemiBold}
    font-size: 2rem;
    line-height: 1.5;
  `,
  montserratLgBold: css`
    ${fonts.MontserratBold}
    font-size: 1.7rem;
    line-height: 1.5;
  `,
  montserratTextBaseBold: css`
    ${fonts.MontserratBold}
    font-size: 1.6rem;
    line-height: 1.5;
  `,
  montserratSmBold: css`
    ${fonts.MontserratBold}
    font-size: 1.4rem;
    line-height: 1.5;
  `,

  bebasXs: css`
    ${fonts.BebasRegular}
    font-size: 1.2rem;
    line-height: 1.5;
  `,
  bebasTextBase: css`
    ${fonts.BebasRegular}
    font-size: 1.6rem;
    line-height: 1.5;
  `,
  bebasSm: css`
    ${fonts.BebasRegular}
    font-size: 1.4rem;
    line-height: 1.5;
  `,
  bebasLg: css`
    ${fonts.BebasRegular}
    font-size: 1.8rem;
    line-height: 1.5;
  `,
  bebasXl: css`
    ${fonts.BebasRegular}
    font-size: 2rem;
    line-height: 1.5;
  `,
  bebas2Xl: css`
    ${fonts.BebasRegular}
    font-size: 2.4rem;
    line-height: 1.5;
  `,
  bebas3Xl: css`
    ${fonts.BebasRegular}
    font-size: 3rem;
    line-height: 1.5;
  `,
  bebas4Xl: css`
    ${fonts.BebasRegular}
    font-size: 3.6rem;
    line-height: 1.5;
  `,
  bebas5Xl: css`
    ${fonts.BebasRegular}
    font-size: 4.8rem;
    line-height: 1.5;
  `,
  bebas6Xl: css`
    ${fonts.BebasRegular}
    font-size: 6.4rem;
    line-height: 1.5;
  `,

  accessibleHiddenText: `
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
  `,
}

export default textStyles
