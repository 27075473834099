import {
  all,
  put,
  SagaReturnType,
  select,
  takeLeading,
} from 'redux-saga/effects'

import ApiSagas from '../api/sagas'
import { actions, selectors } from '../index'
import toggleReservation from '../../rest/requests/reservations/toggleReservation'
import getUserReservations from '../../rest/requests/reservations/getUserReservations'
import getAllUserReservations from '../../rest/requests/reservations/getAllUserReservations'

export default class ReservationsSagas {
  static *onToggleReservationRequest({
    payload,
  }: ReturnType<typeof actions.reservations.toggleReservationRequest>) {
    const isSubscriptionActive: SagaReturnType<
      typeof selectors.auth.isSubscriptionActive
    > = yield select(selectors.auth.isSubscriptionActive)
    if (!isSubscriptionActive) {
      yield put(actions.reservations.toggleReservationReset())
      return
    }
    const response = yield* ApiSagas.rest(toggleReservation(payload))

    if (response.data) {
      const reservations: SagaReturnType<
        typeof selectors.reservations.reservations
      > = yield select(selectors.reservations.reservations)

      if (response.data.status) {
        yield put(
          actions.reservations.setReservations(
            (reservations ?? []).concat([response.data.reservation])
          )
        )
      } else {
        yield put(
          actions.reservations.setReservations(
            (reservations ?? []).filter(
              (r) => r.offer?.id !== response.data.reservation.offer?.id
            )
          )
        )
      }

      yield put(actions.reservations.toggleReservationSuccess(response.data))
    } else {
      yield put(actions.reservations.toggleReservationError(response.errors))
    }
  }

  static *onGetUserReservationsRequest({
    payload,
  }: ReturnType<typeof actions.reservations.getUserReservationsRequest>) {
    const response = yield* ApiSagas.rest(getUserReservations(payload))

    if (response.data) {
      yield put(actions.reservations.getUserReservationsSuccess(response.data))
    } else {
      yield put(actions.reservations.getUserReservationsError(response.errors))
    }
  }
  static *onGetAllUserReservationsRequest() {
    const isSubscriptionActive: SagaReturnType<
      typeof selectors.auth.isSubscriptionActive
    > = yield select(selectors.auth.isSubscriptionActive)
    if (!isSubscriptionActive) {
      yield put(actions.reservations.getAllUserReservationsReset())
      return
    }

    const response = yield* ApiSagas.rest(getAllUserReservations())

    if (!response?.errors && response?.data) {
      yield put(actions.reservations.setReservations(response?.data))
      yield put(
        actions.reservations.getAllUserReservationsSuccess(response.data)
      )
    } else {
      yield put(
        actions.reservations.getAllUserReservationsError(response.errors)
      )
    }
  }

  static *loop() {
    yield all([
      takeLeading(
        actions.reservations.toggleReservationRequest,
        this.onToggleReservationRequest
      ),
      takeLeading(
        actions.reservations.getUserReservationsRequest,
        this.onGetUserReservationsRequest
      ),
      takeLeading(
        actions.reservations.getAllUserReservationsRequest,
        this.onGetAllUserReservationsRequest
      ),
    ])
  }
}
