import { dataToFormData, putRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'
import { User } from '../../types/User'

export type UpdateProfileResponse = User

export type UpdateProfileParams = {
  id: string
  profile: string | number
  firstName?: string
  lastName?: string
  email: string
  civility: string
  speciality: string
}

const updateProfile = ({ id, ...rest }: UpdateProfileParams) =>
  putRestRequest({
    url: api.REST_API_URL + `/users/${id}`,
    data: dataToFormData(rest),
    transformer: (data: UpdateProfileResponse) => {
      return data
    },
  })

export default updateProfile
