import QueryString from 'qs'

import { SimpleResult } from '../../types/PaginatedResult'
import { getRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'
import { classifiedTransformer } from '../../transformers/classifiedTransformer'
import { SClassified } from '../../types/api/Classified'

type ClassifiedPageResponse = SimpleResult<SClassified>

export type GetClassifiedByIdParams = {
  id: string
  filters: { [key: string]: any }
}

const classifiedById = ({ id, filters }: GetClassifiedByIdParams) => {
  return getRestRequest({
    url: `${api.REST_API_URL}/classifieds/${id}?${QueryString.stringify(
      {
        filters,
        populate: {
          block: {
            populate: '*',
          },
          image: {
            populate: '*',
          },
          emitter: {
            populate: {
              classifieds: {
                fields: ['id'],
              },
              logo: {
                populate: '*',
              },
              recipients: {
                populate: '*',
              },
            },
          },
          classifiedCategory: {
            fields: ['form'],
          },
        },
      },
      {
        encodeValuesOnly: true,
      }
    )}`,
    transformer: (response: ClassifiedPageResponse) => {
      return response.data ? classifiedTransformer(response.data) : {}
    },
  })
}

export default classifiedById
