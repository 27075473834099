import { postRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'

export type AddParams = {
  data: { [key: string]: any }
  files: Array<{ name: string; value: File }>
}

const add = ({ data, files }: AddParams) => {
  const formData = new FormData()

  if (files?.length) {
    for (const file of files) {
      formData.append(file.name, file.value)
    }
  }

  formData.append('data', JSON.stringify(data))

  return postRestRequest({
    url: api.REST_API_URL + `/classified-answers`,
    data: formData,
    transformer: (response: any) => {
      console.log('response req', response)
      return response.data.id
    },
  })
}

export default add
