import styled, { css } from 'styled-components'

import FormLabel from '../../FormLabel'

export const Container = styled.div<{ $isDisabled?: boolean }>`
  display: flex;
  flex-flow: column;

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      opacity: 0.4;
      cursor: not-allowed;
    `}
`

export const Label = styled(FormLabel)``

export const Options = styled.div<{ $direction?: 'row' | 'column' }>`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${({ $direction }) => $direction};
  gap: 3rem;
`

export const Text = styled.p`
  ${({ theme }) => theme.textStyles.firaSansTextBase};
  color: ${({ theme }) => theme.colors.blueSecondaryBis};
  margin-bottom: 1.2rem;
`

export const Option = styled.label<{
  $isOptionDisabled?: boolean
  $isInputDisabled?: boolean
}>`
  display: flex;
  align-items: center;
  gap: 1.6rem;
  cursor: pointer;
  ${({ theme }) => theme.textStyles.firaSansTextBase};
  ${({ $isOptionDisabled }) =>
    $isOptionDisabled &&
    css`
      opacity: 0.4;
      cursor: not-allowed;
    `}
  ${({ $isInputDisabled }) =>
    $isInputDisabled &&
    css`
      opacity: 1;
      cursor: not-allowed;
    `}

  & input:checked ~ svg {
    background-color: ${({ theme }) => theme.colors.arapawa};

    & use {
      color: white;
    }
  }

  & input:checked ~ svg use {
    visibility: visible;
  }
`

export const OptionRadio = styled.input`
  appearance: none;
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.pureWhite};
  cursor: pointer;

  &:before {
    content: '';
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border-radius: 1rem;
    opacity: 0;
    scale: 0;
    background-color: ${({ theme }) => theme.colors.greenAccent};
    transition: all 0.3s ease;
  }

  &:checked:before {
    scale: 1;
    opacity: 1;
  }
`

export const Error = styled.p`
  ${({ theme }) => theme.textStyles.firaSansTextBase}
  color: ${({ theme }) => theme.colors.error};
  margin-top: 0.5rem;
`

export const Help = styled.p`
  ${({ theme }) => theme.textStyles.firaSansTextBase}
  color: grey;
  margin-top: 0.5rem;
`
