import styled from 'styled-components'
import Image from 'next/image'

import FormFieldInput from '../FormFieldInput'
import Icon from '../../../components/Icon'
import FormLabel from '../../FormLabel'

export const Container = styled.div``

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Input = styled(FormFieldInput)`
  width: 100%;

  & input {
    opacity: 0;
    position: absolute;
  }
`

export const Value = styled.p`
  ${({ theme }) => theme.textStyles.montserratSm};
  color: ${({ theme }) => theme.colors.bluePrimary};
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const Placeholder = styled.p`
  color: ${({ theme }) => theme.colors.dustyGray};
  ${({ theme }) => theme.textStyles.montserratSm};
`

export const Upload = styled(Icon)``

export const ModalButton = styled.button`
  flex-shrink: 0;
  width: 3.9rem;
  height: 3.9rem;
  border-radius: 0.5rem;
  background: ${({ theme }) => theme.colors.pureWhite};
  border: 1px solid ${({ theme }) => theme.colors.greyContour};
  margin: 0;
  padding: 0;
`

export const Preview = styled(Image)`
  flex: 1;
  object-fit: cover;
`

export const Label = styled(FormLabel)``

export const Block = styled.div`
  display: flex;
  gap: 1.4rem;
`

export const IconWrapper = styled.div``
export const ImageWrapper = styled.div``

export const TrashIcon = styled(Icon)`
  display: block;
  margin: 0 0 1.4rem auto;

  &:hover {
    cursor: pointer;
  }
`
