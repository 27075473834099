import QueryString from 'qs'

import { PaginatedResult } from '../../types/PaginatedResult'
import { getRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'
import { ClassifiedAnswerTransformer } from '../../transformers/classifiedTransformer'
import { SClassifiedAnswer } from '../../types/api/Classified'

type GetAnswersResponse = PaginatedResult<SClassifiedAnswer>

export type GetAnswersParams = {
  filters?: { [key: string]: any }
  populate?: { [key: string]: any }
  pagination?: {
    start?: number
    limit?: number
    page?: number
    pageSize?: number
    withCount?: boolean
  }
  sort?: string | Array<string>
}

const getAnswers = (data: GetAnswersParams) => {
  return getRestRequest({
    url: `${api.REST_API_URL}/classified-answers?${QueryString.stringify(
      {
        ...data,
      },
      {
        encodeValuesOnly: true,
      }
    )}`,
    transformer: (response: GetAnswersResponse) => {
      return response.data
        ? response.data?.map((c) => ClassifiedAnswerTransformer(c))
        : []
    },
  })
}
export default getAnswers
