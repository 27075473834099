import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../store'
import { getCustomService } from '../../helpers'
import {
  GetPaymentIntentParams,
  GetPaymentIntentResponse,
} from '../../rest/requests/stripe/getPaymentIntent'
import { GetPriceResponse } from '../../rest/requests/stripe/getPrice'
import { GetInvoicesResponse } from '../../rest/requests/stripe/getInvoices'

//
// requests
//

export const getPaymentIntentService = getCustomService<
  'getPaymentIntent',
  GetPaymentIntentResponse,
  GetPaymentIntentParams
>('getPaymentIntent')
export const getPriceService = getCustomService<
  'getPrice',
  GetPriceResponse,
  null
>('getPrice')
export const getInvoicesService = getCustomService<
  'getInvoices',
  GetInvoicesResponse,
  null
>('getInvoices')

//
// Initial state
//

export type AuthState = {
  getPaymentIntent: typeof getPaymentIntentService.state
  getPrice: typeof getPriceService.state
  getInvoices: typeof getInvoicesService.state
}

const initialState: AuthState = {
  getPaymentIntent: getPaymentIntentService.state,
  getPrice: getPriceService.state,
  getInvoices: getInvoicesService.state,
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: 'stripe',
  initialState,
  reducers: {
    ...getPaymentIntentService.reducers,
    ...getPriceService.reducers,
    ...getInvoicesService.reducers,
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[slice.name]
const getPaymentIntent = (state: RootState) => root(state).getPaymentIntent
const getPrice = (state: RootState) => root(state).getPrice
const getInvoices = (state: RootState) => root(state).getInvoices

export const selectors = {
  getPaymentIntent,
  getPrice,
  getInvoices,
}
