import { all, fork } from 'redux-saga/effects'

// App
import AppSagas from './app/sagas'
import ApiSagas from './api/sagas'
import AuthSagas from './auth/sagas'
import ExampleSagas from './example/sagas'
import FeedbacksSagas from './feedbacks/sagas'
import UploadSagas from './upload/sagas'
import ReservationsSagas from './reservations/sagas'
import AlertSagas from './alert/sagas'
import OffersSagas from './offers/sagas'
import FiltersSagas from './filters/sagas'
import MailSagas from './mail/sagas'
import StripeSagas from './stripe/sagas'
import ClassifiedsSagas from './classifieds/sagas'
import UserSagas from './user/sagas'
import GeoSagas from './geo/sagas'
import AnswerSagas from './answer/sagas'
import PreviewSagas from './preview/sagas'
import ChatSagas from './chat/sagas'
import RedirectionsSagas from './redirections/sagas'

function* loop() {
  yield all([
    ApiSagas.loop(),
    AppSagas.loop(),
    AuthSagas.loop(),
    ExampleSagas.loop(),
    FeedbacksSagas.loop(),
    UploadSagas.loop(),
    ReservationsSagas.loop(),
    AlertSagas.loop(),
    OffersSagas.loop(),
    FiltersSagas.loop(),
    MailSagas.loop(),
    StripeSagas.loop(),
    ClassifiedsSagas.loop(),
    UserSagas.loop(),
    GeoSagas.loop(),
    AnswerSagas.loop(),
    PreviewSagas.loop(),
    ChatSagas.loop(),
    RedirectionsSagas.loop(),
  ])
}

export default function* rootSaga() {
  yield fork(loop)
}
