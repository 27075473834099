import qs from 'qs'

import { getRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'
import { PaginatedResult } from '../../types/PaginatedResult'
import { classifiedMediaTransformer } from '../../transformers/classifiedTransformer'
import { SClassifiedMedia } from '../../types/api/Classified'

type GetMediaListResponse = PaginatedResult<SClassifiedMedia>

export type GetMediaListParams = {
  classifiedId: string
}

const getMediaList = ({ classifiedId }: GetMediaListParams) =>
  getRestRequest({
    url:
      api.REST_API_URL +
      `/classified-medias?${qs.stringify(
        {
          filters: {
            classified: classifiedId,
          },
          populate: {
            photo: {
              populate: '*',
            },
            document: {
              populate: '*',
            },
          },
        },
        {
          encodeValuesOnly: true,
        }
      )}`,
    transformer: (response: GetMediaListResponse) => {
      return response.data.map((d) => classifiedMediaTransformer(d))
    },
  })

export default getMediaList
