import QueryString from 'qs'

import { getRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'
import { PaginatedResult } from '../../types/PaginatedResult'
import { SClassifiedCabinetType } from '../../types/api/Classified'
import { classifiedCabinetTypeTransformer } from '../../transformers/classifiedTransformer'

type GetClassifiedCabinetTypesResponse = PaginatedResult<SClassifiedCabinetType>
export type getCabinetTypesParams = {
  filters?: { [key: string]: any }
  populate?: { [key: string]: any }
  pagination?: {
    start?: number
    limit?: number
    page?: number
    pageSize?: number
    withCount?: boolean
  }
  sort?: string | Array<string>
}

const getCabinetTypes = (data: getCabinetTypesParams) =>
  getRestRequest({
    url: `${api.REST_API_URL}/classified-cabinet-types?${QueryString.stringify(
      {
        ...data,
      },
      {
        encodeValuesOnly: true,
      }
    )}
    `,
    transformer: (response: GetClassifiedCabinetTypesResponse) => {
      return response.data.map((item) => classifiedCabinetTypeTransformer(item))
    },
  })

export default getCabinetTypes
