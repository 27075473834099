import { putRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'
import { SimpleResult } from '../../types/PaginatedResult'
import { SClassifiedMedia } from '../../types/api/Classified'
import { classifiedMediaTransformer } from '../../transformers/classifiedTransformer'

type UpdateMediaResponse = SimpleResult<SClassifiedMedia>

export type UpdateMediaParams = {
  id: string
  data: any
  files?: Array<{ name: string; value: File }>
}

const updateMedia = ({ id, data, files }: UpdateMediaParams) => {
  const formData = new FormData()

  if (files?.length) {
    for (const file of files) {
      formData.append(file.name, file.value)
    }
  }

  formData.append('data', JSON.stringify(data))

  return putRestRequest({
    url: api.REST_API_URL + `/classified-medias/${id}`,
    data: formData,
    transformer: (response: UpdateMediaResponse) => {
      return classifiedMediaTransformer(response.data)
    },
  })
}

export default updateMedia
