import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../store'
import { getCustomService } from '../../helpers'
import { UserProfile } from '../../rest/types/UserProfile'
import { ClassifiedAlert } from '../../rest/types/Classified'
import { GetAlertsParams } from '../../rest/requests/classifieds/getAlerts'
import { DeleteAlertParams } from '../../rest/requests/classifieds/deleteAlert'
import { UpdateAlertParams } from '../../rest/requests/classifieds/updateAlert'
import { CreateAlertParams } from '../../rest/requests/classifieds/createAlert'

//
// Services
//

export const getUserProfilesService = getCustomService<
  'getUserProfiles',
  Array<UserProfile>,
  null
>('getUserProfiles')

export const getUserSpecialitiesService = getCustomService<
  'getUserSpecialities',
  Array<UserProfile>,
  null
>('getUserSpecialities')

export const getAlertsService = getCustomService<
  'getAlerts',
  Array<ClassifiedAlert>,
  GetAlertsParams
>('getAlerts')

export const deleteAlertService = getCustomService<
  'deleteAlert',
  ClassifiedAlert,
  DeleteAlertParams
>('deleteAlert')

export const updateAlertService = getCustomService<
  'updateAlert',
  ClassifiedAlert,
  UpdateAlertParams
>('updateAlert')

export const createAlertService = getCustomService<
  'createAlert',
  ClassifiedAlert,
  CreateAlertParams
>('createAlert')

//
// Initial state
//

export type FeedbacksState = {
  // Services
  getUserProfiles: typeof getUserProfilesService.state
  getUserSpecialities: typeof getUserProfilesService.state
  getAlerts: typeof getAlertsService.state
  deleteAlert: typeof deleteAlertService.state
  updateAlert: typeof updateAlertService.state
  createAlert: typeof createAlertService.state
  // Customs
  updatedAlert: ClassifiedAlert | null
}

const initialState: FeedbacksState = {
  // Services
  getUserProfiles: getUserProfilesService.state,
  getUserSpecialities: getUserSpecialitiesService.state,
  getAlerts: getAlertsService.state,
  deleteAlert: deleteAlertService.state,
  updateAlert: updateAlertService.state,
  createAlert: createAlertService.state,
  // Customs
  updatedAlert: null,
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // Services
    ...getUserProfilesService.reducers,
    ...getUserSpecialitiesService.reducers,
    ...getAlertsService.reducers,
    ...deleteAlertService.reducers,
    ...updateAlertService.reducers,
    ...createAlertService.reducers,
    // Customs
    setUpdatedAlert: (state, action) => {
      state.updatedAlert = action.payload
    },
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[slice.name]
const getUserProfiles = (state: RootState) => root(state).getUserProfiles
const getUserSpecialities = (state: RootState) => root(state).getUserProfiles
const getAlerts = (state: RootState) => root(state).getAlerts
const deleteAlert = (state: RootState) => root(state).deleteAlert
const createAlert = (state: RootState) => root(state).createAlert
const updateAlert = (state: RootState) => root(state).updateAlert
const updatedAlert = (state: RootState) => root(state).updatedAlert

export const selectors = {
  // Services
  getUserProfiles,
  getUserSpecialities,
  getAlerts,
  deleteAlert,
  updateAlert,
  createAlert,
  // Customs
  updatedAlert,
}
