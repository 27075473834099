import { useEffect } from 'react'

const useLastFocus = (isOpen?: boolean) => {
  useEffect(() => {
    let currentFocus: HTMLElement | null
    if (isOpen) {
      currentFocus = window.document.activeElement as HTMLElement
    }
    return () => {
      if (currentFocus) {
        currentFocus?.focus?.()
      }
    }
  }, [isOpen])
}

export default useLastFocus
