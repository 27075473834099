import { FC } from 'react'
import type { AppProps, NextWebVitalsMetric } from 'next/app'
import dynamic from 'next/dynamic'

import { ReduxProvider, ThemeProvider, I18nProvider } from '../providers'
import Metas from '../components/Metas'
import type { PageProps } from '../relay/Common/PageProps'

import '../helpers/BugsnagHelpers'
import ValidateEmailModal from 'src/relay/Components/ValidateEmailModal'

const ProgressBar = dynamic(() => import('../components/ProgressBar'))
const NotFoundPage = dynamic(() => import('./404'))
const MaintenanceGuard = dynamic(() => import('../guards/MaintenanceGuard'))

const Application: FC<AppProps<PageProps>> = ({ Component, ...rest }) => {
  const JSCComponent = Component as FC<PageProps>
  const pageProps: PageProps = rest?.pageProps

  return (
    <ReduxProvider>
      <I18nProvider store={pageProps?.i18n}>
        <ThemeProvider>
          <Metas {...pageProps?.metas} />
          <ProgressBar />
          <ValidateEmailModal />
          <MaintenanceGuard {...pageProps}>
            {(rest as any)?.error ? (
              <NotFoundPage {...pageProps} />
            ) : (
              <JSCComponent {...pageProps} />
            )}
          </MaintenanceGuard>
        </ThemeProvider>
      </I18nProvider>
    </ReduxProvider>
  )
}

export function reportWebVitals(metric: NextWebVitalsMetric) {
  console.info(metric) // The metric object ({ id, name, startTime, value, label }) is logged to the console

  /*ga('send', 'event', {
      eventCategory:
        metric.label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
      eventAction: name,
      eventValue: Math.round(
        metric.name === 'CLS' ? metric.value * 1000 : metric.value
      ), // values must be integers
      eventLabel: metric.id, // id unique to current page load
      nonInteraction: true, // avoids affecting bounce rate.
    })*/
}

export default Application
