import { all, put, takeLeading } from 'redux-saga/effects'

import ApiSagas from '../api/sagas'
import { actions } from '../index'
import sendYoungDoctorEmail from '../../rest/requests/mail/sendYoungDoctorEmail'
import SendProductFormEmail from '../../rest/requests/mail/sendProductFormEmail'

export default class MailSagas {
  static *onSendYoungDoctorEmailRequest({
    payload,
  }: ReturnType<typeof actions.mail.sendYoungDoctorEmailRequest>) {
    const response = yield* ApiSagas.rest(sendYoungDoctorEmail(payload))

    if (response.data) {
      yield put(actions.mail.sendYoungDoctorEmailSuccess(response.data))
    } else {
      yield put(actions.mail.sendYoungDoctorEmailError(response.errors))
    }
  }

  static *onSendProductFormEmailRequest({
    payload,
  }: ReturnType<typeof actions.mail.sendProductFormEmailRequest>) {
    const response = yield* ApiSagas.rest(SendProductFormEmail(payload))

    if (response.data) {
      yield put(actions.mail.sendProductFormEmailSuccess(response.data))
    } else {
      yield put(actions.mail.sendProductFormEmailError(response.errors))
    }
  }

  static *loop() {
    yield all([
      takeLeading(
        actions.mail.sendYoungDoctorEmailRequest,
        this.onSendYoungDoctorEmailRequest
      ),
      takeLeading(
        actions.mail.sendProductFormEmailRequest,
        this.onSendProductFormEmailRequest
      ),
    ])
  }
}
