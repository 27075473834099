import React, { FC } from 'react'
import { TailSpin } from 'react-loader-spinner'
import { useTheme } from 'styled-components'

import { IconProps } from '../Icon'
import { LinkProps } from '../Link'

import * as SC from './styled'
import { ActionButtonVariant } from './styled'

export type ActionButtonProps = LinkProps & {
  label?: string
  active?: boolean
  iconProps?: IconProps
  iconPosition?: 'left' | 'right'
  isLoading?: boolean
  variant?: ActionButtonVariant
}

const ActionButton: FC<ActionButtonProps> = ({
  label,
  text,
  children,
  active,
  iconProps,
  iconPosition,
  isLoading = false,
  variant = ActionButtonVariant.primary,
  ...linkProps
}) => {
  const theme = useTheme()
  return (
    <SC.ActionButton
      $active={active}
      $variant={variant}
      {...linkProps}
      $isLoading={isLoading}
    >
      {iconProps && (!iconPosition || iconPosition === 'left') && (
        <SC.ActionIcon {...iconProps} />
      )}
      {(children || label || text) && (
        <SC.Label>{children || label || text}</SC.Label>
      )}
      {iconProps && iconPosition === 'right' && (
        <SC.ActionIcon {...iconProps} />
      )}
      <SC.SpinnerWrapper>
        <TailSpin
          width={15}
          height={15}
          color={theme.colors.pureWhite}
          visible={isLoading}
        />
      </SC.SpinnerWrapper>
    </SC.ActionButton>
  )
}

export default ActionButton
