import { getRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'

export type getBlockSchemaParams = {
  uid: string
}

const getBlockSchema = ({ uid }: getBlockSchemaParams) =>
  getRestRequest({
    url:
      api.REST_API_URL +
      `/content-type-builder/components/${uid}
    `,
    transformer: (response: any) => {
      return { ...response.data.schema.attributes }
    },
  })

export default getBlockSchema
