import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { RootState } from '../store'

//
// requests
//

//
// Initial state
//

export enum RedirectionType {
  Alert = 'Alert',
}

type RedirectionsSate = {
  shouldRedirect: boolean
  hasRedirected: boolean
  redirectType: RedirectionType | null
  redirectUrl: string | null
}

const initialState: RedirectionsSate = {
  shouldRedirect: false,
  hasRedirected: false,
  redirectType: null,
  redirectUrl: null,
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: 'redirections',
  initialState,
  reducers: {
    reset: () => {
      return initialState
    },
    setShouldRedirect: (
      state,
      action: PayloadAction<RedirectionsSate['shouldRedirect']>
    ) => {
      state.shouldRedirect = action.payload
    },
    setRedirectType: (
      state,
      action: PayloadAction<RedirectionsSate['redirectType']>
    ) => {
      state.redirectType = action.payload
    },
    setRedirectUrl: (
      state,
      action: PayloadAction<RedirectionsSate['redirectUrl']>
    ) => {
      state.redirectUrl = action.payload
    },
    hasRedirected: (
      state,
      action: PayloadAction<RedirectionsSate['hasRedirected']>
    ) => {
      state.hasRedirected = action.payload
    },
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[slice.name]

const shouldRedirect = (state: RootState) => root(state).shouldRedirect
const hasRedirected = (state: RootState) => root(state).hasRedirected
const redirectUrl = (state: RootState) => root(state).redirectUrl
const redirectType = (state: RootState) => root(state).redirectType

export const selectors = {
  shouldRedirect,
  hasRedirected,
  redirectUrl,
  redirectType,
}
