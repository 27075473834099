import { gql } from '../../../generated'
import { getMutationService } from '../../../../helpers/GraphqlHelpers'

export const refreshToken = getMutationService({
  mutation: gql(/* GraphQL */ `
    mutation AuthRefreshToken {
      __typename
    }
  `),
  transformer: (response) => {
    return response
  },
})
