export enum ListDisplayType {
  List = '1',
  Map = '2',
  Card = '3',
}

export enum ClassifiedCategoryId {
  Installation = '1',
  Replacement = '2',
  Garde = '3',
  Liberal = '4',
}
