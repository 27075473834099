'use client'

import React, {
  AriaAttributes,
  FC,
  FocusEventHandler,
  MouseEventHandler,
  TouchEventHandler,
  useEffect,
} from 'react'
import NextLink, { LinkProps as NextLinkProps } from 'next/link'
import { useRouter } from 'next/router'

import * as SC from './styled'

export type LinkUnion = HTMLButtonElement | HTMLLinkElement | HTMLAnchorElement

type InternalLinkProps = {
  className?: string
  target?: string
  title?: string
  role?: string
  tabIndex?: number
  rel?: string
  nextAs?: string
  href?: string
  onClick?: MouseEventHandler<LinkUnion>
  onFocus?: FocusEventHandler<LinkUnion>
  onMouseEnter?: MouseEventHandler<LinkUnion>
  onTouchStart?: TouchEventHandler<LinkUnion>
  label?: string
  text?: string
  ariaLabel?: string
  disabled?: boolean
  children?: React.ReactNode
  type?: React.ButtonHTMLAttributes<any>['type']
  itemProp?: string
  c2bEan?: string | number
}

export type LinkProps = AriaAttributes &
  Omit<NextLinkProps, 'as' | 'href' | 'className' | keyof InternalLinkProps> &
  InternalLinkProps

export type TextLinkProps = (LinkProps & { text: string }) | null

const Link: FC<LinkProps> = ({
  className,
  children,
  href,
  prefetch,
  replace,
  scroll,
  shallow,
  locale,
  target,
  title,
  label,
  role,
  tabIndex,
  rel,
  onClick,
  onFocus,
  ariaLabel,
  nextAs,
  disabled,
  type,
  text,
  c2bEan,
  ...extraProps
}) => {
  const hasRouter = !!useRouter()

  useEffect(() => {
    // @ts-expect-error external script initialized for click2buy links
    if (typeof c2bWidget !== 'undefined') c2bWidget.init()
  }, [c2bEan])

  return (
    <>
      {href && hasRouter && (
        <NextLink
          href={href}
          passHref
          prefetch={false}
          {...(prefetch && { prefetch })}
          {...(replace && { replace })}
          {...(scroll && { scroll })}
          {...(shallow && { shallow })}
          {...(locale && { locale })}
          {...(title && { title })}
          {...(role && { role })}
          {...(tabIndex && { tabIndex })}
          {...(rel && { rel })}
          {...(onFocus && { onFocus })}
          {...(nextAs && { as: nextAs })}
          legacyBehavior
        >
          <SC.Link
            $disabled={disabled}
            className={`${className} is-link`}
            {...(onClick && { onClick })}
            {...(target && { target })}
            {...(ariaLabel && { 'aria-label': ariaLabel })}
            {...extraProps}
          >
            {children || label || text}
          </SC.Link>
        </NextLink>
      )}
      {href && !hasRouter && (
        <SC.Link
          $disabled={disabled}
          href={href}
          title={title}
          className={`${className} is-link`}
          {...(ariaLabel && { 'aria-label': ariaLabel })}
          {...(target && { target })}
          {...(onFocus && { onFocus })}
          {...extraProps}
        >
          {children || label || text}
        </SC.Link>
      )}
      {!href && (onClick || !!type) && (
        <SC.Button
          $disabled={disabled}
          className={`${className} is-button`}
          onClick={onClick}
          type={type}
          {...(onFocus && { onFocus })}
          {...(ariaLabel && { 'aria-label': ariaLabel })}
          {...extraProps}
        >
          {children || label || text}
        </SC.Button>
      )}
      {!href && !(onClick || !!type) && (
        <SC.Span
          $disabled={disabled}
          className={`${className} is-span`}
          {...(onFocus && { onFocus, tabIndex: 0 })}
          {...(c2bEan && {
            'data-widget-element': true,
            'data-widget-ean': c2bEan,
          })}
          {...extraProps}
        >
          {children || label || text}
        </SC.Span>
      )}
    </>
  )
}

export default Link
