import { all, call, put, select, take } from 'redux-saga/effects'
import { differenceInMinutes } from 'date-fns'
import { NotifiableError } from '@bugsnag/js'
import { OperationVariables } from '@apollo/client/core/types'
import { ResultOf } from '@graphql-typed-document-node/core'
import { TypedDocumentNode } from '@apollo/client'
import { AxiosHeaders, RawAxiosRequestHeaders } from 'axios'

import client, {
  contentfulClient,
  defaultConfig,
  persistedClient,
} from '../../graphql/client'
import bugsnagClient from '../../helpers/BugsnagHelpers'
import * as configuration from '../../configuration'
import {
  ApiResponse,
  MutationService,
  QueryService,
  transformErrors,
} from '../../helpers'
import {
  actions as AuthActions,
  selectors as AuthSelectors,
} from '../auth/redux'
import { refreshToken } from '../../graphql/services/auth/mutations'
import { RestApiResponse, RestRequest } from '../../helpers/RestHelpers'
import RestApiClient from '../../rest/client'

import { actions as ApiActions, selectors as ApiSelectors } from './redux'

const DEBUG = configuration.api.DEBUG
const log = DEBUG ? console.log : () => null

export default class ApiSagas {
  static *getHeaders(checkToken = true): any {
    const headers: Headers = yield select(ApiSelectors.headers)
    let token = yield select(AuthSelectors.token)

    if (checkToken) {
      token = yield call(ApiSagas.getToken)
    }

    return {
      ...headers,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
    }
  }

  static *rest<Request extends RestRequest>(
    request: Request,
    headers?: RawAxiosRequestHeaders,
    enableBearer = true
  ) {
    const token: string = yield select(AuthSelectors.token)

    const response: RestApiResponse<Request> = yield call(
      RestApiClient.call,
      request,
      {
        ...headers,
        ...(token &&
          enableBearer && {
            Authorization: `Bearer ${token}`,
          }),
      } as AxiosHeaders
    )

    return response
  }

  static *call<
    TVariables extends OperationVariables,
    Transformer extends (
      response: ResultOf<TypedDocumentNode<TData, TVariables>>
    ) => any,
    TData = any
  >(
    service:
      | QueryService<TVariables, TData, Transformer>
      | MutationService<TVariables, TData, Transformer>,
    variables: TVariables | null = null,
    options?: {
      persisted: boolean
    }
  ) {
    if ('query' in service && options?.persisted) {
      return (yield call(
        ApiSagas.persistQuery,
        service as QueryService,
        variables
      ) as unknown) as ApiResponse<typeof service>
    }

    if ('query' in service) {
      return (yield call(
        ApiSagas.query,
        service as QueryService,
        variables
      ) as unknown) as ApiResponse<typeof service>
    }

    if ('mutation' in service) {
      return (yield call(
        ApiSagas.mutate,
        service as MutationService,
        variables
      ) as unknown) as ApiResponse<typeof service>
    }

    return { data: undefined } as ApiResponse<typeof service>
  }

  static *query<
    TVariables extends OperationVariables = OperationVariables,
    TData = any
  >(
    service: QueryService<TVariables, TData>,
    variables: TVariables | null = null
  ) {
    const headers: Headers = yield call(ApiSagas.getHeaders)
    const response: ApiResponse = yield ApiSagas.clientCall(
      client.query,
      service,
      variables,
      headers
    )
    return response
  }

  static *mutate<
    TVariables extends OperationVariables = OperationVariables,
    TData = any
  >(
    service: MutationService<TVariables, TData>,
    variables: TVariables | null = null
  ) {
    const headers: Headers = yield call(ApiSagas.getHeaders)
    const response: ApiResponse = yield ApiSagas.clientCall(
      client.mutate,
      service,
      variables,
      headers
    )
    return response
  }

  static *persistQuery<
    TVariables extends OperationVariables = OperationVariables,
    TData = any
  >(
    service: QueryService<TVariables, TData>,
    variables: TVariables | null = null
  ) {
    const headers: Headers = yield call(ApiSagas.getHeaders, false)
    const response: ApiResponse = yield ApiSagas.clientCall(
      persistedClient.query,
      service,
      variables,
      {
        ...headers,
        Authorization: undefined,
      } as Headers
    )
    return response
  }

  static *clientCall(
    method:
      | typeof client.query
      | typeof client.mutate
      | typeof persistedClient.query
      | typeof contentfulClient.query,
    service: QueryService<any, any> | MutationService<any, any>,
    variables: OperationVariables | null,
    headers: Headers
  ) {
    let result: ApiResponse

    try {
      result = yield call(method as any, {
        ...defaultConfig,
        ...service,
        ...(variables && { variables }),
        context: {
          ...service?.context,
          headers: {
            ...service?.context?.headers,
            ...headers,
          },
        },
      })
    } catch (e) {
      console.error(`ApiSagas:`, e, variables)

      if (bugsnagClient) {
        bugsnagClient.addMetadata('graphQL', {
          Variables: variables,
          Config: service,
        })
        bugsnagClient.notify(e as NotifiableError)
      }

      return {
        errors: e,
      }
    }

    if (result.errors) {
      console.error(`ApiSagas:`, result.errors)
    }

    const resultTransformed: ReturnType<typeof ApiSagas.transformResult> =
      yield call(ApiSagas.transformResult, result, service?.transformer)

    return resultTransformed
  }

  static *contentfulQuery(
    service: QueryService,
    variables: OperationVariables | null = null
  ) {
    let result: ApiResponse

    try {
      result = yield call(contentfulClient.query as any, {
        ...defaultConfig,
        ...service,
        ...(variables && { variables }),
        context: {
          ...service?.context,
          headers: {
            ...service?.context?.headers,
          },
        },
      })
    } catch (e) {
      console.error(`ApiSagas:`, e, variables)

      if (bugsnagClient) {
        bugsnagClient.addMetadata('graphQL', {
          Variables: variables,
          Config: service,
        })
        bugsnagClient.notify(e as NotifiableError)
      }

      return {
        errors: e,
      }
    }

    if (result.errors) {
      console.error(`ApiSagas:`, result.errors)
    }

    const resultTransformed: ReturnType<typeof ApiSagas.transformResult> =
      yield call(ApiSagas.transformResult, result, service?.transformer)

    return resultTransformed
  }

  static transformResult(
    result: ApiResponse,
    transformer: QueryService['transformer'] | MutationService['transformer']
  ) {
    if (!result.data || !transformer) {
      return result
    }

    const data = transformer(result.data)

    return { ...result, data }
  }

  static *checkTokenExpire() {
    const storeToken: string | null = yield select(AuthSelectors.token)

    if (storeToken === 'mock_token') return storeToken

    const jwt: ReturnType<typeof AuthSelectors.jwt> = yield select(
      AuthSelectors.jwt
    )
    const expirationDate: number | undefined = jwt?.exp

    if (!expirationDate || !storeToken) {
      return storeToken
    }

    const expires = new Date(expirationDate * 1000)
    const diff = -differenceInMinutes(new Date(), expires)
    log(
      `Api: Token expires at ${expirationDate} (${new Date(
        expirationDate * 1000
      )}) (${diff})`
    )

    const refreshingToken: boolean | null = yield select(
      ApiSelectors.refreshing
    )
    if (refreshingToken) {
      log('Api: Token already refreshing')
      yield take(ApiActions.setRefreshing.type)
      const newStoreToken: string | null = yield select(AuthSelectors.token)
      log('Api: Token refreshing complete', newStoreToken)
      return newStoreToken
    }

    if (diff >= 5) {
      return storeToken
    }

    yield put(ApiActions.setRefreshing(true))
    log('Api: token needs refreshing')
    const headers: Headers = yield call(ApiSagas.getHeaders, false)

    const result: ApiResponse<typeof refreshToken> = yield call(
      ApiSagas.clientCall,
      client.mutate,
      {
        ...refreshToken,
        context: {
          headers,
        },
      },
      {},
      headers
    )

    if (result.errors) {
      log('Api: refresh token error', transformErrors(result.errors))
      yield put(AuthActions.resetAuth())
      return null
    }

    const token = result?.data ?? null
    if (!token) {
      log('Api: refresh token error')
      yield put(AuthActions.resetAuth())
      return null
    }

    log('Api: refresh token success', result?.data)
    yield put(AuthActions.setToken(token as unknown as string))
    yield put(ApiActions.setRefreshing(false))

    return token
  }

  static *getToken() {
    const token: ReturnType<typeof AuthSelectors.token> = yield call(
      ApiSagas.checkTokenExpire
    )
    return token
  }

  static *loop() {
    yield all([
      //
    ])
  }
}
