import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { REHYDRATE } from 'redux-persist'

import type { RootState } from '../store'
import { AdsFormValues } from '../../forms/AdsForm'
import { getCustomService } from '../../helpers/ReduxHelpers'
import { Classified } from '../../rest/types/Classified'
import { SummarizeDescriptionParams } from '../../rest/requests/classifieds/summarizeDescription'

import { SetClassified, SetId } from './types'

//
// Services
//

export const setPreviewService = getCustomService<
  'setPreview',
  any,
  { formValues: AdsFormValues; classified: Classified }
>('setPreview')

export const getSummarizeDescriptionService = getCustomService<
  'getSummarizeDescription',
  any,
  SummarizeDescriptionParams
>('getSummarizeDescription')

//
// Initial state
//

export type PreviewState = {
  setPreview: typeof setPreviewService.state
  id: string | null
  classified: Classified | null
  currentValuesClassifiedForm: AdsFormValues | null
  getSummarizeDescription: typeof getSummarizeDescriptionService.state
  isSummaryByIa: boolean
}

const initialState: PreviewState = {
  setPreview: setPreviewService.state,
  id: null,
  classified: null,
  currentValuesClassifiedForm: null,
  getSummarizeDescription: getSummarizeDescriptionService.state,
  isSummaryByIa: false,
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: 'preview',
  initialState,
  reducers: {
    reset: () => {
      return initialState
    },
    setId: (state, action: SetId) => {
      state.id = action.payload.id
    },
    setClassified: (state, action: SetClassified) => {
      state.classified = action.payload.classified
    },
    setCurrentValuesClassifiedForm: (state, action) => {
      state.currentValuesClassifiedForm = action.payload
    },
    setIsSummaryByIa: (state, action) => {
      state.isSummaryByIa = action.payload
    },
    ...setPreviewService.reducers,
    ...getSummarizeDescriptionService.reducers,
  },
  extraReducers: (builder) =>
    builder.addCase<any, PayloadAction<any>>(
      REHYDRATE,
      (_state, action: PayloadAction<any>) => {
        return { ...(action.payload?.['preview'] ?? initialState) }
      }
    ),
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[slice.name]
const setPreview = (state: RootState) => root(state).setPreview
const id = (state: RootState) => root(state).id
const classified = (state: RootState) => root(state).classified
const currentValuesClassifiedForm = (state: RootState) =>
  root(state).currentValuesClassifiedForm
const getSummarizeDescription = (state: RootState) =>
  root(state).getSummarizeDescription
const isSummaryByIa = (state: RootState) => root(state).isSummaryByIa

export const selectors = {
  setPreview,
  id,
  classified,
  currentValuesClassifiedForm,
  getSummarizeDescription,
  isSummaryByIa,
}
