import { ImageProps } from 'next/image'

import { SImage, SImageData } from '../../rest/types/api/Image'
import { api } from '../../configuration'
import { Image } from '../../rest/types/Image'
import { getSize, toBase64 } from '../../helpers/FileHelpers'

export function ImageTransformer(
  data?: SImage,
  fill = false
): ImageProps | undefined {
  const format = data?.attributes?.formats?.large?.url
    ? data.attributes.formats.large
    : data?.attributes?.formats?.medium?.url
    ? data.attributes.formats.medium
    : data?.attributes?.formats?.small?.url
    ? data.attributes.formats.small
    : data?.attributes?.formats?.thumbnail?.url
    ? data.attributes.formats.thumbnail
    : undefined

  return data?.attributes
    ? {
        src: `${api.API_URL}${format?.url || data?.attributes?.url}`,
        alt: data?.attributes?.alternativeText ?? '',
        ...(!fill && {
          width: format?.width || data?.attributes?.width,
          height: format?.height || data?.attributes?.height,
        }),
        fill,
      }
    : undefined
}

export const UrlImageTransformer = (url: string) => {
  return `${api.API_URL}${url}`
}

export const ImageToFile = (image: SImageData): File => {
  return new File([''], image?.data?.attributes.name ?? '')
}

export function UrlTransformer(image: Image) {
  return api.API_URL + '/' + image.url
}

export async function FileToImage(file: File): Promise<Image> {
  const base64 = await toBase64(file)
  const { width, height } = await getSize(file)

  return {
    url: base64,
    caption: '',
    width: width,
    height: height,
    formats: {},
    id: '',
  }
}
