import { postRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'
import { PaginatedResult } from '../../types/PaginatedResult'
import { SClassifiedCategory } from '../../types/api/Classified'

type getCategoriesByUserProfileResponse = PaginatedResult<SClassifiedCategory>

export type getCategoriesByUserProfileParams = {
  userProfileId: string
}

const getCategoriesByUserProfile = ({
  userProfileId,
}: getCategoriesByUserProfileParams) => {
  return postRestRequest({
    url: api.REST_API_URL + '/classified/get-classifieds-by-profile',
    data: {
      userProfileId: userProfileId,
    },
    transformer: (response: getCategoriesByUserProfileResponse) => {
      return response.data
    },
  })
}

export default getCategoriesByUserProfile
