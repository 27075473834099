import { getRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'
import { Invoice } from '../../types/Invoice'

export type GetInvoicesResponse = {
  data: Invoice[]
}

const getInvoices = () =>
  getRestRequest({
    url: api.REST_API_URL + `/stripe/getInvoices`,
    transformer: (data: GetInvoicesResponse): GetInvoicesResponse => {
      return data
    },
  })

export default getInvoices
