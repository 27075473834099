import { dataToFormData, postRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'
import { User } from '../../types/User'

export type ResetPasswordResponse = {
  jwt: string
  user: User
}

export type ResetPasswordParams = {
  password: string
  passwordConfirmation: string
  code: string
}

const resetPassword = (data: ResetPasswordParams) =>
  postRestRequest({
    url: api.REST_API_URL + `/auth/reset-password`,
    data: dataToFormData(data),
    transformer: (data: ResetPasswordResponse) => {
      return data
    },
  })

export default resetPassword
