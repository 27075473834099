import qs from 'qs'

import { getRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'
import { User } from '../../types/User'

const me = () =>
  getRestRequest({
    url:
      api.REST_API_URL +
      `/users/me?${qs.stringify(
        {
          populate: {
            avatar: {
              populate: '*',
            },
            profile: {
              fields: ['id', 'name', 'default', 'canHaveSpecialty'],
            },
            speciality: {
              populate: '*',
            },
            role: {
              populate: '*',
            },
          },
        },
        {
          encodeValuesOnly: true,
        }
      )}`,
    transformer: (data: any): User => {
      return {
        ...data,
        offersFeedbackIds: data.feedbacks?.reduce(
          (acc: any, next: any) => [...acc, next.offer.id],
          []
        ),
      }
    },
  })

export default me
