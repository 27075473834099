import { FC } from 'react'
import cx from 'classnames'
import { AccountValidateEmailFormProps } from 'src/forms/AccountValidateEmailForm'

import { ModalProps } from '../Modal'

import * as SC from './styled'

export type AccountValidateEmailModalProps = ModalProps & {
  className?: string
  form: AccountValidateEmailFormProps
}

const AccountValidateEmailModal: FC<AccountValidateEmailModalProps> = ({
  className,
  form,
  ...modal
}) => {
  return (
    <SC.ModalStyled
      className={cx('AccountValidateEmailModal', className)}
      {...modal}
    >
      <SC.Form {...form} />
    </SC.ModalStyled>
  )
}

export default AccountValidateEmailModal
