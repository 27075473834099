import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../store'

//
// requests
//

//
// Initial state
//

type ModalsState = {
  addMediaModalIsOpen: boolean
  updateMediaModalIsOpen: boolean
  archiveAdsModalIsOpen: boolean
  updateAlertModalIsOpen: boolean
  answerClassifiedModalIsOpen: boolean
  answerClassifiedFormatedModalIsOpen: boolean
  loginModalIsOpen: boolean
  confirmDeleteMediaModalIsOpen: boolean
  messageClassifiedModalIsOpen: boolean
  validateUserEmailModalIsOpen: boolean
}

const initialState: ModalsState = {
  addMediaModalIsOpen: false,
  updateMediaModalIsOpen: false,
  archiveAdsModalIsOpen: false,
  updateAlertModalIsOpen: false,
  answerClassifiedModalIsOpen: false,
  answerClassifiedFormatedModalIsOpen: false,
  loginModalIsOpen: false,
  confirmDeleteMediaModalIsOpen: false,
  messageClassifiedModalIsOpen: false,
  validateUserEmailModalIsOpen: false,
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    setAddMediaModalIsOpen: (state, action) => {
      state.addMediaModalIsOpen = action.payload
    },
    setUpdateMediaModalIsOpen: (state, action) => {
      state.updateMediaModalIsOpen = action.payload
    },
    setArchiveAdsModalIsOpen: (state, action) => {
      state.archiveAdsModalIsOpen = action.payload
    },
    setUpdateAlertModalIsOpen: (state, action) => {
      state.updateAlertModalIsOpen = action.payload
    },
    setAnswerClassifiedModalIsOpen: (state, action) => {
      state.answerClassifiedModalIsOpen = action.payload
    },
    setAnswerClassifiedFormatedModalIsOpen: (state, action) => {
      state.answerClassifiedFormatedModalIsOpen = action.payload
    },
    setLoginModalIsOpen: (state, action) => {
      state.loginModalIsOpen = action.payload
    },
    setConfirmDeleteMediaModalIsOpen: (state, action) => {
      state.confirmDeleteMediaModalIsOpen = action.payload
    },
    setMessageClassifiedModalIsOpen: (state, action) => {
      state.messageClassifiedModalIsOpen = action.payload
    },
    setValidateUserModalIsOpen: (state, action) => {
      state.validateUserEmailModalIsOpen = action.payload
    },
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[slice.name]

const addMediaModalIsOpen = (state: RootState) =>
  root(state).addMediaModalIsOpen
const updateMediaModalIsOpen = (state: RootState) =>
  root(state).updateMediaModalIsOpen
const archiveAdsModalIsOpen = (state: RootState) =>
  root(state).archiveAdsModalIsOpen
const updateAlertModalIsOpen = (state: RootState) =>
  root(state).updateAlertModalIsOpen
const answerClassifiedModalIsOpen = (state: RootState) =>
  root(state).answerClassifiedModalIsOpen
const answerClassifiedFormatedModalIsOpen = (state: RootState) =>
  root(state).answerClassifiedFormatedModalIsOpen
const loginModalIsOpen = (state: RootState) => root(state).loginModalIsOpen
const confirmDeleteMediaModalIsOpen = (state: RootState) =>
  root(state).confirmDeleteMediaModalIsOpen
const messageClassifiedModalIsOpen = (state: RootState) =>
  root(state).messageClassifiedModalIsOpen
const validateUserEmailModalIsOpen = (state: RootState) =>
  root(state).validateUserEmailModalIsOpen

export const selectors = {
  addMediaModalIsOpen,
  updateMediaModalIsOpen,
  archiveAdsModalIsOpen,
  updateAlertModalIsOpen,
  answerClassifiedModalIsOpen,
  answerClassifiedFormatedModalIsOpen,
  loginModalIsOpen,
  confirmDeleteMediaModalIsOpen,
  messageClassifiedModalIsOpen,
  validateUserEmailModalIsOpen,
}
