import { MediaType } from '../../../form/fields/FormFieldUploadMedia'
import type { ClassifiedSpecialty } from '../Classified'
import { NestedData } from '../Common'

import { SImageData } from './Image'
import { SUser } from './User'
import { SClassifiedView } from './ClassifiedView'

export type SClassified = {
  id: string
  attributes: {
    title?: string
    slug?: string
    description?: string
    keywords?: string
    summary?: string
    address?: string
    additionalAddress?: string
    block: Array<
      | SClassifiedAdoptBlock
      | SClassifiedAssistantBlock
      | SClassifiedBlockOperatoryBlock
      | SClassifiedCarabinBlock
      | SClassifiedCoordinatorBlock
      | SClassifiedEmployeeBlock
      | SClassifiedGardeBlock
      | SClassifiedInstallationBlock
      | SClassifiedLiberalBlock
      | SClassifiedOperatoryBlock
      | SClassifiedReplacementBlock
      | SClassifiedSecretaryBlock
    >
    emitter?: NestedData<SClassifiedEmitter>
    mainMediaType?: MediaType
    image?: SImageData
    videoUrl?: string
    publishedAt?: string
    archivedAt?: string
    updatedAt?: string
    classifiedCategory?: NestedData<SClassifiedCategory>
    classified_answers?: NestedData<Array<SClassifiedAnswer>>
    medias?: { data: Array<SClassifiedMedia> }
    user?: NestedData<SUser>
    lat?: number
    long?: number
    city?: string
    postalCode?: string
    department?: string
    classified_views?: NestedData<Array<SClassifiedView>>
  }
}

export type SClassifiedCategory = {
  id: string
  attributes: {
    name?: string
    slug?: string
    user_profiles?: Array<any>
    form?: string
    classifieds?: NestedData<Array<SClassified>>
    totalClassifieds?: number
  }
}

export type SClassifiedAnswer = {
  id: string
  attributes: {
    birthdate?: string
    city?: string
    civility: string
    classified: NestedData<SClassified>
    country?: string
    disponibilityEndTwo?: string
    disponibilityStart?: string
    disponibilityStartTwo?: string
    disponibillityEnd?: string
    document?: any
    email: string
    jobDuration?: string
    jobDurationCarabin?: string
    lastname: string
    message: string
    name: string
    phone: string
    postcode?: string
    schoolingLevel?: string
    street?: string
    studentCard?: any
    university?: string
    answer_user?: NestedData<SUser>
  }
}

export enum EmitterType {
  Doctor = 'Doctor',
  Organization = 'Organisation',
}

export type SClassifiedEmitter = {
  id: string
  attributes: {
    type: EmitterType
    presentation: string
    website: string
    recipients: Array<{ value: string }>
    logo: SImageData
    classifieds: NestedData<Array<SClassified>>
    name: string
    user: NestedData<SUser>
  }
}

export type SClassifiedMedia = {
  id: string
  attributes: {
    type: string
    title: string
    description: string
    videoUrl: string
    photo: SImageData
    document: any
  }
}

export type SClassifiedOfferType = {
  id: string
  attributes: {
    name: string
  }
}

export type SClassifiedSpecialty = {
  id: string
  attributes: {
    name: string
  }
}

export type SClassifiedCabinetType = {
  id: string
  attributes: {
    name: string
  }
}

export type SClassifiedSecretariat = {
  id: string
  attributes: {
    name: string
  }
}

export type SClassifiedAdoptBlock = {
  id: string
  speciality: SClassifiedSpecialty
  patronages: number
}

export type SClassifiedAssistantBlock = {
  id: string
  cabinetType: SClassifiedCabinetType
  speciality: SClassifiedSpecialty
  saturday: boolean
  gestures: string
}

export type SClassifiedBlockOperatoryBlock = {
  id: string
  establishmentName: string
  group: Array<string>
  speciality: SClassifiedSpecialty
  description: string
  operatoryHelpProvided: boolean
  toolsProvided: boolean
  treatmentType: string
  establishmentApproved: boolean
  availabilities: string
}

export type SClassifiedCarabinBlock = {
  id: string
  startDate: string
  endDate: string
  speciality: SClassifiedSpecialty
  cabinetType: SClassifiedCabinetType
}

export type SClassifiedCoordinatorBlock = {
  id: string
  cabinetType: SClassifiedCabinetType
  saturday: boolean
  experience: boolean
}

export type SClassifiedEmployeeBlock = {
  id: string
  job: string
  contract: string
  startDate: string
  endDate: string
}

export type SClassifiedGardeBlock = {
  id: string
  speciality: SClassifiedSpecialty
  doctors: string
  departments: string
  periods: string
  type: string
}

export type SClassifiedInstallationBlock = {
  id: string
  cabinetType: SClassifiedCabinetType
  specialities: SClassifiedSpecialty
  secretariats: SClassifiedSecretariat
  offerType: SClassifiedOfferType
  startDate: string
}

export type SClassifiedLiberalBlock = {
  id: string
  speciality: SClassifiedSpecialty
  cabinetType: SClassifiedCabinetType
  frequencies: string
  periodMonths: string
  periodDays: Array<any>
  comments: string
}

export type SClassifiedOperatoryBlock = {
  id: string
  speciality: ClassifiedSpecialty
  days: Array<any>
  frequency: string
}

export type SClassifiedReplacementBlock = {
  id: string
  startDate: string
  endDate: string
  period: Array<any>
  negociableDays: boolean
  sector: Array<string>
  softwares: string
  rate: number
  collabReplacement: boolean
  cabinetType: SClassifiedCabinetType
  guard: boolean
  specialities: SClassifiedSpecialty
}

export type SClassifiedSecretaryBlock = {
  id: string
  saturday: boolean
  experience: boolean
  cabinetType: SClassifiedCabinetType
  speciality: SClassifiedSpecialty
}

export type SClassifiedAlert = {
  id: string
  attributes: {
    user?: NestedData<any>
    terms?: string
    localisation?: string
    specialities?: NestedData<Array<SClassifiedSpecialty>>
    categories?: NestedData<Array<SClassifiedCategory>>
  }
}
