import qs from 'qs'

import { putRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'
import { SimpleResult } from '../../types/PaginatedResult'
import { SClassified } from '../../types/api/Classified'
import { classifiedTransformer } from '../../transformers/classifiedTransformer'
import { Status } from '../../types/Classified'

type PublishResponse = SimpleResult<SClassified>

export type PublishParams = {
  id: string
  populate?: any
}

const publish = ({ id, populate }: PublishParams) => {
  const formData = new FormData()

  formData.append(
    'data',
    JSON.stringify({ status: Status.Published, publishedAt: new Date() })
  )

  return putRestRequest({
    url:
      api.REST_API_URL +
      `/classifieds/${id}?${qs.stringify(
        {
          ...populate,
        },
        { encodeValuesOnly: true }
      )}`,
    data: formData,
    transformer: (response: PublishResponse) => {
      return classifiedTransformer(response.data)
    },
  })
}

export default publish
