import styled from 'styled-components'

import Link from '../Link'
import Icon from '../Icon'

export enum AdsCardVariant {
  primary = 'primary',
  secondary = 'secondary',
}

export const AdsCard = styled.div<{ $variant?: AdsCardVariant }>`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.greyContour};
  padding: 2.2rem 2rem 1.6rem 2rem;
  background-color: ${({ theme, $variant }) =>
    !$variant || $variant === AdsCardVariant.primary
      ? theme.colors.pureWhite
      : $variant === AdsCardVariant.secondary
      ? theme.colors.blueBack
      : theme.colors.blueBack};
`

export const Title = styled.h4`
  ${({ theme }) => theme.textStyles.firaSansXlBold};
  margin: 0;
`

export const TextSeparator = styled.div<{ $variant?: AdsCardVariant }>`
  width: 100%;
  height: 0.1rem;
  margin: 1.5rem 0;
  background-color: ${({ theme, $variant }) =>
    !$variant || $variant === AdsCardVariant.primary
      ? theme.colors.blueBack
      : $variant === AdsCardVariant.secondary
      ? theme.colors.greyContour
      : theme.colors.greyContour};
`

export const PublicationDate = styled.p<{ $variant?: AdsCardVariant }>`
  ${({ theme }) => theme.textStyles.montserratSm};
  color: ${({ theme }) => theme.colors.taupeGray};
`

export const Description = styled.p<{ $variant?: AdsCardVariant }>`
  ${({ theme }) => theme.textStyles.montserratTextBase};
`

export const Localization = styled.p`
  ${({ theme }) => theme.textStyles.montserratTextBase};
`

export const OfferType = styled.p`
  ${({ theme }) => theme.textStyles.montserratTextBase};
  margin-bottom: 2rem;
`

export const Wrapper = styled.div<{ $description?: string }>`
  display: flex;
  margin-top: auto;
  flex-wrap: wrap;
  gap: 1rem;
`

export const IconSeparator = styled.div`
  width: 0.1rem;
  height: 1.2rem;
  background-color: ${({ theme }) => theme.colors.greyFields};
`

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
  gap: 1rem;
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3rem;
`

export const IconEye = styled(Icon)``

export const AdViewsNumber = styled.span`
  ${({ theme }) => theme.textStyles.montserratSm};
`

export const IconComment = styled(Icon)``

export const AdCommentNumber = styled.span`
  ${({ theme }) => theme.textStyles.montserratSm};
`

export const Lk = styled(Link)`
  color: ${({ theme }) => theme.colors.pinkButton};
  ${({ theme }) => theme.textStyles.montserratTextBaseBold};
  display: flex;
  align-items: center;
  gap: 0.9rem;

  &:hover {
    svg {
      transform: translateX(5px);
    }
  }
`

export const IconArrowRight = styled(Icon)`
  color: ${({ theme }) => theme.colors.pinkButton};
  transform: translateX(0);
  transition: transform 320ms ease-in-out;
`
