import { all, call, put, select, takeLeading } from 'redux-saga/effects'
import type PubNub from 'pubnub'

import { actions, selectors } from '..'
import { User } from '../../rest/types/User'
import {
  generateChannelID,
  getAllUsersMetadata,
  getMembershipToChannel,
  getPubNubInstance,
  getUuid,
  setMembershipToChannel,
  setUserMetadata,
} from '../../helpers/PubNubHelpers'

export default class ChatSagas {
  static *init() {
    const user: User = yield select(selectors.auth.user)
    const pubnub: PubNub = yield call(getPubNubInstance, getUuid(user))

    if (pubnub && user) {
      const memberships: PubNub.ManageMembershipsResponse<
        PubNub.ObjectCustom,
        PubNub.ObjectCustom
      > | null = yield call(getMembershipToChannel, pubnub, getUuid(user))

      if (memberships) {
        yield put(actions.chat.setMemberships(memberships.data))
        yield call(setUserMetadata, pubnub, user)
        const usersMetadata: PubNub.GetAllUUIDMetadataResponse<PubNub.ObjectCustom> =
          yield call(getAllUsersMetadata, pubnub)

        if (usersMetadata) {
          yield put(actions.chat.setUsersMetadata(usersMetadata.data))
        }
      }
    }
  }

  static *createNewChannel({
    payload,
  }: ReturnType<typeof actions.chat.createNewChannel>) {
    const user: User = yield select(selectors.auth.user)
    const pubnub: PubNub = yield call(getPubNubInstance, getUuid(user))

    const channelID = generateChannelID(
      user,
      payload.classifiedAuthor?.user as User,
      payload.response as string
    )

    yield call(
      setMembershipToChannel,
      pubnub,
      getUuid(payload.classifiedAuthor?.user),
      channelID
    ) // subscribe selected user to the new channel
    yield call(setMembershipToChannel, pubnub, getUuid(user), channelID) // subscribe current user to the new channel

    yield put(actions.chat.setCurrentChannel(channelID))
    yield put(actions.chat.sendFirstMessage(payload))
  }

  static *sendFirstMessage({
    payload,
  }: ReturnType<typeof actions.chat.sendFirstMessage>) {
    const user: User = yield select(selectors.auth.user)
    const pubnub: PubNub = yield call(getPubNubInstance, getUuid(user))
    const currentChannel: string = yield select(selectors.chat.currentChannel)

    pubnub.publish({
      message: { text: payload.message },
      channel: currentChannel,
    })
  }

  static *refreshUnreadCount() {
    const user: User = yield select(selectors.auth.user)
    const uuid = getUuid(user)
    const pubnub = getPubNubInstance(uuid)

    if (uuid && pubnub) {
      const userMembership: PubNub.ManageMembershipsResponse<
        PubNub.ObjectCustom,
        PubNub.ObjectCustom
      > | null = yield call(getMembershipToChannel, pubnub, uuid)

      if (!userMembership) {
        return
      }

      const channels: string[] = []
      const channelTimetokens: string[] = []

      if (userMembership?.data) {
        userMembership.data.forEach((entry: any) => {
          if (entry?.channel?.id && entry?.custom?.lastReadTimetoken) {
            channels.push(entry?.channel?.id)
            channelTimetokens.push(entry?.custom?.lastReadTimetoken)
          }
        })

        if (channels.length > 0 && pubnub) {
          try {
            const data: PubNub.MessageCountsResponse = yield call(
              pubnub.messageCounts,
              {
                channels,
                channelTimetokens,
              }
            )
            if (data?.channels) {
              yield put(actions.chat.setUnreadMessagesCount(data.channels))
            }
          } catch (error) {
            console.log(error)
          }
        }
      }
    }
  }

  static *loop() {
    yield all([
      //
      takeLeading(actions.chat.init, this.init),
      takeLeading(actions.chat.createNewChannel, this.createNewChannel),
      takeLeading(actions.chat.sendFirstMessage, this.sendFirstMessage),
      takeLeading(actions.chat.refreshUnreadCount, this.refreshUnreadCount),
    ])
  }
}
