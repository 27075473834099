import { postRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'

export type SummarizeDescriptionParams = {
  description: string
}

const summarizeDescription = (data: SummarizeDescriptionParams) =>
  postRestRequest({
    url: api.REST_API_URL + `/classified/summarize-description`,
    data,
    transformer: (response) => {
      return response
    },
  })

export default summarizeDescription
