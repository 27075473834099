import { FC, InputHTMLAttributes } from 'react'
import { getIn, useFormikContext } from 'formik'

import { FormLabelProps } from '../../FormLabel'
import FormikField from '../../FormikField'

import * as SC from './styled'

export type FormFieldRadioGroupProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'value'
> & {
  className?: string
  label?: string
  labelProps?: FormLabelProps
  name: string
  value?: string
  text?: string
  options: {
    label: string
    value: string | boolean
    disabled?: boolean
  }[]
  required?: boolean
  disabled?: boolean
  help?: string
  hasError?: boolean
  direction?: 'row' | 'column'
  hideError?: boolean
}

const FormFieldRadioGroup: FC<FormFieldRadioGroupProps> = ({
  className,
  name,
  label,
  text,
  labelProps,
  options,
  required,
  disabled,
  help,
  direction = 'row',
  hideError = false,
  ...inputProps
}) => {
  const { errors } = useFormikContext()
  const error = getIn(errors, name)

  return (
    <SC.Container className={className} $isDisabled={disabled}>
      {label && (
        <SC.Label required={required} underlined {...labelProps}>
          {label}
        </SC.Label>
      )}
      {text && <SC.Text>{text}</SC.Text>}
      <SC.Options $direction={direction}>
        {options?.map((option, idx) => (
          <SC.Option
            key={idx}
            $isOptionDisabled={option.disabled}
            $isInputDisabled={disabled}
          >
            <FormikField
              Component={SC.OptionRadio}
              {...inputProps}
              type="radio"
              name={name}
              value={option.value}
              disabled={disabled || option.disabled}
              checked={option.value === inputProps.value ? true : false}
            />
            {option.label}
          </SC.Option>
        ))}
      </SC.Options>
      {!error && help && <SC.Help>{help}</SC.Help>}
      {error && !hideError && <SC.Error>{error}</SC.Error>}
    </SC.Container>
  )
}

export default FormFieldRadioGroup
