import { all, put, takeEvery } from 'redux-saga/effects'

import ApiSagas from '../api/sagas'
import { actions } from '../index'
import getAddress from '../../rest/requests/geo/getAddress'

export default class GeoSagas {
  static *onGetAddressesRequest({
    payload,
  }: ReturnType<typeof actions.geo.getAddressesRequest>) {
    const response = yield* ApiSagas.rest(getAddress(payload), undefined, false)

    if (response.data) {
      yield put(actions.geo.getAddressesSuccess(response.data))
    } else {
      yield put(actions.geo.getAddressesError(response.errors))
    }
  }
  static *loop() {
    yield all([
      takeEvery(actions.geo.getAddressesRequest, this.onGetAddressesRequest),
    ])
  }
}
