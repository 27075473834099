export type RouteDefinition = {
  component: string
  path: string
  as?: string
  name: string
  isCustom?: boolean
}

const routes = {
  category: {
    component: '/category/[slug]',
    path: '/category/:slug',
    name: 'category',
  },
  retailer: {
    component: '/retailer/[slug]',
    path: '/retailer/:slug',
    name: 'retailer',
  },
  brand: {
    component: '/brand/[slug]',
    path: '/brand/:slug',
    name: 'brand',
  },
  offer: {
    component: '/offer/[slug]',
    path: '/offre/:slug',
    name: 'offer',
  },
  account: {
    component: '/compte',
    path: '/compte',
    name: 'compte',
  },
  reservations: {
    component: '/compte/reservations',
    path: '/compte/reservations',
    name: 'reservations',
  },
  invoices: {
    component: '/compte/invoices',
    path: '/compte/invoices',
    name: 'invoices',
  },
  subscription: {
    component: '/compte/subscription',
    path: '/compte/subscription',
    name: 'subscription',
  },
  classifieds: {
    component: '/compte/annonce/index',
    path: '/compte/annonce',
    name: 'classifieds',
  },
  alerts: {
    component: '/compte/alerte/index',
    path: '/compte/alerte',
    name: 'alerts',
  },
  answers: {
    component: '/compte/answers/index',
    path: '/compte/answers',
    name: 'answers',
  },
  messages: {
    component: '/compte/message/index',
    path: '/compte/message',
    name: 'messages',
  },
  classifiedCreateOrUpdate: {
    component: '/compte/annonce/[id]/index.tsx',
    path: '/compte/annonce/:id',
    name: 'classifiedCreateOrUpdate',
  },
  preview: {
    component: '/compte/annonce/[id]/preview.tsx',
    path: '/compte/annonce/:id/preview',
    name: 'preview',
  },
  search: {
    component: '/search/[search]',
    path: '/search/:search',
    name: 'search',
  },
  loginOrRegister: {
    component: '/auth/connexion',
    path: '/auth/connexion',
    name: 'loginOrRegister',
  },
  forgottenPassword: {
    component: '/auth/forgotten-password',
    path: '/auth/forgotten-password',
    name: 'forgottenPassword',
  },
  resetPassword: {
    component: '/auth/reset-password',
    path: '/auth/reset-password',
    name: 'resetPassword',
  },
  home: {
    component: '/',
    path: '/',
    name: 'home',
  },
  page: {
    component: '/[...slug]',
    path: '/:slug+',
    name: 'page',
  },
  classified: {
    component: '/[slug]',
    path: '/annonce/:slug',
    name: 'annonce',
  },
  classifiedsEmitter: {
    component: '/[id]',
    path: '/classifieds-emitter/:id',
    name: 'classifiedsEmitter',
  },
  classifiedsEmitterPaginated: {
    component: '/[id]/[page]',
    path: '/classifieds-emitter/:id/:page',
    name: 'classifiedsEmitterPaginated',
  },
  classifiedSearch: {
    component: '/annonce/recherche',
    path: '/annonce/recherche',
    name: 'classifiedSearch',
  },
  classifiedDepartmentSearch: {
    component: '/annonce/departement',
    path: '/annonce/departement',
    name: 'classifiedDepartmentSearch',
  },
} as const

export default routes
