import React, { FC, useEffect, useState } from 'react'
import { getIn, useFormikContext } from 'formik'
import { useTheme } from 'styled-components'
import { ImageProps } from 'next/image'

import { VideoProps } from '../../../components/Video'
import { FormFieldRadioGroupProps } from '../FormFieldRadioGroup'
import { FormFieldInputProps } from '../FormFieldInput'
import { FormFieldFileProps } from '../FormFieldFile'
import Icon, { IconProps, Icons } from '../../../components/Icon'
import FormikField from '../../FormikField'

import * as SC from './styled'
import { FormFieldUploadMediaVariant } from './styled'

export enum MediaType {
  Video = 'video',
  Photo = 'photo',
  Document = 'document',
}

export type UploadMediaValues = {
  type: MediaType
  video: string | null
  photo: File | null
  document: File | null
}

export type FormFieldUploadMediaProps = {
  className?: string
  radios: Omit<FormFieldRadioGroupProps, 'name' | 'options'>
  video?: Omit<FormFieldInputProps, 'name'>
  photo?: Omit<FormFieldFileProps, 'name'>
  document?: Omit<FormFieldFileProps, 'name' | 'leftAdornment'>
  preview?: ImageProps
  previewVideo?: VideoProps
  onDeletePhoto?: () => void
  name: string
  labels: {
    video: string
    photo: string
    document?: string
  }
  trashIcon?: IconProps
  variant?: FormFieldUploadMediaVariant
}

const FormFieldUploadMedia: FC<FormFieldUploadMediaProps> = ({
  className,
  radios,
  video,
  photo,
  document,
  preview,
  previewVideo,
  name,
  labels,
  onDeletePhoto,
  variant = FormFieldUploadMediaVariant.primary,
}) => {
  const { values, errors, isSubmitting } = useFormikContext()
  const [displayErrors, setDisplayErrors] = useState<boolean>(false)
  const type = getIn(values, `${name}.type`)
  const mediaError = getIn(errors, name)
  const theme = useTheme()

  useEffect(() => {
    if (type && isSubmitting) {
      setDisplayErrors(true)
    }
  }, [isSubmitting, type])

  useEffect(() => {
    setDisplayErrors(false)
  }, [type])

  return (
    <SC.Container className={className}>
      <SC.Wrapper>
        <SC.Left>
          <SC.Radios
            {...radios}
            name={`${name}.type`}
            hideError
            $variant={variant}
            options={[
              ...(video
                ? [{ label: labels.video, value: MediaType.Video }]
                : []),
              ...(photo
                ? [{ label: labels.photo, value: MediaType.Photo }]
                : []),
              ...(document && labels.document
                ? [{ label: labels.document, value: MediaType.Document }]
                : []),
            ]}
          />
          {values && type === MediaType.Photo ? (
            <FormikField
              Component={SC.PhotoField}
              {...photo}
              name={`${name}.photo`}
              hideError
            />
          ) : values && type === MediaType.Document ? (
            <FormikField
              Component={SC.DocumentField}
              {...document}
              name={`${name}.document`}
              hideError
            />
          ) : values && type === MediaType.Video ? (
            <FormikField
              Component={SC.VideoField}
              {...video}
              name={`${name}.video`}
              hideError
            />
          ) : (
            <></>
          )}
        </SC.Left>
        <SC.Right>
          {onDeletePhoto && (
            <SC.TrashIcon
              icon={Icons.delete}
              width={18}
              height={18}
              color={theme.colors.bluePrimary}
              onClick={onDeletePhoto}
            />
          )}
          {values && type === MediaType.Photo ? (
            <SC.PhotoPoster>
              {preview ? (
                <SC.Preview {...preview} />
              ) : (
                <Icon icon={Icons.upload} width={33} height={34} />
              )}
            </SC.PhotoPoster>
          ) : values && type === MediaType.Document ? (
            <SC.DocumentPoster>
              <Icon icon={Icons.docUpload} width={33} height={34} />
            </SC.DocumentPoster>
          ) : values && type === MediaType.Video ? (
            <SC.VideoPoster>
              {previewVideo ? (
                <SC.PreviewVideo {...previewVideo} />
              ) : (
                <Icon icon={Icons.videoPosterPlay} width={63} height={35} />
              )}
            </SC.VideoPoster>
          ) : (
            <SC.DefaultPoster className={'defaultPoster'} $variant={variant} />
          )}
        </SC.Right>
      </SC.Wrapper>
      {mediaError && mediaError?.[type] && displayErrors && (
        <SC.ErrorMessage>{mediaError?.[type]}</SC.ErrorMessage>
      )}
      {mediaError && mediaError?.type && (
        <SC.ErrorMessage>{mediaError?.type}</SC.ErrorMessage>
      )}
    </SC.Container>
  )
}

export default FormFieldUploadMedia
