import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../store'
import { getApiService } from '../../helpers/ReduxHelpers'
import * as example from '../../graphql/services/example'

//
// requests
//

export const getUserByIdService = getApiService(
  'getUserById',
  example.queries.userById
)
export const updateUserService = getApiService(
  'updateUser',
  example.mutations.updateUser
)

//
// Initial state
//

type ExampleState = {
  getUserById: typeof getUserByIdService.state
  updateUser: typeof updateUserService.state
}

const initialState: ExampleState = {
  getUserById: getUserByIdService.state,
  updateUser: updateUserService.state,
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: 'example',
  initialState,
  reducers: {
    ...getUserByIdService.reducers,
    ...updateUserService.reducers,
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[slice.name]
const getUserById = (state: RootState) => root(state).getUserById
const updateUser = (state: RootState) => root(state).updateUser

export const selectors = {
  getUserById,
  updateUser,
}
