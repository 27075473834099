import styled, { css } from 'styled-components'

export const Label = styled.label<{ $error?: boolean; $underlined?: boolean }>`
  ${(props) => props.theme.textStyles.montserratSm};
  color: ${({ theme }) => theme.colors.bluePrimary};
  margin-bottom: 0.5rem;
  display: inline-block;
  position: relative;
  align-self: flex-start;

  /* &[required]:after {
    content: ' *';
    position: absolute;
    top: 0;
    left: calc(100% + 5px);
    color: ${(props) => props.theme.colors.error};
  } */

  ${(props) =>
    props.$error &&
    css`
      color: ${(props) => props.theme.colors.error};
    `}

  ${({ $underlined }) =>
    $underlined &&
    css`
      ${(props) => props.theme.textStyles.firaSansLg};
      margin-bottom: 2.2rem;
    `};
`

export const Line = styled.span`
  display: block;
  width: 2.5rem;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.bluePrimary};
`
