import React, { FC, InputHTMLAttributes, useId, useState } from 'react'
import cx from 'classnames'

import { FormLabelProps } from '../../FormLabel'
import {
  FormFieldOrientation,
  FormFieldOrientationValues,
} from '../../../types/form'

import * as SC from './styled'

export type FormFieldInputProps = InputHTMLAttributes<HTMLInputElement> & {
  name?: string
  className?: string
  error?: string
  hasError?: boolean
  helpText?: string
  innerHelpText?: string
  innerContent?: React.ReactNode
  innerError?: boolean
  errors?: { message?: string }[]
  label?: string
  required?: boolean
  leftAdornment?: React.ReactElement
  rightAdornment?: React.ReactElement
  InputComponent?: FC<any>
  inputProps?: any
  inputRef?: any
  labelProps?: FormLabelProps
  htmlFor?: string
  maxLength?: number
  active?: boolean
  size?: SC.InputSize
  onRef?: (ref: any) => void
  orientation?: FormFieldOrientationValues
  hideError?: boolean
  [key: string]: any
}

const FormFieldInputRenderer = React.forwardRef<any, FormFieldInputProps>(
  function FormFieldInputRenderer(
    {
      className,
      name,
      id,
      error,
      hasError,
      placeholder,
      onChange,
      label,
      required,
      helpText,
      innerHelpText,
      innerContent,
      innerError,
      leftAdornment,
      rightAdornment,
      InputComponent,
      inputProps,
      labelProps,
      value,
      inputRef,
      onRef,
      htmlFor,
      active,
      maxLength,
      orientation = FormFieldOrientation.Vertical,
      size = SC.InputSize.Small,
      hideError = false,
      ...extraProps
    },
    ref
  ) {
    const localId = useId()
    const [isFocused, setFocused] = useState(false)
    const isActive = active || isFocused || !!value
    const hasLabel = !!label

    const CustomInput: FC<any> = InputComponent ? InputComponent : SC.Input

    return (
      <SC.Container
        className={cx('FormFieldInput', className)}
        $orientation={orientation}
      >
        {label && (
          <SC.Label
            required={required}
            $active={isActive}
            htmlFor={htmlFor ?? localId}
            {...labelProps}
          >
            {label}
          </SC.Label>
        )}
        <SC.MainWrapper>
          <SC.Wrapper
            $isError={!!error || hasError}
            $isActive={isActive}
            $isDisabled={extraProps.disabled}
            $hasLabel={hasLabel}
            $size={size}
            $type={extraProps.type}
            placeholder={placeholder}
          >
            <SC.InputContainer>
              {leftAdornment}
              <CustomInput
                {...inputProps}
                {...extraProps}
                {...((onRef || inputRef) && {
                  ref: (ref: any) => {
                    if (inputRef?.current) {
                      inputRef.current = ref
                    }

                    if (onRef) {
                      onRef(ref)
                    }
                  },
                })}
                {...(ref && { ref: ref })}
                id={id ?? localId}
                name={name}
                type={extraProps.type ?? 'text'}
                placeholder={placeholder}
                value={value}
                $isError={!!error || hasError}
                $isActive={isActive}
                $hasLabel={hasLabel}
                $isDisabled={extraProps.disabled}
                maxLength={maxLength}
                $size={size}
                onChange={(e: any) => {
                  onChange?.(e)
                  inputProps?.onChange?.(e)
                }}
                onFocus={(e: any) => {
                  setFocused(true)
                  extraProps?.onFocus?.(e)
                  inputProps?.onFocus?.(e)
                }}
                onBlur={(e: any) => {
                  extraProps?.onBlur?.(e)
                  inputProps?.onBlur?.(e)
                  setFocused(false)
                }}
              />
              {rightAdornment}
            </SC.InputContainer>
          </SC.Wrapper>
          {innerContent}
          {innerHelpText && (
            <SC.InnerHelpMessage>{innerHelpText}</SC.InnerHelpMessage>
          )}
          {error && innerError && <SC.ErrorMessage>{error}</SC.ErrorMessage>}
        </SC.MainWrapper>
        {!error && helpText && <SC.HelpMessage>{helpText}</SC.HelpMessage>}
        {error && !hideError && !innerError && (
          <SC.ErrorMessage>{error}</SC.ErrorMessage>
        )}
      </SC.Container>
    )
  }
)

const FormFieldInput = FormFieldInputRenderer

export default FormFieldInput
