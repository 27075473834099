import { createSelector, createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../store'
import { getCustomService } from '../../helpers/ReduxHelpers'
import {
  Classified,
  ClassifiedCabinetType,
  ClassifiedCategory,
  ClassifiedEmitter,
  ClassifiedMedia,
  ClassifiedOfferType,
  ClassifiedSecretariat,
  ClassifiedSpecialty,
  Status,
} from '../../rest/types/Classified'
import { CreateParams } from '../../rest/requests/classifieds/create'
import { GetByUserIdParams } from '../../rest/requests/classifieds/getByUserId'
import { AdsFormValues } from '../../forms/AdsForm'
import { GetClassifiedByIdParams } from '../../rest/requests/classifieds/classifiedById'
import { getBlockSchemaParams } from '../../rest/requests/classifieds/getBlockSchema'
import { GetMediaListParams } from '../../rest/requests/classifieds/getMediaList'
import { DeleteMediaParams } from '../../rest/requests/classifieds/deleteMedia'
import { getCategoriesByUserProfileParams } from '../../rest/requests/classifieds/getCategoriesByUserProfile'
import { getClassifiedsParams } from '../../rest/requests/classifieds/getClassifieds'
import { PublishParams } from '../../rest/requests/classifieds/publish'
import { getLatestClassifiedsByCategoryParams } from '../../rest/requests/classifieds/getLatestClassifiedsByCategory'
import { ArchiveParams } from '../../rest/requests/classifieds/archive'
import { GetContentTypeSchemaParams } from '../../rest/requests/content-types-builder/getContentTypeSchema'
import { GeoAddress } from '../../rest/types/GeoAddress'
import { IncreaseViewParams } from '../../rest/requests/classifieds/increaseView'
import { getSpecialtiesParams } from '../../rest/requests/classifieds/getSpecialties'
import { getOfferTypesParams } from '../../rest/requests/classifieds/getOfferTypes'
import { getCabinetTypesParams } from '../../rest/requests/classifieds/getCabinetTypes'
import { getSecretariatsParams } from '../../rest/requests/classifieds/getSecretariats'
import { DataWithMeta } from '../../rest/types/Common'

import { AddMediaToGalleryParams, UpdateMediaParams } from './actions'

//
// Services
//

export const createService = getCustomService<
  'create',
  Classified,
  CreateParams
>('create')

export const updateService = getCustomService<
  'update',
  Classified,
  AdsFormValues & { status?: Status }
>('update')

export const publishService = getCustomService<
  'publish',
  Classified,
  PublishParams
>('publish')

export const archiveService = getCustomService<
  'archive',
  Classified,
  ArchiveParams
>('archive')

export const addMediaToGalleryService = getCustomService<
  'addMediaToGallery',
  boolean,
  AddMediaToGalleryParams
>('addMediaToGallery')

export const getMediaListService = getCustomService<
  'getMediaList',
  Array<ClassifiedMedia>,
  GetMediaListParams
>('getMediaList')

export const updateMediaService = getCustomService<
  'updateMedia',
  boolean,
  UpdateMediaParams
>('updateMedia')

export const deleteMediaService = getCustomService<
  'deleteMedia',
  boolean,
  DeleteMediaParams
>('deleteMedia')

export const getClassifiedByIdService = getCustomService<
  'getClassifiedById',
  Classified,
  GetClassifiedByIdParams
>('getClassifiedById')

export const getClassifiedsService = getCustomService<
  'getClassifieds',
  Array<Classified>,
  getClassifiedsParams
>('getClassifieds')
export const getClassifiedsWithMetaService = getCustomService<
  'getClassifiedsWithMeta',
  DataWithMeta<Array<Classified>>,
  getClassifiedsParams
>('getClassifiedsWithMeta')
export const getPublishAdsService = getCustomService<
  'getPublishAds',
  Array<Classified>,
  getClassifiedsParams
>('getPublishAds')

export const getLatestClassifiedsByCategoryService = getCustomService<
  'getLatestClassifiedsByCategory',
  Array<Classified>,
  getLatestClassifiedsByCategoryParams
>('getLatestClassifiedsByCategory')

export const getUserClassifiedsService = getCustomService<
  'getUserClassifieds',
  Array<Classified>,
  GetByUserIdParams
>('getUserClassifieds')

export const getCategoriesService = getCustomService<
  'getCategories',
  Array<ClassifiedCategory>,
  null
>('getCategories')

export const getCategoriesByUserProfileService = getCustomService<
  'getCategoriesByUserProfile',
  Array<ClassifiedCategory>,
  getCategoriesByUserProfileParams
>('getCategoriesByUserProfile')

export const getOfferTypesService = getCustomService<
  'getOfferTypes',
  Array<ClassifiedOfferType>,
  getOfferTypesParams
>('getOfferTypes')

export const getSpecialtiesService = getCustomService<
  'getSpecialties',
  Array<ClassifiedSpecialty>,
  getSpecialtiesParams
>('getSpecialties')

export const getSecretariatsService = getCustomService<
  'getSecretariats',
  Array<ClassifiedSecretariat>,
  getSecretariatsParams
>('getSecretariats')

export const getEmittersService = getCustomService<
  'getEmitters',
  Array<ClassifiedEmitter>,
  null
>('getEmitters')

export const increaseViewService = getCustomService<
  'increaseView',
  boolean,
  IncreaseViewParams
>('increaseView')

export const getCabinetTypesService = getCustomService<
  'getCabinetTypes',
  Array<ClassifiedCabinetType>,
  getCabinetTypesParams
>('getCabinetTypes')

export const getClassifiedSchemaService = getCustomService<
  'getClassifiedSchema',
  any,
  GetContentTypeSchemaParams
>('getClassifiedSchema')

export const getBlockSchemaService = getCustomService<
  'getBlockSchema',
  any,
  getBlockSchemaParams
>('getBlockSchema')

//
// Initial state
//

export type FeedbacksState = {
  // Services
  create: typeof createService.state
  update: typeof updateService.state
  publish: typeof publishService.state
  archive: typeof archiveService.state
  addMediaToGallery: typeof addMediaToGalleryService.state
  getMediaList: typeof getMediaListService.state
  updateMedia: typeof updateMediaService.state
  deleteMedia: typeof deleteMediaService.state
  getClassifiedById: typeof getClassifiedByIdService.state
  getClassifieds: typeof getClassifiedsService.state
  getClassifiedsWithMeta: typeof getClassifiedsWithMetaService.state
  getPublishAds: typeof getPublishAdsService.state
  getLatestClassifiedsByCategory: typeof getLatestClassifiedsByCategoryService.state
  getUserClassifieds: typeof getUserClassifiedsService.state
  getCategories: typeof getCategoriesService.state
  getCategoriesByUserProfile: typeof getCategoriesByUserProfileService.state
  getCabinetTypes: typeof getCabinetTypesService.state
  getEmitters: typeof getEmittersService.state
  getOfferTypes: typeof getOfferTypesService.state
  getSpecialties: typeof getSpecialtiesService.state
  getSecretariats: typeof getSecretariatsService.state
  getBlockSchema: typeof getBlockSchemaService.state
  getClassifiedSchema: typeof getClassifiedSchemaService.state
  increaseView: typeof increaseViewService.state
  // Customs
  updatedMedia: ClassifiedMedia | null
  currentArchivedId: string | number | null
  selectedAddress: GeoAddress | null
  currentMediaListItemId: string | number | null
  clearInputMedia: boolean
  currentClassifiedSlug: string | null
}

const initialState: FeedbacksState = {
  // Services
  create: createService.state,
  update: updateService.state,
  publish: publishService.state,
  archive: archiveService.state,
  addMediaToGallery: addMediaToGalleryService.state,
  updateMedia: updateMediaService.state,
  deleteMedia: deleteMediaService.state,
  getMediaList: getMediaListService.state,
  getClassifiedById: getClassifiedByIdService.state,
  getClassifieds: getClassifiedsService.state,
  getClassifiedsWithMeta: getClassifiedsWithMetaService.state,
  getPublishAds: getPublishAdsService.state,
  getLatestClassifiedsByCategory: getLatestClassifiedsByCategoryService.state,
  getUserClassifieds: getUserClassifiedsService.state,
  getCategories: getCategoriesService.state,
  getCategoriesByUserProfile: getCategoriesByUserProfileService.state,
  getCabinetTypes: getCabinetTypesService.state,
  getEmitters: getEmittersService.state,
  getOfferTypes: getOfferTypesService.state,
  getSpecialties: getSpecialtiesService.state,
  getSecretariats: getSecretariatsService.state,
  getBlockSchema: getBlockSchemaService.state,
  getClassifiedSchema: getClassifiedSchemaService.state,
  increaseView: increaseViewService.state,
  // Customs
  updatedMedia: null,
  currentArchivedId: null,
  selectedAddress: null,
  currentMediaListItemId: null,
  clearInputMedia: false,
  currentClassifiedSlug: null,
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: 'classifieds',
  initialState,
  reducers: {
    // Services
    ...createService.reducers,
    ...updateService.reducers,
    ...publishService.reducers,
    ...archiveService.reducers,
    ...updateMediaService.reducers,
    ...deleteMediaService.reducers,
    ...getMediaListService.reducers,
    ...addMediaToGalleryService.reducers,
    ...getClassifiedByIdService.reducers,
    ...getClassifiedsService.reducers,
    ...getClassifiedsWithMetaService.reducers,
    ...getPublishAdsService.reducers,
    ...getLatestClassifiedsByCategoryService.reducers,
    ...getUserClassifiedsService.reducers,
    ...getCategoriesService.reducers,
    ...getCategoriesByUserProfileService.reducers,
    ...getCabinetTypesService.reducers,
    ...getOfferTypesService.reducers,
    ...getEmittersService.reducers,
    ...getSecretariatsService.reducers,
    ...getSpecialtiesService.reducers,
    ...getBlockSchemaService.reducers,
    ...getClassifiedSchemaService.reducers,
    ...increaseViewService.reducers,
    // Custom
    setUpdatedMedia: (state, action) => {
      state.updatedMedia = action.payload
    },
    setCurrentArchivedId: (state, action) => {
      state.currentArchivedId = action.payload
    },
    setSelectedAddress: (state, action) => {
      state.selectedAddress = action.payload
    },
    setCurrentMediaListItemId: (state, action) => {
      state.currentMediaListItemId = action.payload
    },
    setClearInputMedia: (state, action) => {
      state.clearInputMedia = action.payload
    },
    setCurrentClassifiedSlug: (state, action) => {
      state.currentClassifiedSlug = action.payload
    },
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[slice.name]
const create = (state: RootState) => root(state).create
const update = (state: RootState) => root(state).update
const publish = (state: RootState) => root(state).publish
const archive = (state: RootState) => root(state).archive
const addMediaToGallery = (state: RootState) => root(state).addMediaToGallery
const getClassifiedById = (state: RootState) => root(state).getClassifiedById
const getClassifieds = (state: RootState) => root(state).getClassifieds
const getClassifiedsWithMeta = (state: RootState) =>
  root(state).getClassifiedsWithMeta
const getPublishAds = (state: RootState) => root(state).getPublishAds
const getLatestClassifiedsByCategory = (state: RootState) =>
  root(state).getLatestClassifiedsByCategory
const getUserClassifieds = (state: RootState) => root(state).getUserClassifieds
const getCategories = (state: RootState) => root(state).getCategories
const getCategoriesByUserProfile = (state: RootState) =>
  root(state).getCategoriesByUserProfile
const getCabinetTypes = (state: RootState) => root(state).getCabinetTypes
const getOfferTypes = (state: RootState) => root(state).getOfferTypes
const getEmitters = (state: RootState) => root(state).getEmitters
const getSecretariats = (state: RootState) => root(state).getSecretariats
const getSpecialties = (state: RootState) => root(state).getSpecialties
const getBlockSchema = (state: RootState) => root(state).getBlockSchema
const getClassifiedSchema = (state: RootState) =>
  root(state).getClassifiedSchema
const getMediaList = (state: RootState) => root(state).getMediaList
const deleteMedia = (state: RootState) => root(state).deleteMedia
const updateMedia = (state: RootState) => root(state).updateMedia
const currentArchivedId = (state: RootState) => root(state).currentArchivedId
const selectedAddress = (state: RootState) => root(state).selectedAddress
const increaseView = (state: RootState) => root(state).increaseView
const currentMediaListItemId = (state: RootState) =>
  root(state).currentMediaListItemId
const clearInputMedia = (state: RootState) => root(state).clearInputMedia
const currentClassifiedSlug = (state: RootState) =>
  root(state).currentClassifiedSlug

// Customs

const categories = createSelector([getCategories], (service) => service.data)
const offerTypes = createSelector([getOfferTypes], (service) => service.data)
const specialties = createSelector([getSpecialties], (service) => service.data)
const medias = createSelector([getMediaList], (service) => service.data)
const secretariats = createSelector(
  [getSecretariats],
  (service) => service.data
)
const cabinetTypes = createSelector(
  [getCabinetTypes],
  (service) => service.data
)

const updatedMedia = (state: RootState) => root(state).updatedMedia

const blockSchema = createSelector([getBlockSchema], (service) => service.data)

export const selectors = {
  // Services
  create,
  update,
  publish,
  archive,
  getClassifiedById,
  getClassifieds,
  getClassifiedsWithMeta,
  getPublishAds,
  getLatestClassifiedsByCategory,
  getUserClassifieds,
  getCategories,
  getCategoriesByUserProfile,
  getCabinetTypes,
  getOfferTypes,
  getEmitters,
  getSecretariats,
  getSpecialties,
  getBlockSchema,
  addMediaToGallery,
  getMediaList,
  deleteMedia,
  updateMedia,
  currentArchivedId,
  getClassifiedSchema,
  selectedAddress,
  increaseView,
  currentMediaListItemId,
  clearInputMedia,
  currentClassifiedSlug,

  // Customs
  categories,
  offerTypes,
  specialties,
  secretariats,
  cabinetTypes,
  blockSchema,
  medias,
  updatedMedia,
}
