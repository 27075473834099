import { Reservation } from '../types/Reservation'
import { SReservation } from '../types/api/Reservation'

import { offerTransformer } from './offerTransformer'
import { userTransformer } from './userTransformer'

export const reservationTransformer = (data: SReservation): Reservation => {
  return {
    ...data.attributes,
    id: data.id,
    user: data.attributes.user
      ? userTransformer(data.attributes.user.data)
      : undefined,
    offer: data.attributes.offer
      ? offerTransformer(data.attributes.offer.data)
      : undefined,
  }
}
