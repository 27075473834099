import qs from 'qs'

import { getRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'
import { PaginatedResult } from '../../types/PaginatedResult'
import { SClassified } from '../../types/api/Classified'
import { classifiedTransformer } from '../../transformers/classifiedTransformer'

type GetByUserIdResponse = PaginatedResult<SClassified>

export type GetByUserIdParams = {
  user: string
  [key: string]: any
}

const getByUser = ({ user, ...rest }: GetByUserIdParams) =>
  getRestRequest({
    url:
      api.REST_API_URL +
      `/classifieds?${qs.stringify({
        ...rest,
        filters: {
          user: user,
          ...rest?.filters,
        },
        fields: ['title', 'status', 'slug'],
        populate: {
          classifiedCategory: {
            populate: '*',
          },
        },
      })}`,
    transformer: (response: GetByUserIdResponse) => {
      return response.data.map((classified) =>
        classifiedTransformer(classified)
      )
    },
  })

export default getByUser
