export enum BlockCategory {
  Installation = 'installation',
  Replacement = 'replacement',
  Garde = 'garde',
  Liberal = 'liberal',
  Adopt = 'adopt',
  Job = 'carabin',
  ShareOperatingTheatre = 'block_operatory',
  Secretary = 'secretary',
  MedicalAssistant = 'assistant',
  Coordinator = 'coordinator',
  OperatingAid = 'operatory',
  PaidEmployment = 'employee',
}
