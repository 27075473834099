import { putRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'
import { SimpleResult } from '../../types/PaginatedResult'
import { SClassifiedAnswer } from '../../types/api/Classified'
import { ClassifiedAnswerTransformer } from '../../transformers/classifiedTransformer'
import { Status } from '../../types/Classified'

type ArchiveResponse = SimpleResult<SClassifiedAnswer>

export type ArchiveParams = {
  id: number
}

const archiveAnswer = ({ id }: ArchiveParams) => {
  const formData = new FormData()

  formData.append(
    'data',
    JSON.stringify({
      status: Status.Archived,
    })
  )

  return putRestRequest({
    url: api.REST_API_URL + `/classified-answers/${id}`,
    data: formData,
    transformer: (response: ArchiveResponse) => {
      return ClassifiedAnswerTransformer(response.data)
    },
  })
}

export default archiveAnswer
