import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../store'
import { getCustomService } from '../../helpers/ReduxHelpers'
import { SendYoungDoctorEmailParams } from '../../rest/requests/mail/sendYoungDoctorEmail'
import { SendProductFormParams } from '../../rest/requests/mail/sendProductFormEmail'

//
// requests
//

export const sendYoungDoctorEmailService = getCustomService<
  'sendYoungDoctorEmail',
  boolean,
  SendYoungDoctorEmailParams
>('sendYoungDoctorEmail')

export const sendProductFormEmailService = getCustomService<
  'sendProductFormEmail',
  boolean,
  SendProductFormParams
>('sendProductFormEmail')

//
// Initial state
//

type ReservationsState = {
  sendYoungDoctorEmail: typeof sendYoungDoctorEmailService.state
  sendProductFormEmail: typeof sendProductFormEmailService.state
}

const initialState: ReservationsState = {
  sendYoungDoctorEmail: sendYoungDoctorEmailService.state,
  sendProductFormEmail: sendProductFormEmailService.state,
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: 'mail',
  initialState,
  reducers: {
    ...sendYoungDoctorEmailService.reducers,
    ...sendProductFormEmailService.reducers,
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[slice.name]
const sendYoungDoctorEmail = (state: RootState) =>
  root(state).sendYoungDoctorEmail

const sendProductFormEmail = (state: RootState) =>
  root(state).sendProductFormEmail

export const selectors = {
  sendYoungDoctorEmail,
  sendProductFormEmail,
}
