import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../store'
import { getCustomService } from '../../helpers/ReduxHelpers'
import { AdsReplyFormValues } from '../../forms/AdsReplyForm'
import { GetAnswersParams } from '../../rest/requests/classifieds/getAnswers'
import { ClassifiedAnswer } from '../../rest/types/Classified'
import { DeleteAnswerParams } from '../../rest/requests/classifieds/deleteAnswer'
import { ArchiveParams } from '../../rest/requests/classifieds/archive'

//
// requests
//

export const getAnswerSchemaService = getCustomService<'getAnswerSchema', any>(
  'getAnswerSchema'
)

export const addAnswerService = getCustomService<
  'addAnswer',
  boolean,
  AdsReplyFormValues
>('addAnswer')

export const getAnswersService = getCustomService<
  'getAnswers',
  Array<ClassifiedAnswer>,
  GetAnswersParams
>('getAnswers')

export const deleteAnswerService = getCustomService<
  'deleteAnswer',
  ClassifiedAnswer,
  DeleteAnswerParams
>('deleteAnswer')

export const archiveAnswerService = getCustomService<
  'archiveAnswer',
  ClassifiedAnswer,
  ArchiveParams
>('archiveAnswer')

//
// Initial state
//

type AnswerState = {
  getAnswerSchema: typeof getAnswerSchemaService.state
  addAnswer: typeof addAnswerService.state
  getAnswers: typeof getAnswersService.state
  deleteAnswers: typeof deleteAnswerService.state
  archiveAnswers: typeof archiveAnswerService.state
  // Customs
  selectedAnswer: ClassifiedAnswer | null
}

const initialState: AnswerState = {
  getAnswerSchema: getAnswerSchemaService.state,
  addAnswer: addAnswerService.state,
  getAnswers: getAnswersService.state,
  deleteAnswers: deleteAnswerService.state,
  archiveAnswers: archiveAnswerService.state,
  // Customs
  selectedAnswer: null,
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: 'answer',
  initialState,
  reducers: {
    ...getAnswerSchemaService.reducers,
    ...addAnswerService.reducers,
    ...getAnswersService.reducers,
    ...deleteAnswerService.reducers,
    ...archiveAnswerService.reducers,
    // Custom
    setSelectedAnswer: (state, action) => {
      state.selectedAnswer = action.payload
    },
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[slice.name]
const getAnswerSchema = (state: RootState) => root(state).getAnswerSchema
const addAnswer = (state: RootState) => root(state).addAnswer
const getAnswers = (state: RootState) => root(state).getAnswers
const selectedAnswer = (state: RootState) => root(state).selectedAnswer
const deleteAnswer = (state: RootState) => root(state).deleteAnswers
const archiveAnswer = (state: RootState) => root(state).archiveAnswers

export const selectors = {
  getAnswerSchema,
  addAnswer,
  getAnswers,
  selectedAnswer,
  deleteAnswer,
  archiveAnswer,
}
