import { gql } from '../../../generated'
import { getMutationService } from '../../../../helpers/GraphqlHelpers'

export const updateUser = getMutationService({
  mutation: gql(/* GraphQL */ `
    mutation ExampleUpdateUser($id: ID!, $name: String) {
      updateUser(id: $id, name: $name) {
        ...UserFragment
      }
    }
  `),
  transformer: (response) => {
    return response.updateUser
  },
})
