import {
  declareFont,
  defineFont,
  fontStyles,
  fontWeights,
  Font,
} from '../utils/FontUtils'

enum FontKeys {
  MontserratRegular = 'MontserratRegular',
  MontserratSemiBold = 'MontserratSemiBold',
  MontserratBold = 'MontserratBold',

  FiraSansRegular = 'FiraSansRegular',
  FiraSansSemiBold = 'FiraSansSemiBold',
  FiraSansBold = 'FiraSansBold',

  BebasRegular = 'BebasRegular',

  VujahdayScript = 'VujahdayScript',
}

export const declarations: { [key in FontKeys]: Font } = {
  VujahdayScript: {
    basename: 'VujahdayScript',
    fontFamily: 'VujahdayScript',
    fontWeight: fontWeights.regular,
    fontStyle: fontStyles.normal,
    fallback: 'serif',
  },
  MontserratRegular: {
    basename: 'Montserrat-Regular',
    fontFamily: 'Montserrat',
    fontWeight: fontWeights.regular,
    fontStyle: fontStyles.normal,
    fallback: 'sans-serif',
  },
  MontserratSemiBold: {
    basename: 'Montserrat-SemiBold',
    fontFamily: 'Montserrat',
    fontWeight: fontWeights.semiBold,
    fontStyle: fontStyles.normal,
    fallback: 'sans-serif',
  },
  MontserratBold: {
    basename: 'Montserrat-Bold',
    fontFamily: 'Montserrat',
    fontWeight: fontWeights.bold,
    fontStyle: fontStyles.normal,
    fallback: 'sans-serif',
  },
  FiraSansRegular: {
    basename: 'FiraSans-Regular',
    fontFamily: 'FiraSans',
    fontWeight: fontWeights.regular,
    fontStyle: fontStyles.normal,
    fallback: 'sans-serif',
  },
  FiraSansSemiBold: {
    basename: 'FiraSans-SemiBold',
    fontFamily: 'FiraSans',
    fontWeight: fontWeights.semiBold,
    fontStyle: fontStyles.normal,
    fallback: 'sans-serif',
  },
  FiraSansBold: {
    basename: 'FiraSans-Bold',
    fontFamily: 'FiraSans',
    fontWeight: fontWeights.bold,
    fontStyle: fontStyles.normal,
    fallback: 'sans-serif',
  },
  BebasRegular: {
    basename: 'Bebas-Regular',
    fontFamily: 'Bebas',
    fontWeight: fontWeights.regular,
    fontStyle: fontStyles.normal,
    fallback: 'sans-serif',
  },
}

export const fontsFaces = (): string =>
  Object.values(declarations)
    .filter((font) => !font.isAlreadyDeclared && font)
    .map(declareFont)
    .join(' ')

const fonts: { [key in FontKeys]?: string } = {
  VujahdayScript: defineFont(declarations.VujahdayScript),
  MontserratRegular: defineFont(declarations.MontserratRegular),
  MontserratSemiBold: defineFont(declarations.MontserratSemiBold),
  MontserratBold: defineFont(declarations.MontserratBold),
  FiraSansRegular: defineFont(declarations.FiraSansRegular),
  FiraSansSemiBold: defineFont(declarations.FiraSansSemiBold),
  FiraSansBold: defineFont(declarations.FiraSansBold),
  BebasRegular: defineFont(declarations.BebasRegular),
}

export default fonts
