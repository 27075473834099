const colors = {
  pureBlack: '#000',
  pureWhite: '#FFF',
  arapawa: '#314C5C',
  bismark: '#546A77',
  greyChateau: '#98A5AD',
  pattensBlue: '#DCE1E4',
  creamCan: '#F2C75C',
  salomie: '#FFD978',
  bananaMania: '#FFE9AF',
  oldLace: '#FFF8E5',
  crusta: '#F8835B',
  atomicTangerine: '#F99871',
  melon: '#FBC1AD',
  chablis: '#F0E6D9',
  killarney: '#3E7A4B',
  bayLeaf: '#78C288',
  fringyFlower: '#ACDCB6',
  offGreen: '#DEF5E3',
  cornflowerBlue: '#7199FF',
  blue: '#56CCF2',
  matterhorn: '#4F4F4F',
  grey: '#828282',
  silver: '#BDBDBD',
  gainsboro: '#E0E0E0',
  snow: '#F9F9F9',
  linen: '#F7F2EC',
  floralWhite: '#FCFAF7',
  zircon: '#DCE1E4',
  aliceBlue: '#F8F9FB',
  error: '#E83D15',
  tangerineYellow: '#FFCF00',
  darkPink: '#BC0048',
  framboise: '#C82A64',
  facebook: '#1877F2',
  ruby: '#DC1E63',
  standardRed: '#EB5757',
  red: '#E25944',
  beige: '#EEE9D9',

  // Project colors
  bluePrimary: '#005F89',
  blueSecondary: '#1795D2',
  blueSecondaryBis: '#02293B',
  blueLight: '#FBFBFB',
  blueNormal: '#0092FC',
  blueExtraLight: '#F5F7FD',
  blueDark: '#164467',
  blueTransparent: '#255f84b2',

  pinkPrimary: '#EA5285',
  pinkSecondary: '#EF96B4',
  pinkSecondaryBis: '#D3205A',
  pinkButton: '#F05A89',
  pinkLink: '#ED2074',

  greenSecondary: '#B2E7D6',
  greenSecondaryBis: '#41C69B',
  greenAccent: '#75D8B9',
  greenHeader: '#60AF9B',

  greyContour: '#A7B1C0',
  greyFields: '#929DA7',
  greyBorder2: '#dde2ec',
  greyBorder: '#E0E0E0',
  taupeGray: '#83878C',
  dustyGray: '#969696',
  altoGray: '#D9D9D9',

  brownBack: '#EEEBEB',
  blueBack: '#EDF0F5',
}

export default colors
