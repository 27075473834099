export { default as arrowRight } from './arrowRight.svg'
export { default as burger } from './burger.svg'
export { default as bread } from './bread.svg'
export { default as check } from './check.svg'
export { default as cheese } from './cheese.svg'
export { default as chevronBreadcrumb } from './chevronBreadcrumb.svg'
export { default as chevronDown } from './chevronDown.svg'
export { default as chevronUp } from './chevronUp.svg'
export { default as chevronLeft } from './chevronLeft.svg'
export { default as chevronRight } from './chevronRight.svg'
export { default as circleChevronDown } from './circleChevronDown.svg'
export { default as circleChevronUp } from './circleChevronUp.svg'
export { default as close } from './close.svg'
export { default as cooking } from './cooking.svg'
export { default as download } from './download.svg'
export { default as euro } from './euro.svg'
export { default as eye } from './eye.svg'
export { default as eyeSlash } from './eyeSlash.svg'
export { default as fingerLike } from './fingerLike.svg'
export { default as google } from './google.svg'
export { default as heartFull } from './heartFull.svg'
export { default as heartLine } from './heartLine.svg'
export { default as home } from './home.svg'
export { default as help } from './help.svg'
export { default as info } from './info.svg'
export { default as knife } from './knife.svg'
export { default as level } from './level.svg'
export { default as location } from './location.svg'
export { default as milk } from './milk.svg'
export { default as minus } from './minus.svg'
export { default as nutriscore } from './nutriscore.svg'
export { default as scoreA } from './nutriscore-a.svg'
export { default as scoreB } from './nutriscore-b.svg'
export { default as scoreC } from './nutriscore-c.svg'
export { default as scoreD } from './nutriscore-d.svg'
export { default as scoreE } from './nutriscore-e.svg'
export { default as pageNext } from './pageNext.svg'
export { default as pagePrev } from './pagePrev.svg'
export { default as plus } from './plus.svg'
export { default as pizza } from './pizza.svg'
export { default as play } from './play.svg'
export { default as positiveFood } from './positiveFood.svg'
export { default as positiveFoodLogo } from './positiveFoodLogo.svg'
export { default as print } from './print.svg'
export { default as priceTag } from './price-tag.svg'
export { default as puzzle } from './puzzle.svg'
export { default as refresh } from './refresh.svg'
export { default as search } from './search.svg'
export { default as settings } from './settings.svg'
export { default as share } from './share.svg'
export { default as sherlock } from './sherlock.svg'
export { default as socialFacebook } from './social-facebook.svg'
export { default as socialTwitter } from './social-twitter.svg'
export { default as socialLinkedin } from './social-linkedin.svg'
export { default as socialYoutube } from './social-youtube.svg'
export { default as socialInstagram } from './social-instagram.svg'
export { default as shareSocialTwitter } from './share-social-twitter.svg'
export { default as shareSocialFacebook } from './share-social-facebook.svg'
export { default as shareSocialLinkedin } from './share-social-linkedin.svg'
export { default as socialX } from './social-x.svg'
export { default as starFull } from './starFull.svg'
export { default as sun } from './sun.svg'
export { default as tag } from './tag.svg'
export { default as tendance } from './tendance.svg'
export { default as time } from './time.svg'
export { default as timer } from './timer.svg'
export { default as toque } from './toque.svg'
export { default as triman } from './triman.svg'
export { default as user } from './user.svg'
export { default as wineGlass } from './wineGlass.svg'
export { default as like } from './like.svg'
export { default as bell } from './bell.svg'
export { default as folderCheese } from './folderCheese.svg'
export { default as conseil } from './conseil.svg'
export { default as filter } from './filter.svg'
export { default as panier } from './panier.svg'
export { default as coupons } from './coupons.svg'
export { default as message } from './message.svg'
export { default as phone } from './phone.svg'
export { default as profileCircle } from './profile-circle.svg'
export { default as crosses } from './crosses.svg'
export { default as crossesSecondary } from './crossesSecondary.svg'
export { default as crossWhite } from './cross-white.svg'
export { default as crossBlue } from './cross-blue.svg'
export { default as crossGreen } from './cross-green.svg'
export { default as powerOff } from './powerOff.svg'
export { default as cameraPlus } from './cameraPlus.svg'
export { default as stethoscope } from './stethoscope.svg'
export { default as stethoscopeSecondary } from './stethoscope-secondary.svg'
export { default as drugs } from './drugs.svg'
export { default as car } from './car.svg'
export { default as computer } from './computer.svg'
export { default as desktop } from './desktop.svg'
export { default as energy } from './energy.svg'
export { default as estate } from './estate.svg'
export { default as moneybox } from './moneybox.svg'
export { default as services } from './services.svg'
export { default as statusError } from './status-error.svg'
export { default as statusPending } from './status-pending.svg'
export { default as statusSuccess } from './status-success.svg'
export { default as ArrowsBottomMulti } from './arrows-bottom-multi.svg'
export { default as comment } from './comment.svg'
export { default as schedule } from './schedule.svg'
export { default as postmail } from './postmail.svg'
export { default as upload } from './upload.svg'
export { default as markerMap } from './map-marker.svg'
export { default as profil } from './profil.svg'
export { default as doc } from './doc.svg'
export { default as delete } from './delete.svg'
export { default as edit } from './edit.svg'
export { default as link } from './link.svg'
export { default as docUpload } from './doc-upload.svg'
export { default as videoPosterPlay } from './video-poster-play.svg'
export { default as factAnswers } from './fact_answers.svg'
export { default as factTotal } from './fact_total.svg'
export { default as factViews } from './fact_views.svg'
export { default as logo } from './logo.svg'
export { default as peoples } from './peoples.svg'
export { default as peoples_uncolorred } from './peoples_uncolorred.svg'
export { default as bags } from './bags.svg'
export { default as bags_uncolorred } from './bags_uncolorred.svg'
export { default as pencil } from './pencil.svg'
export { default as bin } from './bin.svg'
