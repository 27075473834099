import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../store'
import { getCustomService } from '../../helpers/ReduxHelpers'
import { Upload } from '../../rest/types/Upload'
import { UploadParams } from '../../rest/requests/media/upload'

//
// requests
//

export const uploadService = getCustomService<'upload', Upload, UploadParams>(
  'upload'
)

//
// Initial state
//

type UploadState = {
  upload: typeof uploadService.state
}

const initialState: UploadState = {
  upload: uploadService.state,
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    ...uploadService.reducers,
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[slice.name]
const upload = (state: RootState) => root(state).upload

export const selectors = {
  upload,
}
