import { getRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'
import { User } from '../../types/User'

export type OnboardResponse = User

const onboard = () =>
  getRestRequest({
    url: api.REST_API_URL + `/onboarding/onboard`,
    method: 'POST',
    transformer: (data: OnboardResponse): OnboardResponse => {
      return data
    },
  })

export default onboard
