import { all, call, put, takeLeading } from 'redux-saga/effects'

import { actions } from '..'
import AuthSagas from '../auth/sagas'
import FiltersSagas from '../filters/sagas'

export default class AppSagas {
  static *initClientSide() {
    console.log('init redux saga')
    yield call(FiltersSagas.init)
    yield call(AuthSagas.onInit)
    yield put(actions.app.setIsInit({ isInit: true }))
  }

  static *loop() {
    yield all([
      //
      takeLeading(actions.app.init, this.initClientSide),
    ])
  }
}
