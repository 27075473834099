import { getRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'

import getUserReservations from './getUserReservations'

type getAllUserReservations = {
  offer: {
    id: string
  }
}

const getAllUserReservations = () =>
  getRestRequest({
    url: api.REST_API_URL + `/reservation/all`,
    transformer: (response: getUserReservations) => {
      return response ?? []
    },
  })

export default getAllUserReservations
