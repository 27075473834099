import { FC } from 'react'
import { useTheme } from 'styled-components'

import { FormFieldInputProps } from '../FormFieldInput'

import * as SC from './styled'

export type FormFieldEmailProps = Omit<FormFieldInputProps, 'type'>

const FormFieldEmail: FC<FormFieldEmailProps> = ({ className, ...props }) => {
  const theme = useTheme()

  return (
    <SC.Input
      className={className}
      type={'email'}
      leftAdornment={
        <SC.Email
          icon="postmail"
          width={29}
          height={24}
          color={theme.colors.greyContour}
        />
      }
      {...props}
    />
  )
}

export default FormFieldEmail
