import { getRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'
import { PaginatedResult } from '../../types/PaginatedResult'
import { SClassifiedCategory } from '../../types/api/Classified'
import { classifiedCategoryTransformer } from '../../transformers/classifiedTransformer'

type GetCategoriesResponse = PaginatedResult<SClassifiedCategory>

const getCategories = () =>
  getRestRequest({
    url:
      api.REST_API_URL +
      `/classified-categories?sort=order:asc
    `,
    transformer: (response: GetCategoriesResponse) => {
      return response.data.map((category) =>
        classifiedCategoryTransformer(category)
      )
    },
  })

export default getCategories
