import qs from 'qs'

import { getRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'
import { PaginatedResult } from '../../types/PaginatedResult'
import { SReservation } from '../../types/api/Reservation'
import { reservationTransformer } from '../../transformers/reservationTransformer'

type getUserReservations = PaginatedResult<SReservation>

export type getUserReservationsParams = {
  offset?: string | number
  limit?: string | number
}

const getUserReservations = (params: getUserReservationsParams) =>
  getRestRequest({
    url:
      api.REST_API_URL +
      `/reservations/all?${qs.stringify(
        {
          ...(params.limit !== undefined && {
            limit: params.limit,
          }),
          ...(params.offset !== undefined && {
            start: params.offset,
          }),
        },
        {
          encodeValuesOnly: true,
        }
      )}`,
    transformer: (response: getUserReservations) => {
      return (
        response?.data?.map((reservation) =>
          reservationTransformer(reservation)
        ) ?? []
      )
    },
  })

export default getUserReservations
