import { UserSpeciality } from '../types/User'
import { SUserSpeciality } from '../types/api/UserSpeciality'

export const userSpecialityTransformer = (
  data: SUserSpeciality
): UserSpeciality => {
  return {
    id: data.id,
    ...data.attributes,
  }
}
