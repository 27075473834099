import styled, { css } from 'styled-components'
import Image from 'next/image'

import FormFieldRadioGroup from '../FormFieldRadioGroup'
import FormFieldFile from '../FormFieldFile'
import FormFieldInput from '../FormFieldInput'
import Icon from '../../../components/Icon'
import Video from '../../../components/Video'

export enum FormFieldUploadMediaVariant {
  primary = 'primary',
  secondary = 'secondary',
}

export const Container = styled.div``

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 2rem;

  ${({ theme }) => theme.breakpoints.up('mobile')} {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Radios = styled(FormFieldRadioGroup)<{
  $variant?: FormFieldUploadMediaVariant
}>`
  input {
    background-color: ${({ theme, $variant }) =>
      !$variant || $variant === FormFieldUploadMediaVariant.primary
        ? theme.colors.blueBack
        : $variant === FormFieldUploadMediaVariant.secondary
        ? theme.colors.pureWhite
        : theme.colors.blueBack};
  }

  ${({ theme }) => theme.breakpoints.down('mobile')} {
    margin-bottom: 2rem;
    > div {
      justify-content: center;
    }
  }
`

export const VideoField = styled(FormFieldInput)``

export const PhotoField = styled(FormFieldFile)``

export const DocumentField = styled(FormFieldFile)``

export const Right = styled.div`
  align-self: center;
`

const PosterStyle = css`
  width: 19rem;
  height: 9.95rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

export const VideoPoster = styled.div`
  ${PosterStyle};
  background-color: ${({ theme }) => theme.colors.greyContour};
`

export const PhotoPoster = styled.div`
  ${PosterStyle};
  background-color: ${({ theme }) => theme.colors.blueBack};
`

export const DocumentPoster = styled.div`
  ${PosterStyle};
  background-color: ${({ theme }) => theme.colors.brownBack};
`

export const DefaultPoster = styled.div<{
  $variant?: FormFieldUploadMediaVariant
}>`
  ${PosterStyle};
  background-color: ${({ theme, $variant }) =>
    !$variant || $variant === FormFieldUploadMediaVariant.primary
      ? theme.colors.brownBack
      : $variant === FormFieldUploadMediaVariant.secondary
      ? theme.colors.pureWhite
      : theme.colors.brownBack};
`

const HintMessage = styled.p`
  ${({ theme }) => theme.textStyles.firaSansTextBase}
  margin-top: 0.5rem;
  padding-inline: 0.8rem;
  text-align: left;
`

export const ErrorMessage = styled(HintMessage)`
  color: ${({ theme }) => theme.colors.error};
`

export const TrashIcon = styled(Icon)`
  display: block;
  margin: 0 0 1.4rem auto;

  &:hover {
    cursor: pointer;
  }
`

export const Preview = styled(Image)`
  width: 100%;
  object-fit: cover;
`

export const PreviewVideo = styled(Video)``
