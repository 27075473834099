import React, { FC } from 'react'
import { ReactPlayerProps } from 'react-player'
import cx from 'classnames'
import dynamic from 'next/dynamic'

import * as SC from './styled'

const ReactPlayer = dynamic(() => import('react-player'), { ssr: false })

export type VideoProps = ReactPlayerProps & {
  className?: string
}

const Video: FC<VideoProps> = ({ className, ...playerProps }) => {
  return (
    <SC.Container className={cx('Video', className)}>
      <ReactPlayer
        width={'100%'}
        height={'auto'}
        style={{ aspectRatio: '16 / 9' }}
        {...playerProps}
      />
    </SC.Container>
  )
}

export default Video
