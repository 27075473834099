import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { REHYDRATE } from 'redux-persist'

import type { RootState } from '../store'
import { OFFERS_LIST_LIMIT, OFFERS_LIST_LIMIT_STEP } from '../../constant'

import { actionsTypes, stateTypes } from './types'

//
// Initial state
//

type FiltersState = {
  filters: {
    groupPurchase: Array<string>
    categories: Array<number>
    brand: Array<number>
  }
  limit: number
  sort: stateTypes.Sort
  shouldRedirect: boolean
}

const initialState: FiltersState = {
  filters: {
    groupPurchase: [],
    categories: [],
    brand: [],
  },
  limit: OFFERS_LIST_LIMIT,
  sort: stateTypes.Sort.Default,
  shouldRedirect: false,
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    resetState: () => initialState,
    setLimit: (state, action: actionsTypes.setLimit) => {
      const { limit } = action.payload
      state.limit = limit
    },
    setFilters: (state, action: actionsTypes.setFilters) => {
      state.filters = action.payload.filters
    },
    setSort: (state, action: actionsTypes.setSort) => {
      const { sort } = action.payload
      state.sort = sort
    },
    increaseLimit: (state) => {
      state.limit = state.limit + OFFERS_LIST_LIMIT_STEP
    },
    setShouldRedirect: (state, action: actionsTypes.setShouldRedirect) => {
      state.shouldRedirect = action.payload
    },
    // Trigger saga actions
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    redirect: (_state, _action: actionsTypes.redirect) => undefined,
  },
  extraReducers: (builder) =>
    builder.addCase<any, PayloadAction<any>>(REHYDRATE, () => {
      return initialState
    }),
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[slice.name]
const limit = (state: RootState) => root(state).limit
const filters = (state: RootState) => root(state).filters
const sort = (state: RootState) => root(state).sort
const shouldRedirect = (state: RootState) => root(state).shouldRedirect

export const selectors = {
  limit,
  filters,
  sort,
  shouldRedirect,
}
