export const createPopup = (
  url: string,
  title: string,
  width: number,
  height: number
): Window | null => {
  const left = window.screenX + (window.outerWidth - width) / 2
  const top = window.screenY + (window.outerHeight - height) / 2.5
  return window.open(
    url,
    title,
    `width=${width},height=${height},left=${left},top=${top}`
  )
}

export enum AuthMessageType {
  INIT = 'init',
  CODE = 'code',
  ERROR = 'error',
  CLOSE = 'close',
}
