const API_URL = process.env.NEXT_PUBLIC_API_URL
const REST_API_URL = process.env.NEXT_PUBLIC_REST_API_URL || `${API_URL}/api`
const GRAPH_API_URL =
  process.env.NEXT_PUBLIC_GRAPH_API_URL || `${API_URL}/graphql`
const BACKEND_URL = String(process.env.NEXT_PUBLIC_BACKEND_URL)
const UPLOAD_ENDPOINT = `${API_URL}/api/image/upload`
const BASIC_AUTH =
  String(process.env.API_BASIC_AUTH) === '1' ||
  String(process.env.API_BASIC_AUTH) === 'false' ||
  false

export default {
  DEBUG: false,
  API_URL,
  REST_API_URL,
  GRAPH_API_URL,
  UPLOAD_ENDPOINT,
  BACKEND_URL,
  BASIC_AUTH,
  IGNORE_API_SSL_ERRORS:
    String(process.env.IGNORE_API_SSL_ERRORS) === 'true' ||
    String(process.env.IGNORE_API_SSL_ERRORS) === '1' ||
    false,
  AUTH_PROVIDER: process.env.NEXT_PUBLIC_AUTH_PROVIDER ?? 'ecps',
}
