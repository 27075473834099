import QueryString from 'qs'

import { PaginatedResult } from '../../types/PaginatedResult'
import { getRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'
import { classifiedTransformer } from '../../transformers/classifiedTransformer'
import { SClassified } from '../../types/api/Classified'

type GetClassifiedResponse = PaginatedResult<SClassified>
export type getClassifiedsParams = {
  filters?: { [key: string]: any }
  populate?: { [key: string]: any }
  pagination?: {
    start?: number
    limit?: number
    page?: number
    pageSize?: number
    withCount?: boolean
  }
  sort?: string | Array<string>
}
const getClassifieds = (data: getClassifiedsParams) => {
  return getRestRequest({
    url: `${api.REST_API_URL}/classifieds?${QueryString.stringify(
      {
        ...data,
      },
      {
        encodeValuesOnly: true,
      }
    )}`,
    transformer: (response: GetClassifiedResponse) => {
      return response.data
        ? response.data?.map((c) => classifiedTransformer(c))
        : []
    },
  })
}
export default getClassifieds
