import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import { actions, selectors } from '..'
import ApiSagas from '../api/sagas'
import create from '../../rest/requests/classifieds/create'
import getCategories from '../../rest/requests/classifieds/getCategories'
import getCabinetTypes from '../../rest/requests/classifieds/getCabinetTypes'
import getOfferTypes from '../../rest/requests/classifieds/getOfferTypes'
import getSpecialties from '../../rest/requests/classifieds/getSpecialties'
import getSecretariats from '../../rest/requests/classifieds/getSecretariats'
import getByUser from '../../rest/requests/classifieds/getByUserId'
import { User } from '../../rest/types/User'
import update from '../../rest/requests/classifieds/update'
import classifiedById from '../../rest/requests/classifieds/classifiedById'
import getBlockSchema from '../../rest/requests/classifieds/getBlockSchema'
import { MediaType } from '../../form/fields/FormFieldUploadMedia'
import addMedia from '../../rest/requests/classifieds/addMedia'
import getMediaList from '../../rest/requests/classifieds/getMediaList'
import deleteMedia from '../../rest/requests/classifieds/deleteMedia'
import updateMedia from '../../rest/requests/classifieds/updateMedia'
import { isEmptyFile } from '../../helpers/FileHelpers'
import publish from '../../rest/requests/classifieds/publish'
import getCategoriesByUserProfile from '../../rest/requests/classifieds/getCategoriesByUserProfile'
import getLatestClassifiedsByCategory from '../../rest/requests/classifieds/getLatestClassifiedsByCategory'
import getClassifieds from '../../rest/requests/classifieds/getClassifieds'
import archive from '../../rest/requests/classifieds/archive'
import GetContentTypeSchema from '../../rest/requests/content-types-builder/getContentTypeSchema'
import increaseView from '../../rest/requests/classifieds/increaseView'
import getClassifiedsWithMeta from '../../rest/requests/classifieds/getClassifiedsWithMeta'

import { adsFormValuesTransformer } from './transformers'

export default class ClassifiedsSagas {
  static *onCreateRequest({
    payload,
  }: ReturnType<typeof actions.classifieds.createRequest>) {
    const response = yield* ApiSagas.rest(create(payload))

    if (response.data) {
      yield put(actions.classifieds.createSuccess(response.data))
    } else {
      yield put(actions.classifieds.createError(response.errors))
    }
  }

  static *onArchiveRequest({
    payload,
  }: ReturnType<typeof actions.classifieds.archiveRequest>) {
    const response = yield* ApiSagas.rest(archive(payload))

    if (response.data) {
      yield put(actions.classifieds.archiveSuccess(response.data))
    } else {
      yield put(actions.classifieds.archiveError(response.errors))
    }
  }

  static *onUpdateRequest({
    payload,
  }: ReturnType<typeof actions.classifieds.updateRequest>) {
    const user: ReturnType<typeof selectors.auth.user> = yield select(
      selectors.auth.user
    )

    const selectedAddress: ReturnType<
      typeof selectors.classifieds.selectedAddress
    > = yield select(selectors.classifieds.selectedAddress)

    const categories: ReturnType<typeof selectors.classifieds.categories> =
      yield select(selectors.classifieds.categories)

    if (user && categories?.length) {
      const values: ReturnType<typeof adsFormValuesTransformer> = yield call(
        adsFormValuesTransformer,
        payload,
        categories,
        user,
        selectedAddress
      )

      const response = yield* ApiSagas.rest(
        update({
          id: (payload as any).id,
          data: {
            ...values.data,
            user: user.id,
            ...(payload?.status && {
              status: payload.status,
            }),
            _geoloc: {
              lat: selectedAddress?.lat,
              lng: selectedAddress?.long,
            },
          },
          ...(values.files && {
            files: values.files,
          }),
        })
      )

      if (response.data) {
        yield put(actions.classifieds.updateSuccess(response.data))
      } else {
        yield put(actions.classifieds.updateError(response.errors))
      }
    }
  }

  static *onGetClassifiedById({
    payload,
  }: ReturnType<typeof actions.classifieds.getClassifiedByIdRequest>) {
    const response = yield* ApiSagas.rest(classifiedById(payload))

    if (response.data && !response.data?.error) {
      yield put(actions.classifieds.getClassifiedByIdSuccess(response.data))
    } else {
      yield put(actions.classifieds.getClassifiedByIdError(response.errors))
    }
  }

  static *onGetClassifieds({
    payload,
  }: ReturnType<typeof actions.classifieds.getClassifiedsRequest>) {
    const response = yield* ApiSagas.rest(getClassifieds(payload))
    if (response.data && !response.data?.error) {
      yield put(actions.classifieds.getClassifiedsSuccess(response.data))
    } else {
      yield put(actions.classifieds.getClassifiedsError(response.errors))
    }
  }

  static *onGetClassifiedsWithMeta({
    payload,
  }: ReturnType<typeof actions.classifieds.getClassifiedsWithMetaRequest>) {
    const response = yield* ApiSagas.rest(getClassifiedsWithMeta(payload))
    if (response.data && !response.data?.error) {
      yield put(
        actions.classifieds.getClassifiedsWithMetaSuccess(response.data)
      )
    } else {
      yield put(
        actions.classifieds.getClassifiedsWithMetaError(response.errors)
      )
    }
  }

  static *onGetPublishAds({
    payload,
  }: ReturnType<typeof actions.classifieds.getPublishAdsRequest>) {
    const response = yield* ApiSagas.rest(getClassifieds(payload))
    if (response.data && !response.data?.error) {
      yield put(actions.classifieds.getPublishAdsSuccess(response.data))
    } else {
      yield put(actions.classifieds.getPublishAdsError(response.errors))
    }
  }

  static *onGetLatestClassifiedsByCategoryByRequest({
    payload,
  }: ReturnType<
    typeof actions.classifieds.getLatestClassifiedsByCategoryRequest
  >) {
    const response = yield* ApiSagas.rest(
      getLatestClassifiedsByCategory(payload)
    )

    if (response.data) {
      yield put(
        actions.classifieds.getLatestClassifiedsByCategorySuccess(response.data)
      )
    } else {
      yield put(
        actions.classifieds.getLatestClassifiedsByCategoryError(response.errors)
      )
    }
  }

  static *onGetCategoriesRequest() {
    const response = yield* ApiSagas.rest(getCategories())

    if (response.data) {
      yield put(actions.classifieds.getCategoriesSuccess(response.data))
    } else {
      yield put(actions.classifieds.getCategoriesError(response.errors))
    }
  }

  static *onGetCategoriesByUserProfileRequest({
    payload,
  }: ReturnType<typeof actions.classifieds.getCategoriesByUserProfileRequest>) {
    const response = yield* ApiSagas.rest(getCategoriesByUserProfile(payload))

    if (response.data) {
      yield put(
        actions.classifieds.getCategoriesByUserProfileSuccess(response.data)
      )
    } else {
      yield put(
        actions.classifieds.getCategoriesByUserProfileError(response.errors)
      )
    }
  }

  static *onGetCabinetTypesRequest({
    payload,
  }: ReturnType<typeof actions.classifieds.getCabinetTypesRequest>) {
    const response = yield* ApiSagas.rest(getCabinetTypes(payload))

    if (response.data) {
      yield put(actions.classifieds.getCabinetTypesSuccess(response.data))
    } else {
      yield put(actions.classifieds.getCabinetTypesError(response.errors))
    }
  }

  static *onGetOfferTypesRequest({
    payload,
  }: ReturnType<typeof actions.classifieds.getOfferTypesRequest>) {
    const response = yield* ApiSagas.rest(getOfferTypes(payload))

    if (response.data) {
      yield put(actions.classifieds.getOfferTypesSuccess(response.data))
    } else {
      yield put(actions.classifieds.getOfferTypesError(response.errors))
    }
  }

  static *onGetSpecialtiesRequest({
    payload,
  }: ReturnType<typeof actions.classifieds.getSpecialtiesRequest>) {
    const response = yield* ApiSagas.rest(getSpecialties(payload))

    if (response.data) {
      yield put(actions.classifieds.getSpecialtiesSuccess(response.data))
    } else {
      yield put(actions.classifieds.getSpecialtiesError(response.errors))
    }
  }

  static *onGetSecretariatsRequests({
    payload,
  }: ReturnType<typeof actions.classifieds.getSecretariatsRequest>) {
    const response = yield* ApiSagas.rest(getSecretariats(payload))

    if (response.data) {
      yield put(actions.classifieds.getSecretariatsSuccess(response.data))
    } else {
      yield put(actions.classifieds.getSecretariatsError(response.errors))
    }
  }

  static *onGetUserClassifiedsByRequest({
    payload,
  }: ReturnType<typeof actions.classifieds.getUserClassifiedsRequest>) {
    const user: User = yield select(selectors.auth.user)

    const response = yield* ApiSagas.rest(
      getByUser({ ...payload, user: user.id })
    )

    if (response.data) {
      yield put(actions.classifieds.getUserClassifiedsSuccess(response.data))
    } else {
      yield put(actions.classifieds.getUserClassifiedsError(response.errors))
    }
  }

  static *onGetBlockSchemaRequest({
    payload,
  }: ReturnType<typeof actions.classifieds.getBlockSchemaRequest>) {
    const response = yield* ApiSagas.rest(getBlockSchema(payload))

    if (response.data) {
      yield put(actions.classifieds.getBlockSchemaSuccess(response.data))
    } else {
      yield put(actions.classifieds.getBlockSchemaError(response.errors))
    }
  }

  static *onGetClassifiedSchemaRequest() {
    const response = yield* ApiSagas.rest(
      GetContentTypeSchema({ uid: 'api::classified.classified' })
    )

    if (response.data) {
      yield put(actions.classifieds.getClassifiedSchemaSuccess(response.data))
    } else {
      yield put(actions.classifieds.getClassifiedSchemaError(response.errors))
    }
  }

  static *onGetMediaListRequest({
    payload,
  }: ReturnType<typeof actions.classifieds.getMediaListRequest>) {
    const response = yield* ApiSagas.rest(getMediaList(payload))

    if (response.data) {
      yield put(actions.classifieds.getMediaListSuccess(response.data))
    } else {
      yield put(actions.classifieds.getMediaListError(response.errors))
    }
  }

  static *onAddMediaToGallery({
    payload,
  }: ReturnType<typeof actions.classifieds.addMediaToGalleryRequest>) {
    const { values, classified } = payload
    const type = values.media?.type

    const response = yield* ApiSagas.rest(
      addMedia({
        data: {
          type: values.media?.type,
          title: values.title,
          classified: classified.id,
          description: values.description,
          ...(type === MediaType.Video
            ? {
                videoUrl: values.media?.video,
              }
            : {}),
        },
        files: [
          ...(type === MediaType.Document &&
          values.media?.document &&
          !isEmptyFile(values.media.document)
            ? [
                {
                  name: 'files.document',
                  value: values.media.document,
                },
              ]
            : []),
          ...(type === MediaType.Photo &&
          values.media?.photo &&
          !isEmptyFile(values.media.photo)
            ? [
                {
                  name: 'files.photo',
                  value: values.media.photo,
                },
              ]
            : []),
        ],
      })
    )

    if (response.data) {
      yield put(actions.classifieds.addMediaToGallerySuccess(response.data))
    } else {
      yield put(actions.classifieds.addMediaToGalleryError(response.errors))
    }
  }

  static *onUpdateMediaRequest({
    payload,
  }: ReturnType<typeof actions.classifieds.updateMediaRequest>) {
    const { values, classified, id } = payload
    const type = values.media?.type

    const response = yield* ApiSagas.rest(
      updateMedia({
        id,
        data: {
          type: values.media?.type,
          title: values.title,
          classified: classified.id,
          description: values.description,
          ...(type === MediaType.Video
            ? {
                videoUrl: values.media?.video,
              }
            : {}),
        },
        files: [
          ...(type === MediaType.Document &&
          values.media?.document &&
          !isEmptyFile(values.media?.document)
            ? [
                {
                  name: 'files.document',
                  value: values.media.document,
                },
              ]
            : []),
          ...(type === MediaType.Photo &&
          values.media?.photo &&
          !isEmptyFile(values.media?.photo)
            ? [
                {
                  name: 'files.photo',
                  value: values.media.photo,
                },
              ]
            : []),
        ],
      })
    )

    if (response.data) {
      yield put(actions.classifieds.updateMediaSuccess(response.data))
    } else {
      yield put(actions.classifieds.updateMediaError(response.errors))
    }
  }

  static *onDeleteMediaRequest({
    payload,
  }: ReturnType<typeof actions.classifieds.deleteMediaRequest>) {
    const response = yield* ApiSagas.rest(deleteMedia(payload))

    if (response.data) {
      yield put(actions.classifieds.deleteMediaSuccess(response.data))
    } else {
      yield put(actions.classifieds.deleteMediaReset(response.errors))
    }
  }

  static *onPublishRequest({
    payload,
  }: ReturnType<typeof actions.classifieds.publishRequest>) {
    const response = yield* ApiSagas.rest(publish(payload))

    if (response.data) {
      yield put(actions.classifieds.publishSuccess(response.data))
    } else {
      yield put(actions.classifieds.publishError(response.errors))
    }
  }

  static *onIncreaseViewRequest({
    payload,
  }: ReturnType<typeof actions.classifieds.increaseViewRequest>) {
    const response = yield* ApiSagas.rest(increaseView(payload))

    if (response.data) {
      yield put(actions.classifieds.increaseViewSuccess(response.data))
    } else {
      yield put(actions.classifieds.increaseViewError(response.errors))
    }
  }

  static *loop() {
    yield all([
      takeLatest(actions.classifieds.createRequest, this.onCreateRequest),
      takeLatest(actions.classifieds.updateRequest, this.onUpdateRequest),
      takeLatest(
        actions.classifieds.getClassifiedByIdRequest,
        this.onGetClassifiedById
      ),
      takeLatest(
        actions.classifieds.getClassifiedsRequest,
        this.onGetClassifieds
      ),
      takeLatest(
        actions.classifieds.getClassifiedsWithMetaRequest,
        this.onGetClassifiedsWithMeta
      ),
      takeLatest(
        actions.classifieds.getPublishAdsRequest,
        this.onGetPublishAds
      ),
      takeLatest(actions.classifieds.archiveRequest, this.onArchiveRequest),
      takeLatest(
        actions.classifieds.getLatestClassifiedsByCategoryRequest,
        this.onGetLatestClassifiedsByCategoryByRequest
      ),
      takeLatest(
        actions.classifieds.getCategoriesRequest,
        this.onGetCategoriesRequest
      ),
      takeLatest(
        actions.classifieds.getCategoriesByUserProfileRequest,
        this.onGetCategoriesByUserProfileRequest
      ),
      takeLatest(
        actions.classifieds.getCabinetTypesRequest,
        this.onGetCabinetTypesRequest
      ),
      takeLatest(
        actions.classifieds.getSecretariatsRequest,
        this.onGetSecretariatsRequests
      ),
      takeLatest(
        actions.classifieds.getOfferTypesRequest,
        this.onGetOfferTypesRequest
      ),
      takeLatest(
        actions.classifieds.getSpecialtiesRequest,
        this.onGetSpecialtiesRequest
      ),
      takeLatest(
        actions.classifieds.getUserClassifiedsRequest,
        this.onGetUserClassifiedsByRequest
      ),
      takeLatest(
        actions.classifieds.getBlockSchemaRequest,
        this.onGetBlockSchemaRequest
      ),
      takeLatest(
        actions.classifieds.addMediaToGalleryRequest,
        this.onAddMediaToGallery
      ),
      takeLatest(
        actions.classifieds.getMediaListRequest,
        this.onGetMediaListRequest
      ),
      takeLatest(
        actions.classifieds.deleteMediaRequest,
        this.onDeleteMediaRequest
      ),
      takeLatest(
        actions.classifieds.updateMediaRequest,
        this.onUpdateMediaRequest
      ),
      takeLatest(actions.classifieds.publishRequest, this.onPublishRequest),
      takeLatest(
        actions.classifieds.getClassifiedSchemaRequest,
        this.onGetClassifiedSchemaRequest
      ),
      takeLatest(
        actions.classifieds.increaseViewRequest,
        this.onIncreaseViewRequest
      ),
    ])
  }
}
