import { useCallback, useEffect, useRef } from 'react'

export default function useTimeout(callback: () => void, duration: number) {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const clear = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }, [])

  const launch = useCallback(() => {
    clear()
    timeoutRef.current = setTimeout(() => callback?.(), duration)
  }, [clear, callback, duration])

  useEffect(() => {
    return () => {
      clear()
    }
  }, [clear])

  return [launch, clear]
}
