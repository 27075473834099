import { getRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'

export type GetPriceResponse = {
  unit_amount: number
}

const getPrice = () =>
  getRestRequest({
    url: api.REST_API_URL + `/stripe/getPrice`,
    transformer: (data: GetPriceResponse): GetPriceResponse => {
      return data
    },
  })

export default getPrice
