import qs from 'qs'

import { putRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'
import { SimpleResult } from '../../types/PaginatedResult'
import { SAlert } from '../../types/api/Alert'
import { classifiedAlertTransformer } from '../../transformers/classifiedTransformer'

export type UpdateAlertResponse = SimpleResult<SAlert>

export type UpdateAlertParams = {
  id: string
  specialities: string
  categories: string
  localisation: string
  terms: string
}

const updateAlert = ({ id, ...rest }: UpdateAlertParams) => {
  return putRestRequest({
    url: `${api.REST_API_URL}/classified-alerts/${id}?${qs.stringify(
      {
        populate: {
          categories: {
            fields: ['id', 'name'],
          },
          specialities: {
            fields: ['id', 'name'],
          },
        },
      },
      { encodeValuesOnly: true }
    )}`,
    data: {
      data: rest,
    },
    transformer: (response: UpdateAlertResponse) => {
      return classifiedAlertTransformer(response.data)
    },
  })
}
export default updateAlert
