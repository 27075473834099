import QueryString from 'qs'

import { getRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'
import { PaginatedResult } from '../../types/PaginatedResult'
import { SClassifiedOfferType } from '../../types/api/Classified'
import { classifiedOfferTypeTransformer } from '../../transformers/classifiedTransformer'

type GetOfferTypesResponse = PaginatedResult<SClassifiedOfferType>
export type getOfferTypesParams = {
  filters?: { [key: string]: any }
  populate?: { [key: string]: any }
  pagination?: {
    start?: number
    limit?: number
    page?: number
    pageSize?: number
    withCount?: boolean
  }
  sort?: string | Array<string>
}

const getOfferTypes = (data: getOfferTypesParams) =>
  getRestRequest({
    url: `${api.REST_API_URL}/classified-offer-types?${QueryString.stringify(
      {
        ...data,
      },
      {
        encodeValuesOnly: true,
      }
    )}
    `,
    transformer: (response: GetOfferTypesResponse) => {
      return response.data.map((offerType) =>
        classifiedOfferTypeTransformer(offerType)
      )
    },
  })

export default getOfferTypes
