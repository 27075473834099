import { getRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'
import { PaginatedResult } from '../../types/PaginatedResult'
import { userSpecialityTransformer } from '../../transformers/userSpecialityTransformer'
import { SUserSpeciality } from '../../types/api/UserSpeciality'

export type GetSpecialitiesResponse = PaginatedResult<SUserSpeciality>

const getSpecialities = () =>
  getRestRequest({
    url:
      api.REST_API_URL +
      `/user-specialities?&pagination[pageSize]=100&&sort=order:asc`,
    transformer: (response: GetSpecialitiesResponse) =>
      response.data.map((profile) => userSpecialityTransformer(profile)),
  })

export default getSpecialities
