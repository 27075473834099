import { useCallback, useEffect } from 'react'
import { takeEvery } from 'redux-saga/effects'
import { ActionCreator } from 'redux'
import { Saga } from 'redux-saga'

import store from '../redux/store'

/**
 * Run saga in component while component is mounted
 */
export const useSaga = (generatorFn: Saga) => {
  useEffect(() => {
    const task = store.sagaMiddleware.run(generatorFn)
    return () => {
      task.cancel()
    }
  }, [generatorFn])
}

/**
 * Run saga effect in component
 */
export const useSagaEffect = <Action extends ActionCreator<any>>(
  effect: (...args: any[]) => any,
  action: Action,
  callback: (action: ReturnType<Action>) => void
) => {
  const generatorFn = useCallback(
    function* fn() {
      yield effect(action, callback)
    },
    [effect, action, callback]
  )

  useSaga(generatorFn)
}

/**
 * Run takeEvery saga effect in component
 */
export const useSagaTakeEvery = <Action extends ActionCreator<any>>(
  action: Action,
  callback: (action: ReturnType<Action>) => void
) => {
  useSagaEffect(takeEvery, action, callback)
}

/*

Usage example:

const MyComponent = () => {

  const [count, setCount] = useState(0)

  // pre-made saga with takeEvery effect
  useSagaTakeEvery(
    actions.example.myAction,
    useCallback((action) => {
      setCount((prevState) => prevState + 1)
    }, [])
  )

  // custom saga
  useSaga(
    useCallback(function* () {
      yield takeEvery(actions.example.otherAction, function (action) {
        setCount((prevState) => prevState + 1)
      })
    }, [])
  )

  return <p>{`Actions dispatched ${count} time`}</p>
}

*/
