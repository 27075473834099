import { postRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'
import { User } from '../../types/User'

export type GetPaymentIntentResponse = {
  clientSecret: string
  user: User
}

export type GetPaymentIntentParams = {
  company: string
  address: string
  postcode: string
  rpps: string
  city: string
  email: string
  cgu: boolean
}

const getPaymentIntent = (data: GetPaymentIntentParams) =>
  postRestRequest({
    url: api.REST_API_URL + `/stripe/getPaymentIntent`,
    data,
    transformer: (data: GetPaymentIntentResponse): GetPaymentIntentResponse => {
      return data
    },
  })

export default getPaymentIntent
