import { api } from '../../../configuration'
import { dataToFormData, postRestRequest } from '../../../helpers/RestHelpers'
import { SimpleResult } from '../../types/PaginatedResult'

type SendYoungDoctorEmailResponse = SimpleResult<boolean>

export type SendYoungDoctorEmailParams = {
  firstName: string
  lastName: string
  urpsId: string
  email: string
  phone: string
  message: string
}

const sendYoungDoctorEmail = (params: SendYoungDoctorEmailParams) =>
  postRestRequest({
    url: `${api.REST_API_URL}/mail/sendFormYoungDoctor`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: dataToFormData(params),
    transformer: (response: SendYoungDoctorEmailResponse) => {
      return !!response.data
    },
  })

export default sendYoungDoctorEmail
