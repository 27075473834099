import { ObjectValues } from './helpers'

export const FormColorVariants = {
  Primary: 'Primary',
  Secondary: 'Secondary',
} as const

export type FormColorVariantValues = ObjectValues<typeof FormColorVariants>

export const FormFieldOrientation = {
  Horizontal: 'horizontal',
  Vertical: 'vertical',
} as const

export type FormFieldOrientationValues = ObjectValues<
  typeof FormFieldOrientation
>
