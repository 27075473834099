import QueryString from 'qs'

import { getRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'
import { PaginatedResult } from '../../types/PaginatedResult'
import { SClassified } from '../../types/api/Classified'
import { classifiedTransformer } from '../../transformers/classifiedTransformer'

type getLatestClassifiedsByCategoryResponse = PaginatedResult<SClassified>

export type getLatestClassifiedsByCategoryParams = {
  category: string
  limit: string
  excludedId: string
}

const getLatestClassifiedsByCategory = ({
  category,
  limit,
  excludedId,
}: getLatestClassifiedsByCategoryParams) =>
  getRestRequest({
    url: `${api.REST_API_URL}/classifieds?${QueryString.stringify(
      {
        filters: {
          classifiedCategory: {
            form: {
              $eq: category,
            },
          },
          id: {
            $ne: excludedId,
          },
        },
        sort: ['publishedAt:desc'],
        pagination: {
          limit: limit,
        },
        populate: {
          classifiedCategory: {
            fields: ['form', 'name'],
          },
          classified_views: {
            fields: ['id'],
            populate: {
              user: {
                fields: ['id'],
              },
            },
          },
          classified_answers: {
            fields: ['id'],
          },
        },
      },
      {
        encodeValuesOnly: true,
      }
    )}`,
    transformer: (response: getLatestClassifiedsByCategoryResponse) => {
      return response.data.map((classified) => {
        return classifiedTransformer(classified)
      })
    },
  })

export default getLatestClassifiedsByCategory
