import { FC } from 'react'

import FormikField from '../../form/FormikField'
import { FormikFormProps } from '../../form/FormikForm'
import { ActionButtonVariant } from '../../components/ActionButton/styled'
import { FormSubmitButtonProps } from '../../form/FormSubmitButton'
import FormMessages, { FormMessagesProps } from '../../form/FormMessages'
import { FormFieldEmailProps } from '../../form/fields/FormFieldEmail'
import { FormFieldOrientation } from '../../types/form'

import * as SC from './styled'

type AccountValidateEmailFormValues = {
  email: string
}

export type AccountValidateEmailFormProps =
  FormikFormProps<AccountValidateEmailFormValues> & {
    text: string
    fields: {
      email: Omit<FormFieldEmailProps, 'name'>
    }
    submitButton?: FormSubmitButtonProps
    success?: string
    errors?: FormMessagesProps['messages']
  }

const AccountValidateEmailForm: FC<AccountValidateEmailFormProps> = ({
  text,
  fields,
  submitButton,
  errors,
  success,
  ...formikProps
}) => {
  const { email } = fields

  return (
    <SC.Container>
      <SC.Text>{text}</SC.Text>
      <SC.Form {...formikProps}>
        <SC.Fields>
          <FormikField
            labelProps={{ underlined: true }}
            orientation={FormFieldOrientation.Horizontal}
            {...email}
            name="email"
            Component={SC.EmailField}
          />
        </SC.Fields>
        <SC.Actions>
          <SC.SubmitButton
            type={'submit'}
            variant={ActionButtonVariant.quaternary}
            {...submitButton}
          />
        </SC.Actions>
      </SC.Form>
      {success && <SC.Success>{success}</SC.Success>}
      {errors && <FormMessages messages={errors} />}
    </SC.Container>
  )
}

export default AccountValidateEmailForm
