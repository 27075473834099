import { MediaType } from '../../form/fields/FormFieldUploadMedia'
import { AdsFormValues } from '../../forms/AdsForm'
import { GeneralBlockValues } from '../../forms/AdsForm/blocks/GeneralBlock'
import { InstallationBlockValues } from '../../forms/AdsForm/blocks/InstallationBlock'
import { MediaBlockValues } from '../../forms/AdsForm/blocks/MediaBlock'
import { SidebarBlockValues } from '../../forms/AdsForm/blocks/SidebarBlock'
import { BlockCategory } from '../../forms/AdsForm/types'
import { tagsToString } from '../../helpers/FormHelpers'
import { ClassifiedCategory } from '../../rest/types/Classified'
import { SecretaryBlockValues } from '../../forms/AdsForm/blocks/SecretaryBlock'
import { GardeBlockValues } from '../../forms/AdsForm/blocks/GardeBlock'
import { CoordinatorBlockValues } from '../../forms/AdsForm/blocks/CoordinatorBlock'
import { AssistantBlockValues } from '../../forms/AdsForm/blocks/AssistantBlock'
import { AdoptBlockValues } from '../../forms/AdsForm/blocks/AdoptBlock'
import { JobBlockValues } from '../../forms/AdsForm/blocks/JobBlock'
import { SurgeryBlockValues } from '../../forms/AdsForm/blocks/SurgeryBlock'
import { LiberalBlockValues } from '../../forms/AdsForm/blocks/LiberalBlock'
import { ReplacementBlockValues } from '../../forms/AdsForm/blocks/ReplacementBlock'
import { HelpBlockValues } from '../../forms/AdsForm/blocks/HelpBlock'
import { WorkerBlockValues } from '../../forms/AdsForm/blocks/WorkerBlock'
import { Civility, User } from '../../rest/types/User'
import { isEmptyFile } from '../../helpers/FileHelpers'
import { GeoAddress } from '../../rest/types/GeoAddress'
import { EmitterType } from '../../rest/types/api/Classified'

export const adsFormValuesTransformer = (
  { generalBlock, ...data }: AdsFormValues,
  categories: Array<ClassifiedCategory>,
  user: User,
  selectedAddress: GeoAddress | null
) => {
  let block

  switch (generalBlock.category) {
    case BlockCategory.Installation:
      block = data.installationBlock
        ? installationValuesTransformer(data.installationBlock)
        : null
      break
    case BlockCategory.Garde:
      block = data.gardeBlock ? gardeValuesTransformer(data.gardeBlock) : null
      break
    case BlockCategory.Secretary:
      block = data.secretaryBlock
        ? secretaryValuesTransformer(data.secretaryBlock)
        : null
      break
    case BlockCategory.Coordinator:
      block = data.coordinatorBlock
        ? coordinatorValuesTransformer(data.coordinatorBlock)
        : null
      break
    case BlockCategory.MedicalAssistant:
      block = data.assistantBlock
        ? assistantBlockValuesTransformer(data.assistantBlock)
        : null
      break
    case BlockCategory.Adopt:
      block = data.adoptBlock
        ? adoptBlockValuesTransformer(data.adoptBlock)
        : null
      break
    case BlockCategory.Job:
      block = data.jobBlock ? jobBlockValuesTransformer(data.jobBlock) : null
      break
    case BlockCategory.ShareOperatingTheatre:
      block = data.surgeryBlock
        ? surgeryBlockValuesTransformer(data.surgeryBlock)
        : null
      break
    case BlockCategory.Liberal:
      block = data.liberalBlock
        ? liberalBlockValuesTransformer(data.liberalBlock)
        : null
      break
    case BlockCategory.Replacement:
      block = data.replacementBlock
        ? replacementBlockValuesTransformer(data.replacementBlock)
        : null
      break
    case BlockCategory.OperatingAid:
      block = data.helpBlock ? helpBlockValuesTransformer(data.helpBlock) : null
      break
    case BlockCategory.PaidEmployment:
      block = data.workerBlock
        ? WorkerBlockValuesTransformer(data.workerBlock)
        : null
      break
  }
  const general = generalValuesTransformer(
    generalBlock,
    categories,
    selectedAddress,
    user
  )
  const media = mediaTransformer(data.mediaBlock)
  const files = filesTransformer({
    emitter: data.sidebarBlock,
    media: data.mediaBlock,
  })

  return {
    data: {
      ...general,
      block: [block],
      videoUrl: media.videoUrl,
      mainMediaType: media.mainMediaType,
      ...(data.sidebarBlock && {
        emitter: {
          user: user.id,
          ...emitterValuesTransformer(data.sidebarBlock),
        },
      }),
    },
    ...(files.length && {
      files,
    }),
  }
}

export const generalValuesTransformer = (
  data: GeneralBlockValues,
  categories: Array<ClassifiedCategory>,
  selectedAddress: GeoAddress | null,
  user: User
) => {
  let civility
  if (user?.civility === Civility.Dr) {
    civility = 'Docteur'
  } else if (user?.civility === Civility.Mr) {
    civility = 'Monsieur'
  } else {
    civility = 'Madame'
  }

  return {
    classifiedCategory: categories.find((c) => c.form === data.category)?.id,
    title:
      data.category === BlockCategory.Liberal ||
      data.category === BlockCategory.Adopt
        ? `${civility} ${user?.firstName} ${user?.lastName}`
        : data.title,
    description: data.description,
    summary: data.resume,
    address: data.mainAddress,
    additionalAddress: data.secondAddress,
    keywords: tagsToString(data.keywords),
    ...(selectedAddress && {
      postalCode: selectedAddress.postcode,
      city: selectedAddress.city,
      lat: selectedAddress.lat,
      long: selectedAddress.long,
      department: selectedAddress.context,
    }),
  }
}

export const installationValuesTransformer = (
  data: InstallationBlockValues
) => ({
  __component: 'classified.installation',
  offerType: data.offerType.length === 0 ? null : data.offerType,
  specialities: data.specialities,
  cabinetType: data.cabinetType.length === 0 ? null : data?.cabinetType,
  secretariats: data.receptionType,
  startDate: data.installationDate.length === 0 ? null : data.installationDate,
})

export const gardeValuesTransformer = (data: GardeBlockValues) => ({
  __component: 'classified.garde',
  specialities: data.specialities,
  doctors: data.doctors,
  departments: data.departments,
  periods: data.period,
  type: data.adsType,
})

export const secretaryValuesTransformer = (data: SecretaryBlockValues) => ({
  __component: 'classified.secretary',
  saturday: data.saturdays === 'undefined' ? null : data.saturdays,
  experience: data.experience === 'undefined' ? null : data.experience,
  cabinetType: data.cabinetType.length === 0 ? null : data?.cabinetType,
  specialities: data.specialities,
  contract: data.contractType,
  startDate:
    data?.period?.startDate?.length === 0 ? null : data.period.startDate,
  endDate: data?.period?.endDate?.length === 0 ? null : data.period.endDate,
})

export const coordinatorValuesTransformer = (data: CoordinatorBlockValues) => ({
  __component: 'classified.coordinator',
  contract: data.contractType,
  startDate:
    data?.period?.startDate?.length === 0 ? null : data.period.startDate,
  endDate: data?.period?.endDate?.length === 0 ? null : data.period.endDate,
  saturday: data.saturdays === 'undefined' ? null : data.saturdays,
  experience: data.experience === 'undefined' ? null : data.experience,
  cabinetType: data.speciality.length === 0 ? null : data?.speciality,
})

export const assistantBlockValuesTransformer = (
  data: AssistantBlockValues
) => ({
  __component: 'classified.assistant',
  cabinetType: data.cabinetType.length === 0 ? null : data?.cabinetType,
  speciality: data.cabinetType.length === 0 ? null : data.speciality,
  saturday: data.saturdays === 'undefined' ? null : data.saturdays,
  gestures: data.technicalGesture,
  contract: data.contractType,
  startDate:
    data?.period?.startDate?.length === 0 ? null : data.period.startDate,
  endDate: data?.period?.endDate?.length === 0 ? null : data.period.endDate,
})

export const adoptBlockValuesTransformer = (data: AdoptBlockValues) => ({
  __component: 'classified.adopt',
  speciality: data.speciality.length === 0 ? null : data.speciality,
  patronages: data.studentNumbers,
})

export const jobBlockValuesTransformer = (data: JobBlockValues) => ({
  __component: 'classified.carabin',
  startDate: data.period.startDate,
  endDate: data.period.endDate,
  cabinetType: data.cabinetType.length === 0 ? null : data.cabinetType,
  speciality: data.speciality.length === 0 ? null : data.speciality,
})

export const surgeryBlockValuesTransformer = (data: SurgeryBlockValues) => {
  return {
    __component: 'classified.block-operatory',
    establishmentName: data.establishmentName,
    group:
      data.groupMembership.length === 0
        ? null
        : data.groupMembership[0].toUpperCase() + data.groupMembership.slice(1),
    speciality: data.specialities.length === 0 ? null : data.specialities,
    description: data.description,
    operatoryHelpProvided:
      data.helpProvided === 'undefined' ? null : data.helpProvided,
    treatmentType: data.possibilities,
    toolsProvided:
      data.toolsProvided === 'undefined' ? null : data.toolsProvided,
    establishmentApproved:
      data.establishmentApproved === 'undefined'
        ? null
        : data.establishmentApproved,
    availabilities: data.frequency,
  }
}

export const liberalBlockValuesTransformer = (data: LiberalBlockValues) => {
  return {
    __component: 'classified.liberal',
    speciality: data.specialities.length === 0 ? null : data.specialities,
    cabinetType: data.cabinetType.length === 0 ? null : data?.cabinetType,
    frequencies: data.frequencies,
    periodMonths: data.period,
    periodDays: {
      mondayMorning: data?.periodDays?.weekDays?.monday?.morning,
      mondayAfternoon: data?.periodDays?.weekDays?.monday?.afternoon,
      tuesdayMorning: data?.periodDays?.weekDays?.tuesday?.morning,
      tuesdayAfternoon: data?.periodDays?.weekDays?.tuesday?.afternoon,
      wednesdayMorning: data?.periodDays?.weekDays?.wednesday?.morning,
      wednesdayAfternoon: data?.periodDays?.weekDays?.wednesday?.afternoon,
      thursdayMorning: data?.periodDays?.weekDays?.thursday?.morning,
      thursdayAfternoon: data?.periodDays?.weekDays?.thursday?.afternoon,
      fridayMorning: data?.periodDays?.weekDays?.friday?.morning,
      fridayAfternoon: data?.periodDays?.weekDays?.friday?.afternoon,
      saturdayMorning: data?.periodDays?.weekDays?.saturday?.morning,
      saturdayAfternoon: data?.periodDays?.weekDays?.saturday?.afternoon,
      sundayMorning: data?.periodDays?.weekDays?.sunday?.morning,
      sundayAfternoon: data?.periodDays?.weekDays?.sunday?.afternoon,
    },
    comments: data.comments,
  }
}

export const replacementBlockValuesTransformer = (
  data: ReplacementBlockValues
) => {
  return {
    __component: 'classified.replacement',
    specialities: data.specialities,
    startDate: data.period.startDate,
    endDate: data.period.endDate,
    period: {
      mondayMorning: data?.period?.weekDays?.monday?.morning,
      mondayAfternoon: data?.period?.weekDays?.monday?.afternoon,
      tuesdayMorning: data?.period?.weekDays?.tuesday?.morning,
      tuesdayAfternoon: data?.period?.weekDays?.tuesday?.afternoon,
      wednesdayMorning: data?.period?.weekDays?.wednesday?.morning,
      wednesdayAfternoon: data?.period?.weekDays?.wednesday?.afternoon,
      thursdayMorning: data?.period?.weekDays?.thursday?.morning,
      thursdayAfternoon: data?.period?.weekDays?.thursday?.afternoon,
      fridayMorning: data?.period?.weekDays?.friday?.morning,
      fridayAfternoon: data?.period?.weekDays?.friday?.afternoon,
      saturdayMorning: data?.period?.weekDays?.saturday?.morning,
      saturdayAfternoon: data?.period?.weekDays?.saturday?.afternoon,
      sundayMorning: data?.period?.weekDays?.sunday?.morning,
      sundayAfternoon: data?.period?.weekDays?.sunday?.afternoon,
    },
    negociableDays:
      data.replacementDays === 'undefined' ? false : data.replacementDays,
    guard: data.garde === 'undefined' ? false : data.garde,
    cabinetType: data.cabinetType.length === 0 ? null : data?.cabinetType,
    secretariats: data.receptionType,
    sector: data.sector.length === 0 ? null : data?.sector,
    softwares: tagsToString(data.softwares),
    rate: data.rate
      ? typeof data.rate === 'string'
        ? data?.rate?.slice(0, -1)
        : data.rate
      : 0,
    collabReplacement:
      data.replacementCollaboration === 'undefined'
        ? false
        : data.replacementCollaboration,
    periodMonths: data.period,
  }
}

export const helpBlockValuesTransformer = (data: HelpBlockValues) => {
  return {
    __component: 'classified.operatory',
    contract: data.contractType,
    startDate:
      data?.period?.startDate?.length === 0 ? null : data.period.startDate,
    endDate: data?.period?.endDate?.length === 0 ? null : data.period.endDate,
    speciality: data.speciality.length === 0 ? null : data.speciality,
    days: {
      mondayMorning: data?.periodDays?.weekDays?.monday?.morning,
      mondayAfternoon: data?.periodDays?.weekDays?.monday?.afternoon,
      tuesdayMorning: data?.periodDays?.weekDays?.tuesday?.morning,
      tuesdayAfternoon: data?.periodDays?.weekDays?.tuesday?.afternoon,
      wednesdayMorning: data?.periodDays?.weekDays?.wednesday?.morning,
      wednesdayAfternoon: data?.periodDays?.weekDays?.wednesday?.afternoon,
      thursdayMorning: data?.periodDays?.weekDays?.thursday?.morning,
      thursdayAfternoon: data?.periodDays?.weekDays?.thursday?.afternoon,
      fridayMorning: data?.periodDays?.weekDays?.friday?.morning,
      fridayAfternoon: data?.periodDays?.weekDays?.friday?.afternoon,
      saturdayMorning: data?.periodDays?.weekDays?.saturday?.morning,
      saturdayAfternoon: data?.periodDays?.weekDays?.saturday?.afternoon,
      sundayMorning: data?.periodDays?.weekDays?.sunday?.morning,
      sundayAfternoon: data?.periodDays?.weekDays?.sunday?.afternoon,
    },
    frequency: data.frequency.length === 0 ? null : data.frequency,
  }
}

export const WorkerBlockValuesTransformer = (data: WorkerBlockValues) => {
  return {
    __component: 'classified.employee',
    job: data.role,
    contract: data.contractType,
    startDate: data.period.startDate,
    endDate: data.period.endDate,
  }
}

export const emitterValuesTransformer = (data: SidebarBlockValues) => ({
  ...(data.id && {
    id: data.id,
  }),
  ...(data.emitter === EmitterType.Doctor && {
    presentation: data.presentation,
    website: data.website,
    recipients: data.recipient.map((r) => ({ value: r.value })),
  }),
  ...(data.emitter === EmitterType.Organization && {
    name: data.name,
    presentation: data.presentation,
    website: data.website,
    recipients: data.recipient.map((r) => ({ value: r.value })),
  }),
  type: data.emitter,
})

export const mediaTransformer = (data?: MediaBlockValues) => {
  return {
    mainMediaType: data?.media.type,
    videoUrl: data?.media.video,
  }
}

export const filesTransformer = ({
  emitter,
  media,
}: {
  media?: MediaBlockValues
  emitter?: SidebarBlockValues
}) => [
  ...(media?.media.type === MediaType.Photo && !isEmptyFile(media.media.photo)
    ? [{ name: 'files.image', value: media?.media.photo }]
    : []),
  ...(emitter?.emitter === EmitterType.Organization && emitter?.logo
    ? [{ name: 'files.emitter.logo', value: emitter.logo }]
    : []),
]
