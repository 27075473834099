import { all, put, takeEvery } from 'redux-saga/effects'

import ApiSagas from '../api/sagas'
import { actions } from '../index'
import upload from '../../rest/requests/media/upload'

export default class UploadSagas {
  static *onUploadRequest({
    payload,
  }: ReturnType<typeof actions.upload.uploadRequest>) {
    const response = yield* ApiSagas.rest(upload(payload))

    if (response.data) {
      yield put(actions.upload.uploadSuccess(response.data))
    } else {
      yield put(actions.upload.uploadError(response.errors))
    }
  }
  static *loop() {
    yield all([takeEvery(actions.upload.uploadRequest, this.onUploadRequest)])
  }
}
