import React from 'react'

import { ActionButtonProps } from '../../components/ActionButton'
import { Icons } from '../../components/Icon'

import * as SC from './styled'

export type FormSubmitButtonProps = ActionButtonProps & {
  pendingText?: string
  successText?: string
  disabledText?: string
  isPending?: boolean
  isSuccess?: boolean
  autoIcon?: boolean
}

const FormSubmitButton: React.FC<FormSubmitButtonProps> = ({
  className,
  isPending,
  isSuccess,
  disabled,
  successText,
  pendingText,
  disabledText,
  label,
  autoIcon,
  ...rest
}) => {
  const displayText =
    disabled && disabledText
      ? disabledText
      : isSuccess && successText
      ? successText
      : isPending && pendingText
      ? pendingText
      : label

  const enabled = !disabled && !isPending

  return (
    <SC.Button
      className={className}
      type="submit"
      $isPending={isPending}
      $isSuccess={isSuccess}
      disabled={!enabled}
      iconProps={autoIcon && isPending ? { icon: Icons.refresh } : undefined}
      label={displayText}
      {...rest}
    />
  )
}
export default FormSubmitButton
