import { createGlobalStyle } from 'styled-components'

import { fontsFaces } from './fonts'

export const GlobalStyles = createGlobalStyle`
  ${fontsFaces()}
  :root {
    --radius-blocks--mobile: 2rem;
    --radius-blocks--desktop: 4rem;
    --boxedContainer-default: 1322px;
    --boxedContainer-small: 1146px;
    --boxedContainer-Xsmall: 1083px;
  }
  * {
    box-sizing: border-box;
    &:before, &:after {
      box-sizing: border-box;
    }
  }
  html {
    font-size: 62.5%;
  }
  body {
    padding: 0;
    margin: 0;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }  
  p {
    margin: 0;
    padding: 0;
  }
  a {
    text-decoration: none;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }
  img {
    max-width: 100%;
    height: auto;
    vertical-align: middle
  }
  picture img {
    display: block;
  }
  button {
    padding: 0;
    border: 0;
    &:not(:disabled) {
      cursor: pointer;
    }
  }  
  :focus {
    outline: 0.1rem dotted #000;
  }
  :focus:not(:focus-visible) {
    outline: none;
  }
  :focus-visible {
    outline: 0.1rem dotted #000;
  }
  input::-ms-clear {
    display: none;
  }
  input[type="datetime-local"]::-webkit-calendar-picker-indicator{
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    width: 22px;
    height: 22px;
    left: 10px;
    background: transparent;
  }
  
  
`
