import React from 'react'
import { Formik } from 'formik'
import { FormikConfig, FormikProps } from 'formik/dist/types'

import * as SC from './styled'
import FormikContextObserver, {
  FormikContextObserverProps,
} from './FormikContextObserver'

export type FormikFormProps<Values = any> = FormikConfig<Values> & {
  className?: string
  onContextUpdate?: FormikContextObserverProps<Values>['onUpdate']
  onContextUpdateWhenFirstTouched?: FormikContextObserverProps<Values>['onUpdateWhenFirstTouched']
  children?: ((props: FormikProps<Values>) => React.ReactNode) | React.ReactNode
}

const FormikForm: React.FC<FormikFormProps> = ({
  className,
  onContextUpdate,
  onContextUpdateWhenFirstTouched,
  children,
  ...props
}) => {
  return (
    <Formik validateOnChange={false} validateOnBlur={false} {...props}>
      {(formikProps) => (
        <>
          {(onContextUpdate || onContextUpdateWhenFirstTouched) && (
            <FormikContextObserver
              onUpdate={onContextUpdate}
              onUpdateWhenFirstTouched={onContextUpdateWhenFirstTouched}
            />
          )}
          <SC.Form
            className={className}
            noValidate
            onSubmit={formikProps.handleSubmit}
          >
            {typeof children === 'function' ? children(formikProps) : children}
          </SC.Form>
        </>
      )}
    </Formik>
  )
}

export default FormikForm
